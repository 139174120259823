import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ViewNewInspectionReportComponent } from "../new-inspection-report/view-inspection-report/view-new-inspection-report.component";
import { InspectionReportComponent } from "./inspection-report.component";
const routes: Routes = [
  {
    path: "",
    component: InspectionReportComponent,
  },
  // {
  //   path: "view-new-reports/:id",
  //   component: ViewNewInspectionReportComponent,
  // },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InspectionReportRoutingModule {}
