import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { tap, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class LoginService {
  userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(-1);
  user: Observable<any> = this.userSubject.asObservable();
  companyID: any;
  constructor(private http: HttpClient, private router: Router) {
    const token = localStorage.getItem("token");
    if (token) {
      const decodeToken = this.parseJwt(token);
      const expiryTime = new Date(decodeToken.exp * 1000);
      if (expiryTime < new Date()) {
        console.log("login page");
        localStorage.clear();
        this.router.navigate(["login"]);
        window.location.reload();
      }
    }
  }
  updateUser(user) {
    this.userSubject.next(user);
  }
  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  login(loginForm): Observable<any> {
    return this.http
      .post(environment.apiURL + "adminusers/signin", loginForm)
      .pipe(
        tap((res: any) => {
          return res;
        })
      );
  }
  updateAdmin(data): Observable<any> {
    return this.http.post(environment.apiURL + "adminusers/update", data).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }
  setCompanyId(id) {
    this.companyID = id;
  }
  getCompanyId() {
    return this.companyID;
  }
  create(registerForm) {
    return this.http
      .post(environment.apiURL + "adminusers/create", registerForm)
      .pipe(tap((res) => res));
  }
  fetchUser() {
    return this.http
      .get(environment.apiURL + "adminusers/list")
      .pipe(tap((res) => res));
  }
  deleteUser(userID) {
    return this.http
      .delete(environment.apiURL + "adminusers/delete/" + userID)
      .pipe(tap((res) => res));
  }
  getDetails(id) {
    return this.http
      .get(environment.apiURL + "adminusers/" + id)
      .pipe(tap((res) => res));
  }
  getUserDetails(id) {
    return this.http
      .get(environment.apiURL + "user/" + id)
      .pipe(tap((res) => res));
  }
  forgotPassword(body) {
    return this.http.post(
      environment.apiURL + "adminusers/forgot-password",
      body
    );
  }
  resetPassword(body) {
    return this.http.post(
      environment.apiURL + "adminusers/reset-password",
      body
    );
  }
  resetPasswordForUser(body) {
    return this.http.post(environment.apiURL + 'user/reset-password', body);
}
}
