import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


enum ViewMode {
  Default = 'default',
  Selection = 'selection',
}

@Component({
  selector: 'app-dropdown-list-item',
  templateUrl: './dropdown-list-item.component.html',
  styleUrls: ['./dropdown-list-item.component.scss']
})
export class DropdownListItemComponent implements OnInit {
  @Input() data: any;
  @Input() viewMode: ViewMode;
  @Input() inSelection: boolean;
  @Input() disabled: boolean;
  @Output() liClicked = new EventEmitter<number>();
  constructor() {
    
  }

  ngOnInit(): void {
  }


  toggleAdded() {
    if (this.inSelection == true) {
      this.liClicked.emit(this.data.id);
      //this.inSelection = false
    } else {
      this.liClicked.emit(this.data.id);
      //this.inSelection = true
    }
  }

  highlightSubstring() {
    if (!this.data["substringPositions"]) {
      return this.data["display_value"];
    }
    const mainString = this.data["display_value"];
    if (mainString === null) {
      return '';
    }
      
    const substringPositions = this.data["substringPositions"]["highlight"];
    const start = substringPositions["start"];
    const end = substringPositions["end"];
    const boldSubstring = '<strong>' + mainString.substring(start, end + 1) + '</strong>';
    return mainString.substring(0, start) + boldSubstring + mainString.substring(end + 1);
  }

}




