import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Injectable()
export class VehicleTypesService {
    constructor(private http: HttpClient) { }

    vehicleList() {
        return this.http.get(environment.apiURL + 'vehicle/list-vehicles').pipe(
            tap((response: any) => {
                return response;
            })
        );

    }
    vehicleTypeList(){
        return this.http.get(environment.apiURL + 'shared/list-vehicle-types').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    companyList() {
        return this.http.get(environment.apiURL + 'company/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    getVehicleTypeDetails(id) {
        return this.http.get(environment.apiURL + 'shared/vehicle-type-details/' + id).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    createVehicleType(data) {
        return this.http.post(environment.apiURL + 'shared/add-vehicle-types', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    updateVehicleType(data) {
        return this.http.post(environment.apiURL + 'shared/update-vehicle-types', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    fetchType() {
        return this.http.get(environment.apiURL + 'types/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    createType(data) {
        return this.http.post(environment.apiURL + 'types/create', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    updateType(data) {
        return this.http.post(environment.apiURL + 'types/update', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    deleteVehicleType(id) {
        let data;
            data = {_id:id};
        return this.http.post(environment.apiURL + 'shared/delete-vehicle-types', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
}
