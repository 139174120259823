import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { tap, takeUntil } from "rxjs/operators";

@Injectable()
export class FormSectionsService {
  userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(-1);
  user: Observable<any> = this.userSubject.asObservable();

  constructor(private http: HttpClient) {
    const token = localStorage.getItem("token");
    if (token) {
      const decodeToken = this.parseJwt(token);
      const expiryTime = new Date(decodeToken.exp * 1000);
      if (expiryTime < new Date()) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }
  updateUser(user) {
    this.userSubject.next(user);
  }
  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  login(loginForm): Observable<any> {
    return this.http
      .post(environment.apiURL + "adminusers/signin", loginForm)
      .pipe(
        tap((res: any) => {
          return res;
        })
      );
  }
  updateAdmin(data): Observable<any> {
    return this.http.post(environment.apiURL + "adminusers/update", data).pipe(
      tap((res: any) => {
        return res;
      })
    );
  }
  createAdmin(registerForm) {
    return this.http
      .post(environment.apiURL + "adminusers/create", registerForm)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
  fetchUser() {
    return this.http.get(environment.apiURL + "adminusers/list").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  deleteUser(userID) {
    return this.http
      .delete(environment.apiURL + "adminusers/delete/" + userID)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
  getDetails(id) {
    return this.http.get(environment.apiURL + "adminusers/" + id).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  forgotPassword(body) {
    return this.http.post(
      environment.apiURL + "adminusers/forgotPassword",
      body
    );
  }
  companyList() {
    return this.http.get(environment.apiURL + "company/list").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  resetPassword(body) {
    return this.http.post(
      environment.apiURL + "adminusers/resetPassword",
      body
    );
  }

  // question apis
  fetchSection() {
    return this.http.get(environment.formBuilderApiURL + "sections/getAllSections").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  fetchSectionById(id) {
    return this.http
      .get(environment.formBuilderApiURL + "sections/getAllSections?id=" + id)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  insertSection(body) {
    return this.http.post(environment.formBuilderApiURL + "sections/addSections", body);
  }
  editSection(body, id) {
    return this.http.post(
      environment.formBuilderApiURL + "sections/editSections?id=" + id,
      body
    );
  }

  deleteSection(id) {
    return this.http
      .delete(environment.formBuilderApiURL + "sections/deleteSections?id=" + id)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getQuestionDetails(id) {
    return this.http
      .get(environment.formBuilderApiURL + "questions/viewQuestion?id=" + id)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
}
