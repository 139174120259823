<div class="">
    <div id="styleSelector" class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
        <div class="d-flex flex-row justify-content-around mb-5 mt-5">
            <h4>Schedule Message</h4>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Disposal site"
                tooltipPosition="top">
                <i class="fa fa-window-close fa-2x "></i>
            </div>
        </div>
       <div class="main-row">
            <div class="calendar">
                <p-calendar [showTime]="true" [(ngModel)]="date" class="form-control" dateFormat="dd/mm/yy"
                    [showIcon]="true" [minDate]="minSelectableDate"></p-calendar>
            </div>
            <button class="btn btn-primary" (click)="scheduleMessage($event)">Set Schedule</button>
            <button *ngIf="dateSet" class="btn btn-primary cancel-btn" (click)="clearSchedule($event)">Clear Schedule</button>
            <div></div>
       </div>
</div>
