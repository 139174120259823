import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
/*import { AnimationBuilder, AnimationService } from 'css-animator';*/
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { environment } from "../../../../../environments/environment";
import { JobService } from "src/app/demo/job/job-data.service";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
  providers: [NgbDropdownConfig],
  animations: [
    trigger("collapsedCard", [
      state(
        "collapsed, void",
        style({
          overflow: "hidden",
          height: "0px",
        })
      ),
      state(
        "expanded",
        style({
          overflow: "hidden",
          height: AUTO_STYLE,
        })
      ),
      transition("collapsed <=> expanded", [animate("400ms ease-in-out")]),
    ]),
    trigger("cardRemove", [
      state(
        "open",
        style({
          opacity: 1,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0,
          display: "none",
        })
      ),
      transition("open <=> closed", animate("400ms")),
    ]),
  ],
})
export class CardComponent implements OnInit {
  @Input() cardTitle: string;
  @Input() cardClass: string;
  @Input() blockClass: string;
  @Input() headerClass: string;
  @Input() options: boolean;
  @Input() hidHeader: boolean;
  @Input() customHeader: boolean;
  @Input() cardCaption: string;
  @Input() captionClass: string;
  @Input() isCardFooter: boolean;
  @Input() footerClass: string;
  @Input() moduleName: any;
  @Input() module: any;
  @Output("jobReload") jobReload = new EventEmitter<any>();
  @Output("formTypeReload") formTypeReload = new EventEmitter<any>();
  @Output("addVehicle") addVehicle = new EventEmitter<any>();
  @Output("addUser") addUser = new EventEmitter<any>();
  @Output("addQuestion") addQuestion = new EventEmitter<any>();
  @Output("addForm") addForm = new EventEmitter<any>();
  @Output("sendMessage") sendMessage = new EventEmitter<any>();
  @Output("addFormType") addFormType = new EventEmitter<any>();
  @Output("exportStaffs") exportStaffs = new EventEmitter<any>();
  // @Output('exportJobs') exportJobs = new EventEmitter<any>();
  @Output("export") exports = new EventEmitter<any>();
  @Output("addDisposal") createDisp = new EventEmitter<any>();
  @Output("import") imports = new EventEmitter<any>();
  @Output("importStaff") importStaffs = new EventEmitter<any>();

  public animation: string;
  public fullIcon: string;
  public isAnimating: boolean;
  /*public animator: AnimationBuilder;
  public animators: AnimationBuilder;*/

  public collapsedCard: string;
  public collapsedIcon: string;

  public loadCard: boolean;

  public cardRemove: string;
  exportDisposalLocations: any;
  token: any;
  constructor(
    /*animationService: AnimationService,*/ config: NgbDropdownConfig,
    private jobService: JobService
  ) {
    config.placement = "bottom-right";
    this.customHeader = false;
    this.options = true;
    this.hidHeader = false;
    this.isCardFooter = false;
    this.cardTitle = "";

    /*this.animator = animationService.builder();
    this.animators = animationService.builder();
    this.animator.useVisibility = true;*/
    this.fullIcon = "icon-maximize";
    this.isAnimating = false;

    this.collapsedCard = "expanded";
    this.collapsedIcon = "icon-minus";

    this.loadCard = false;

    this.cardRemove = "open";
    this.token = localStorage.getItem("token");
    this.exportDisposalLocations = `${environment.apiURL}disposallocation/export-disposalLocations`;
  }

  ngOnInit() {
    if (this.hidHeader) {
      this.options = false;
    }

    if (!this.options || this.hidHeader || this.customHeader) {
      this.collapsedCard = "false";
    }
  }fexpor

  public fullCardToggle(
    element: HTMLElement,
    animation: string,
    status: boolean
  ) {
    animation = this.cardClass === "full-card" ? "zoomOut" : "zoomIn";
    this.fullIcon =
      this.cardClass === "full-card" ? "icon-maximize" : "icon-minimize";
    // const duration = this.cardClass === 'full-card' ? 300 : 600;
    this.cardClass =
      this.cardClass === "full-card" ? this.cardClass : "full-card";
    if (status) {
      this.animation = animation;
    }
    this.isAnimating = true;

    /*this.animators
      .setType(this.animation)
      .setDuration(500)
      .setDirection('alternate')
      .setTimingFunction('cubic-bezier(0.1, -0.6, 0.2, 0)')
      .animate(element)
      .then(() => {
        this.isAnimating = false;
      })
      .catch(() => {
        this.isAnimating = false;
      });*/
    setTimeout(() => {
      this.cardClass = animation === "zoomOut" ? "" : this.cardClass;
      if (this.cardClass === "full-card") {
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("body").removeAttribute("style");
      }
    }, 500);
  }

  collapsedCardToggle() {
    this.collapsedCard =
      this.collapsedCard === "collapsed" ? "expanded" : "collapsed";
    this.collapsedIcon =
      this.collapsedCard === "collapsed" ? "icon-plus" : "icon-minus";
  }
  reload(event) {
    console.log("event");
    this.jobReload.emit(true);
  }
  formTypeReloading(event) {
    console.log("event");
    this.formTypeReload.emit(true);
  }
  createVehicle(event) {
    console.log("event");
    this.addVehicle.emit(true);
  }
  createDisposal(event) {
    console.log("event");
    this.createDisp.emit(true);
  }
  import(event) {
    console.log("event");
    this.imports.emit(true);
  }
  export(event) {
    this.exports.emit(true);
  }
  createStaff(event) {
    this.addUser.emit(true);
  }
  createQuestion(event) {
    this.addQuestion.emit(true);
  }

  createForm(event) {
    this.addForm.emit(true);
  }
  createMessage(event) {
    this.sendMessage.emit(true);
  }

  createFormType(event) {
    this.addFormType.emit(true);
  }

  exportStaff(event) {
    this.exportStaffs.emit(true);
  }
  // exportJob(event) {
  //   this.exportJobs.emit(true);
  // }
  importStaff(event) {
    console.log("event import staff");
    this.importStaffs.emit(true);
  }
  cardRefresh() {
    this.loadCard = true;
    this.cardClass = "card-load";
    setTimeout(() => {
      this.loadCard = false;
      this.cardClass = "expanded";
    }, 3000);
  }

  cardRemoveAction() {
    this.cardRemove = this.cardRemove === "closed" ? "open" : "closed";
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
