import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingGuard implements CanActivate {
  constructor(
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const menuList = JSON.parse(localStorage.getItem("accessModule"));
      const adminModule = menuList.find(
        (item) => item && item.moduleName === "Messaging"
      );
      console.log("admin module", adminModule);
  
      if (adminModule?.objectArray?.read) {
        return true;
      }
      if (localStorage.getItem("token")) {
        history.back();
      }
      return this.router.navigate(["/login"]);
  }
  
}
