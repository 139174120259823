<div class="container h-100">
  <div class="row align-items-center h-100">
    <div class="col-6 mx-auto">
     
 
          <div class='row'>
              <div class='ui-g-12  mt-3' *ngIf="_data.url">
                <img src="{{_data.url}}" style="width: 100%;" />
    
              </div>
              <div class='ui-g-12  mt-3' *ngIf="!_data.url">
                <img src="{{_data}}" style="width: 100%;"/>
    
              </div>
            </div>
       

       
        
    </div>
  </div>
</div>