import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class NewInspectionReportService {
  startDate: any;
  endDate: any;
  constructor(private http: HttpClient) {}

  fetchReports(data?: any) {
    if (data && data.endDate) {
      let tempDate = new Date(data.endDate);
      tempDate.setHours(23, 59, 59, 999);
      data.endDate = tempDate;
    }
    if (data && Object.keys(data).length == 0) {
      return this.http.get(environment.apiURL + "inspectionReport/list").pipe(
        map((response: any) => {
          return response;
        })
      );
    } else {
      return this.http
        .post(environment.apiURL + "inspectionReport/list", data)
        .pipe(
          map((response: any) => {
            return response;
          })
        );
    }
  }
  fetchReportsById(id) {
    return this.http.get(environment.apiURL + "inspectionReport/" + id).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  fetchNewSubmittedReports(id, isSuperAdmin) {
    let url = "";
    if (isSuperAdmin) {
      url =
        environment.apiURL +
        "inspectionReport/fetchNewReports/" +
        id +
        "?superAdmin=true";
    } else {
      url = environment.apiURL + "inspectionReport/fetchNewReports/" + id;
    }
    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  deleteReport(id) {
    return this.http
      .delete(environment.apiURL + "inspectionReport/delete/" + id)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  saveReports(inspectionReportDetails: any) {
    if (
      inspectionReportDetails.reportAccepted == true ||
      inspectionReportDetails.reportAccepted == "true"
    ) {
      inspectionReportDetails.acceptedBy =
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName");
      inspectionReportDetails.acceptedDate = new Date();
    } else {
      inspectionReportDetails.acceptedDate = null;
    }
    delete inspectionReportDetails._id;
    return this.http
      .post(
        environment.apiURL + "inspectionReport/update",
        inspectionReportDetails
      )
      .pipe(map((res: any) => res.data));
  }
  setStartAndEndDate(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
