import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

enum MessageStatus {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Sent = 'sent'
}


@Injectable({
  providedIn: 'root'
})
export class MessagingDataService {
  status: MessageStatus;
  startDate: any;
  endDate: any;
  constructor(private http: HttpClient) { }

  messageList(data?: any) {
    if (data && data.endDate) {
      let tempDate = new Date(data.endDate);
      tempDate.setHours(23, 59, 59, 999);
      data["to_date"] = tempDate;
      delete data.endDate;
    }
    if (data && data.startDate) {
      data["from_date"] = data.startDate
    }
    if (data && Object.keys(data).length == 0) {
      return this.http.get(environment.messagingApiURL + "messages/").pipe(
        tap((response: any) => {
          return response;
        })
      );
    } else {
      return this.http.post(environment.messagingApiURL + "messages/", data).pipe(
        tap((response: any) => {
          return response;
        })
      );
    }
  }

  getMessageDetails(id: string) {
    return this.http.get(environment.messagingApiURL + `messages/${id}`).pipe(
      tap((response: any) => {
        return response;
      })
    )
  }

  setParams(startDate, endDate, status) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.status = status;
  }

  getParams() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      status: this.status
    };
  }

  deleteMessage(id) {
    return this.http.delete(environment.messagingApiURL + `messages/delete/${id}`).pipe(
      tap((response: any) => {
        return response;
      })
    )
  }
}
