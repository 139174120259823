import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  constructor(private http: HttpClient) {}
  fetchChatsFromReportId(id) {
    return this.http
      .get(environment.chatApiUrl + "ChatRoute/getChatByIdJobOrReportId/" + id)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  insertChat(data) {
    return this.http.post(environment.chatApiUrl + "ChatRoute/addChat", data);
  }

  updateChat(id, data) {
    return this.http.post(
      environment.chatApiUrl + "ChatRoute/updateChat/" + id,
      data
    );
  }

  sendNotification(data) {
    return this.http.post("https://fcm.googleapis.com/fcm/send", data);
  }
  getNotifications(id) {
    return this.http.get(environment.chatApiUrl + "userMessageResponse/fetchUsersResponse");
  }
  setMessageToRead(id) {
    return this.http.post(environment.chatApiUrl + "userMessageResponse/updateUsersResponse/"+id, {});
  }
  deleteReadMessage(id) {
    return this.http.delete(environment.chatApiUrl + "userMessageResponse/deleteUsersResponse/"+id);
  }
}
