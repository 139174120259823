<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card cardClass="table-card list-category" blockClass="p-0">
      <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->
      <div class="row padding-10">
        <div class="ui-g-11">
          <h1>{{ paramsData?.type }} for {{ paramsData?.formName }}</h1>
        </div>
        <div class="ui-g-1">
          <div
            (click)="closeEvent($event)"
            pTooltip="Click to close user permission"
            tooltipPosition="top"
          >
            <i class="fa fa-window-close fa-2x"></i>
          </div>
        </div>
      </div>
      <div class="row padding-10">
        <div class="ui-g-2">
          <button
            type="button"
            class="btn btn-primary"
            (click)="reloadFormList()"
          >
            <i class="feather icon-refresh-ccw"></i> RELOAD
            <!-- RELOAD -->
          </button>
        </div>
        <div class="ui-g-2">
          <button
            type="button"
            class="btn btn-success"
            pTooltip="Click to save user access"
            tooltipPosition="top"
            *ngIf="paramsData?.type === 'Users'"
            (click)="addStaffUser()"
          >
            <i class="feather icon-user-plus"></i> ADD
          </button>
          <button
            type="button"
            class="btn btn-success"
            pTooltip="Click to save user access"
            tooltipPosition="top"
            *ngIf="paramsData?.type === 'Administrator'"
            (click)="addStaffUser()"
          >
            <i class="feather icon-user-plus"></i> ADD
          </button>
        </div>
      </div>

      <p-table
        #dt
        [value]="formData?.adminUserPermission"
        [(selection)]="selectedCatgeory"
        dataKey="_id"
        styleClass="ui-table-customers"
        [rowHover]="true"
        [rows]="20"
        [showCurrentPageReport]="true"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [globalFilterFields]="searchArr"
        *ngIf="paramsData?.type === 'Administrator'"
      >
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search"
            />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of colsForAdmin" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
          </tr>
          <!-- <tr>
            <th></th>
          </tr> -->
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of colsForAdmin">
              <span
                *ngIf="col?.field === 'userFullName' || col?.field === 'email'"
              >
                {{ car[col.field] }}
              </span>
              <span *ngIf="col?.field === 'reportTo'">
                <p-checkbox
                  name="active"
                  ngDefaultControl
                  class="map-checkbox text-allign-btns"
                  binary="false"
                  [(ngModel)]="car.reportTo"
                  (onChange)="removeOrAddAccessToAdmin($event, car, 'reportTo')"
                ></p-checkbox>
              </span>
              <span *ngIf="col?.field === 'signOff'">
                <p-checkbox
                  name="active"
                  ngDefaultControl
                  class="map-checkbox text-allign-btns"
                  binary="false"
                  [(ngModel)]="car.signOff"
                  (onChange)="removeOrAddAccessToAdmin($event, car, 'signOff')"
                ></p-checkbox>
              </span>
            </td>
            <td class="flex-between">
              <span
                pTooltip="Click to delete a Form"
                tooltipPosition="top"
                class="style-toggler mob_icon delete_icon"
              >
                <i
                  class="fa fa-trash fa-2x"
                  (click)="confirmDelete(car, 'admin')"
                ></i>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template
          class="noRecords"
          *ngIf="formData?.adminUserPermission?.length == 0"
          pTemplate="emptymessage"
        >
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="colsForAdmin.length + 1">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>

      <p-table
        #dt
        [value]="formData?.staffUserPermission"
        [(selection)]="selectedCatgeory"
        dataKey="_id"
        styleClass="ui-table-customers"
        [rowHover]="true"
        [rows]="20"
        [showCurrentPageReport]="true"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [globalFilterFields]="searchArr"
        *ngIf="paramsData?.type === 'Users'"
      >
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search"
            />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of colsForStaff" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <!-- <th></th> -->
          </tr>
          <!-- <tr>
            <th *ngFor="let col of colsForStaff" [ngSwitch]="col.field">
            </th>
            <th></th>
          </tr> -->
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr>
            <td *ngFor="let col of colsForStaff">
              <span
                *ngIf="col?.field === 'userFullName' || col?.field === 'email'"
              >
                {{ car[col.field] }}
              </span>
              <span *ngIf="col?.field === 'active'">
                <p-checkbox
                  name="active"
                  ngDefaultControl
                  class="map-checkbox text-allign-btns"
                  binary="false"
                  [(ngModel)]="car.active"
                  (onChange)="removeUsersAccess($event, car, 'staff')"
                ></p-checkbox>
              </span>
              <!-- {{ car[col.field] }} -->
            </td>

            <!-- <td class="flex-between">
              <p-checkbox
                name="active"
                ngDefaultControl
                [(ngModel)]="car.active"
                class="map-checkbox text-allign-btns"
                (onChange)="removeUsersAccess($event, car)"
                label="Active"
                binary="false"
              ></p-checkbox>
            </td> -->
            <td class="flex-between">
              <i
                class="fa fa-trash fa-2x"
                (click)="confirmDelete(car, 'staff')"
              ></i>
            </td>
          </tr>
        </ng-template>
        <ng-template
          class="noRecords"
          *ngIf="formData?.staffUserPermission?.length == 0"
          pTemplate="emptymessage"
        >
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="colsForStaff.length + 1">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>
    </app-card>
  </div>
  <!-- tabs card end -->
</div>

<div *ngIf="showAdd">
  <app-add-edit-view-user-access-form-builder
    (closeEvents)="closePopup($event)"
    [data]="userList"
    [type]="paramsData?.type"
    [formData]="formData"
  >
  </app-add-edit-view-user-access-form-builder>
</div>
