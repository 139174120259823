<div class="row">
  <div class="col-sm-12">
    <app-card cardClass="table-card view-job-details" blockClass="p-0">
      <div class="row">
        <div class="ui-g-11">
          <h1 class="parent">{{ reportDetails?.live_report_number }}</h1>
          <!-- <p-button label="Chat" class="chat"> </p-button> -->
        </div>
        <div class="ui-g-1">
          <div
            (click)="closeEvent($event)"
            pTooltip="Click to close a Inspection Report"
            tooltipPosition="top"
          >
            <i class="fa fa-window-close fa-2x"></i>
          </div>
        </div>
      </div>
      <div class="row view-job-tab">
        <div class="ui-g-12">
          <div class="container h-100 job-details">
            <div class="row align-items-center h-100">
              <div class="col-12 mx-auto">
                <div class="row report_details-wrap">
                  <div class="col-12">
                    <div class="row">
                      <!-- <div class="col">
                                      <p-button label="Back" (onClick)="backToReport($event)" *ngIf='!isEditView'></p-button>
                                      <p-button label="Cancel" (onClick)="backToReport($event)" *ngIf='isEditView'></p-button>
                                    </div> -->
                      <div
                        class="col pull-right"
                        *ngIf="checkPermissions('edit', 'Archived Reports')"
                      >
                        <p-button
                          label="Edit"
                          *ngIf="!reportDetails?.reportAccepted && !isEditView"
                          (onClick)="editReport($event)"
                        >
                        </p-button>
                        <p-button
                          label="Save"
                          *ngIf="isEditView"
                          (onClick)="saveReport($event)"
                        ></p-button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="contentArea">
                  <div class="row my-3">
                    <div class="col">
                      <div class="row">
                        <div class="col bolderTxt">
                          <b>Report Number</b
                          ><span class="mx-3">{{
                            reportDetails?.live_report_number
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <b>Date and Time for report</b
                          ><span class="mx-3">{{
                            reportDetails?.date_time_submit
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <b>Company Name</b
                          ><span class="mx-3">{{
                            reportDetails?.company.companyName
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <b>Location </b>
                      <span class="mx-3">
                        {{ reportDetails?.pickupLocationDetails?.address }}
                      </span>
                      <span
                        class="mx-3"
                        *ngIf="reportDetails?.pickupLocationDetails?.location"
                        (click)="
                          viewGoogleMap(
                            reportDetails.pickupLocationDetails.location
                          )
                        "
                      >
                        <button class="btn button_txtlink">
                          View in Google Map
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <b>Submitted {{ reportDetails?.formType }} Report</b>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div *ngFor="let data of data_list">
                        <b>{{ data[0] }}</b>
                        <p>{{ data[1] }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-6">
                      <b>Check on Actions for Improvement </b>
                    </div>
                    <div class="col-12 mb-3" *ngIf="!isEditView">
                      {{ improvement }}
                    </div>
                    <div class="col-12 mb-3" *ngIf="isEditView">
                      <textarea
                        class="mx-3"
                        style="width: 95%"
                        [(ngModel)]="improvement"
                      ></textarea>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <div class="row">
                        <div class="col"><b>Photos</b></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <!-- <div class='lightbox' (click)="openPopUp(photos)" *ngFor='let photos of reportDetails?.photos'>
                                      <img src="{{photos.fileURL}}"> -->
                      <div class="ui-g-8 ui-element">
                        <div *ngFor="let i of reportDetails?.photos">
                          <img
                            src="{{ i.fileURL }}"
                            (click)="viewImage(i)"
                            class="image-cls"
                          />
                        </div>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-6"><b>Notes </b></div>
                    <div class="col-12 mb-3" *ngIf="!isEditView">
                      {{ reportDetails?.notes }}
                    </div>
                    <div class="col-12 mb-3" *ngIf="isEditView">
                      <textarea
                        class="mx-3"
                        style="width: 95%"
                        [(ngModel)]="reportDetails.notes"
                      ></textarea>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col">
                      <b>Report Accepted</b>
                      <span class="mx-3" *ngIf="!isEditView">{{
                        reportDetails?.reportAccepted ? "Yes" : "No"
                      }}</span>
                      <select
                        *ngIf="isEditView"
                        class="w-50 mx-3"
                        [(ngModel)]="reportDetails.reportAccepted"
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>

                    <div class="col">
                      <b>Accepted By</b
                      ><span class="mx-3" *ngIf="!isEditView">{{
                        reportDetails?.acceptedBy
                      }}</span>
                      <!-- <input type="text" class="mx-3" [(ngModel)]="reportDetails.acceptedBy" *ngIf='isEditView'> -->
                      <span class="mx-3">{{ reportDetails?.acceptedBy }} </span>
                    </div>
                    <div class="col">
                      <b>Date</b>
                      <span class="mx-3"
                        >{{ reportDetails?.acceptedDate }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row mb-3 contentFooter">
                  <div class="col-12">
                    <div class="row">
                      <div class="col">
                        <!-- <p-button label="Back" (onClick)="backToReport($event)" *ngIf='!isEditView'></p-button>
                                      <p-button label="Cancel" (onClick)="backToReport($event)" *ngIf='isEditView'></p-button> -->
                      </div>
                      <div
                        class="col pull-right"
                        *ngIf="checkPermissions('edit', 'Archived Reports')"
                      >
                        <p-button
                          label="Edit"
                          *ngIf="!reportDetails?.reportAccepted && !isEditView"
                          (onClick)="editReport($event)"
                        >
                        </p-button>
                        <p-button
                          label="Save"
                          *ngIf="isEditView"
                          (onClick)="saveReport($event)"
                        ></p-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="loader" *ngIf="showLoader"></div>
        </div>
      </div>
    </app-card>
  </div>
</div>
