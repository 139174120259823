import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChatComponent } from "./chat/chat.component";
import { FormsModule } from "@angular/forms";
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { SearchFilterAddComponent } from './search-filter-add/search-filter-add.component';
import { DropdownListItemComponent } from './dropdown-list-item/dropdown-list-item.component';
import { CheckboxModule } from "primeng";

@NgModule({
  declarations: [ChatComponent, SelectDropdownComponent, SearchFilterAddComponent, DropdownListItemComponent],
  imports: [CommonModule, FormsModule, CheckboxModule],
  exports: [ChatComponent, SelectDropdownComponent, SearchFilterAddComponent, DropdownListItemComponent],
})
export class SharedComponentsModule {}
