import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AdminUserService } from "./admin-users.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { JobService } from "../job/job-data.service";
@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.scss"],
})
export class AdminUsersComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen: any;
  companyID: any;
  searchArr = [];
  showAdd = false;
  cols = [
    {
      header: "First Name",
      field: "name",
    },
    {
      header: "Admin Email",
      field: "email",
    },
    {
      header: "Company Name",
      field: "companyName",
    },
    // {
    //     header: 'Active',
    //     field: 'active'
    // }
  ];
  adminData: any;
  adminUserList = [];
  showLoader = true;
  selectedAdmin: any;
  constructor(
    private service: AdminUserService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private router: Router,
    private jobService: JobService
  ) {}

  ngOnInit() {
    this.clearIndex();
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");

    this.fetchStaffList();
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
    console.log("searchArr", this.searchArr);
  }
  callList(event) {
    console.log("call list");
    this.showAdd = false;
    this.isView = false;
    this.adminData = {};
    this.cdr.detectChanges();
    this.fetchStaffList();
  }
  clearIndex() {
    localStorage.removeItem("jobID");
    localStorage.removeItem("pageJobIndex");
    localStorage.removeItem("pageDisposalIndex");
    localStorage.removeItem("pageWasteIndex");
    localStorage.removeItem("pageReportIndex");
    localStorage.removeItem("pageInspectionReportIndex");
  }
  change(event, value) {
    let obj;
    obj = {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      company: value.company.companyId,
      //  password: value.password,
      active: value.active,
      _id: value._id,
    };
    console.log("event", obj);
    this.service.updateAdmin(obj).subscribe((data) => {});
  }
  createAdmin(event) {
    console.log("create staff called");
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.adminData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
    }

    this.cdr.detectChanges();
  }
  editAdmin(event, data) {
    this.service.getDetails(data._id).subscribe((response) => {
      console.log("user  details", response);
      this.adminData = response.data[0];
      this.showAdd = true;
      console.log("user  details", this.adminData);

      this.cdr.detectChanges();
      // editData = response.data[0];
      // this.showLoader = false;
      // const ref = this.dialogService.open(AddEditStaffComponent, {
      //     data: {
      //         viewData: editData,
      //         isUpdate: true
      //     },
      //     header: 'Edit Staff Details',
      //     width: '600px',
      // });

      // ref.onClose.subscribe((car) => {
      //     if (car) {
      //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
      //      this.fetchStaffList();
      //     }
      // });
    });
  }
  viewAdmin(event, data) {
    this.service.getDetails(data._id).subscribe((response) => {
      console.log("user  details", response);
      this.adminData = response.data[0];
      this.isView = true;
      this.showAdd = true;
      console.log("user  details", this.adminData);

      this.cdr.detectChanges();
    });
  }
  deleteAdmin(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Admin?",
      accept: () => {
        this.service.deleteUser(id).subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.messageService.add({
                severity: "success",
                summary: "Delete Admin",
                detail: "Admin details deleted successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Delete Admin",
                detail: data.error.message,
              });
            }
            this.fetchStaffList();
          },
          (err) => {
            console.log("error", err);
            this.messageService.add({
              severity: "error",
              summary: "Delete Admin",
              detail: "Failed to delete the Admin",
            });
            this.fetchStaffList();
          }
        );
        //  });
      },
    });
  }
  fetchStaffList() {
    const staffArr = [];
    this.service.fetchUser().subscribe((response) => {
      console.log("staff  details", response);

      // response.data.forEach((ele) => {
      //     ele.isActive = ele.active ? 'Yes' : 'No';
      // });
      if (response.data.length > 0) {
        response.data.forEach((element) => {
          console.log("element", element, this.companyID);
          if (this.isAcumen == "true" || this.isAcumen == true) {
            staffArr.push(element);
          } else {
            if (element.company) {
              if (element.company.companyId == this.companyID) {
                staffArr.push(element);
              }
            }
          }
        });
      }
      this.adminUserList = staffArr;
      if (this.adminUserList.length > 0) {
        this.adminUserList.forEach((ele) => {
          if (ele.company) {
            ele.companyId = ele.company.companyId;
            ele.companyName = ele.company.companyName;
          }
          ele.name = ele.firstName + " " + ele.lastName;
        });
      }
      this.showLoader = false;
    });
  }

  navigateToUsersAccess(id) {
    this.router.navigate(["user-permission", id]);
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
