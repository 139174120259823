import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ChatService } from '../chat/chat.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  slide = true;
  @Output("closeWindow") closeWindow = new EventEmitter<any>();
  @Input("data") data: any = {};
  @Input("userData") userData: any = {};
  intervalData: any;

  constructor(private router: Router, private chatService: ChatService) { }

  ngOnInit() {
    console.log(this.data);
    this.intervalData = setInterval(() => {
      this.fetchNewNotifications();

    }, 2000)
  }

  closeNotifications() {
    clearInterval(this.intervalData);
    this.closeWindow.emit(true)
  }

  openMessages(data) {
    this.setMessageAsRead(data)
    clearInterval(this.intervalData);
    switch (data?.page) {
      case 'reports':
        this.router.navigate(['new-inspection-report', data?.jobOrReportId])
        this.closeWindow.emit(true);
        break;
      case 'skips':
        this.router.navigate(['job/data', data?.jobOrReportId])
        this.closeWindow.emit(true);
        break;
      case 'hazardous':
        this.router.navigate(['job/data', data?.jobOrReportId])
        this.closeWindow.emit(true);
        break;
      case 'waste':
        this.router.navigate(['reports', data?.jobOrReportId])
        this.closeWindow.emit(true);
        break;
      case 'directTip':
        this.router.navigate(['job/data', data?.jobOrReportId])
        this.closeWindow.emit(true);
        break;
    }
  }

  setMessageAsRead(data) {
    this.chatService.setMessageToRead(data?.id).subscribe((res) => {
      console.log(res);
      this.fetchNewNotifications();
    }, (error) => {
      console.log(error)
    })
  }

  deleteMessage(data) {
    this.chatService.deleteReadMessage(data?.id).subscribe((res) => {
      console.log(res);
    }, (error) => {
      console.log(error)
    })
  }
fetchNewNotifications() {
  this.chatService.getNotifications(this.userData?._id).subscribe((response: any) => {
    console.log(response);
    if(JSON.stringify(this.data) !== JSON.stringify(response?.data)) {
      this.data = response.data;
      localStorage.setItem('notifications', JSON.stringify(this.data));
    }
  },(error) => {
    console.log(error)
  })
}
}
