import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessagingDataRoutingModule } from './messaging-data-routing.module';
import {SharedModule} from '../../../theme/shared/shared.module';
import {NgbCarouselModule, NgbProgressbarModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from 'primeng/table';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TooltipModule} from 'primeng/tooltip';
import { MessagingDataComponent } from './messaging-data.component';
import { CheckboxModule, DialogModule, DropdownModule, EditorModule, TabViewModule } from 'primeng';
import { SharedComponentsModule } from 'src/app/shared-components/shared-components.module';
import { SendEditMessageComponent } from '../send-edit-message/send-edit-message.component';
import { ScheduleMessageComponent } from '../schedule-message/schedule-message.component';
import { ViewMessageDetailsComponent } from '../view-message-details/view-message-details.component';


@NgModule({
  declarations: [MessagingDataComponent, SendEditMessageComponent, ScheduleMessageComponent, ViewMessageDetailsComponent],
  imports: [
    TabViewModule,
    EditorModule,
    DialogModule,
    SharedComponentsModule,
    CommonModule,
    MessagingDataRoutingModule,
    SharedModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    ToastModule,
    DropdownModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MessageModule,
    MessagesModule,
    TooltipModule,
    NgbCarouselModule,
    CalendarModule,
    CheckboxModule,
  ],
  exports: [SendEditMessageComponent, ScheduleMessageComponent]
})
export class MessagingDataModule { }
