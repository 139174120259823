import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { JobService } from "../../job/job-data.service";
import { WasteCategoryService } from "../waste-category.service";

@Component({
  selector: "app-alert-text",
  templateUrl: "./alert-text.component.html",
  styleUrls: ["./alert-text.component.scss"],
})
export class AlertTextComponent implements OnInit {
  alertTextForm: FormGroup;
  _data: any;
  slide = true;
  @Input("data") viewData: any = {};
  @Input("isView") isView = false;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  constructor(
    private service: WasteCategoryService,
    private cdr: ChangeDetectorRef,
    private jobService: JobService
  ) {
    this.alertTextForm = new FormGroup({
      alert_text: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    if (this.viewData && Object.keys(this.viewData).length !== 0) {
      console.log(" true vehicleData", this.viewData);
      this._data = this.viewData;
      if (this._data.alert_text) {
        this.alertTextForm.controls["alert_text"].setValue(
          this._data.alert_text
        );
      }
    }
  }
  closeEvent(event) {
    this.slide = false;
    this.cdr.detectChanges();
    setTimeout((e) => {
      this.closeEvents.emit(true);
    }, 1000);
  }
  save(event) {
    let value = this._data;
    value.alert_text = this.alertTextForm.value.alert_text;
    // console.log('form',this.alertTextForm.value);
    //    value.wasteTypeId = this._data.wasteTypeId;
    //    value.linked_waste_category = this._data.linked_waste_category;
    //    value.companyId = this._data.companyId;
    //    value.default_min_tonnage = this._data.default_min_tonnage?this._data.default_min_tonnage:'';
    //    value.metal_slider = this._data.metal_slider?this._data.metal_slider:false;
    //    value.alert = this._data.alert?this._data.alert:false;
    //   value._id = this._data._id;
    console.log("data", value);
    this.service.updateWasteType(value).subscribe((data: any) => {
      this.closeEvent(event);
    });
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
  checkPermissionsToDisableCheckbox(type, module) {
    return this.jobService.checkPermissionsToDisableCheckbox(type, module);
  }
}
