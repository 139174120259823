import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SendEditMessageService } from '../send-edit-message/send-edit-message.service';
import { MessageService } from 'primeng';
import { map } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';
@Component({
  selector: 'app-schedule-message',
  templateUrl: './schedule-message.component.html',
  styleUrls: ['./schedule-message.component.scss']
})
export class ScheduleMessageComponent implements OnInit {
  @Output() scheduleDate = new EventEmitter<any>();
  @Output() clearScheduleEvent = new EventEmitter<any>();
  @Output() toggleScheduleMode = new EventEmitter<any>();
  @Input() date;
  @Input() dateSet: boolean;
  minSelectableDate: Date = new Date();
  private timerSubscription: Subscription;
  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,) { 
    }
  @Output('closeEvents') closeEvents = new EventEmitter<any>();
  slide = true;
  ngOnInit(): void {
   this.updateMinDate();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  closeEvent(event) {
    this.slide = false;
    setTimeout(e => {
      this.closeEvents.emit(true);
    }, 1000)
  }

  scheduleMessage(event) {
    this.slide = false;
    this.messageService.add({
      severity: "success",
      summary: `Schedule set to ${this.formatDate(this.date)}`,
      detail: "Message scheduled successfully",
    });
    if (this.dateSet !== true) {
      this.toggleScheduleMode.emit();
    }
    this.scheduleDate.emit(this.date);
    setTimeout(e => {
      this.closeEvents.emit(true);
    }, 1000)
  }

  clearSchedule(event) {
    this.slide = false;
    this.messageService.add({
      severity: "success",
      summary: "Schedule cleared",
      detail: "Schedule cleared successfully",
    });
    this.toggleScheduleMode.emit();
    setTimeout(e => {
      this.clearScheduleEvent.emit();
      this.closeEvents.emit(true);
    }, 1000)
  }

  formatDate(date) {
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'full',
      timeStyle: 'long',
    }).format(date);
  }

  updateMinDate() {
    const timer$ = interval(1000); 

    this.timerSubscription = timer$.pipe(
      map(() => new Date())
    ).subscribe((currentDate: Date) => {
      this.minSelectableDate = currentDate;
      if (this.date < this.minSelectableDate) {
        this.date = currentDate;
      }
    });
  }
}
