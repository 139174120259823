import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  HostListener,
  OnInit,
  Output,
  ChangeDetectorRef,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Inject,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { FormBuildersService } from "../form-builders.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { DragulaService } from "ng2-dragula";
import { FormSectionsService } from "../../form-sections/form-sections.service";
import * as _ from "underscore";
import differenceBy from "lodash/differenceBy";
import * as moment from "moment";
import { DOCUMENT } from "@angular/common";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";

@Component({
  selector: "app-add-edit-view-form-builders",
  templateUrl: "./add-edit-view-form-builders.component.html",
  styleUrls: ["./add-edit-view-form-builders.component.scss"],
})
export class AddEditViewFormBuildersComponent implements OnInit, OnChanges {
  userForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  isUpdate = false;
  slide = true;
  clickOpen = false;
  isEdit = false;
  errorMessage = false;
  isAcumen: any;
  companyID: any;
  categoryList = [];
  companyList = [{ label: "Please Select", value: "" }];
  maxDateOptions;
  selectedSection = [];
  allocatedSection = [];
  isSectionSelected = false;
  selectedQues: Array<any> = [];
  selectedQuestions: Array<any> = [];
  selectedSectionObj: any = {};
  selectedQueObj: any = {};
  showLoader: boolean = false;
  showSelectedQuestionForDetails: any = false;
  selectedQuestionForDetails = {
    itemText: "",
    itemInputType: "",
    answers: "",
  };

  data: any = [];
  @Input("data") viewData: any = {};
  @Input("isView") isView = false;
  @Input("isNew") isNew = false;
  @Input("edit") edit = false;
  @Input("sectionList") sectionList = [];
  @Input("questionsList") questionsList = [];
  public toBeDeleted = [];
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  @Output("openAddQues") openAddQues = new EventEmitter<any>();
  @Output("openAddSection") openAddSection = new EventEmitter<any>();
  hideAllocateSectionButton: boolean;
  dragData: any;
  initalValues: any;
  showPopup: boolean = false;
  // ids for connected drop lists
  dropTargetIds = [];
  nodeLookup = {};
  dropActionTodo = null;
  public keyName = "itemName";
  public isAllocatedQuestionsOrderChanged = false;
  formSectionDataSelected: boolean = false;

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private service: FormBuildersService,
    private eRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _ds: DragulaService,
    private confirmDialogService: ConfirmationService,
    private sectionService: FormSectionsService,
    private formBuilderService: FormBuildersService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._ds.createGroup("form-section-items", {});
    this._ds.createGroup("form-question-items", {});
    this.prepareDragDrop(this.allocatedSection);
  }

  ngOnInit() {
  // localStorage.setItem('originalSectionIdArray', JSON.stringify(this.viewData.sectionIdArray));
    this._ds.drop("form-section-items").subscribe((args) => {
      this.allocatedSection.map((item: any, index) => {
        item.sectionOrderNumber = index + 1;
      });
    });
    this._ds.drop("form-question-items").subscribe((args) => {});
    this.fetchFormTypes();
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");
    this.loadData();
    // this.fetchSectionList();
    this.maxDateOptions = {
      attrs: {
        id: "max-date",
        placeholder: "None Set",
      },
      inputClasses: ["form-control"],
    };
    // this.companyDepartmentList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.isEdit = changes.edit.currentValue;
    this.isView = changes.isView.currentValue;
    this.isNew = changes.isNew.currentValue;
    this.addForm = new FormGroup({
      formName: new FormControl("", [Validators.required]),
      formType: new FormControl("", [Validators.required]),
      active: new FormControl(true),
      mobileView: new FormControl(true),
      dateActive: new FormControl(moment().format("YYYY-MM-DD")),
      sectionIdArray: new FormControl([]),
    });

    if (changes.edit.currentValue) {
      this.addForm.patchValue({
        formName: this.viewData.formName,
        formType: this.viewData.formType,
        active: this.viewData.active,
        sectionIdArray: this.viewData.sectionIdArray,
        mobileView: this.viewData.mobileView,
        dateActive: moment(this.viewData.createdDate).format("YYYY-MM-DD"),
      });

      this.allocatedSection = this.viewData.sectionIdArray;
      this.allocatedSection.forEach(element => {
          element.isSelected = false;
      });
      this.allocatedSection = _.sortBy(
        this.allocatedSection,
        "sectionOrderNumber"
      );
      this.allocatedSection.map((m) => {
        m.id = m._id;
        m.children = m?.children ? m?.children : [];
        m.isExpanded = false;
      });
      this.prepareDragDrop(this.allocatedSection);
      const sectionList = JSON.parse(localStorage.getItem("sectionData"));
      this.sectionList = differenceBy(
        sectionList,
        this.allocatedSection,
        "_id"
      );
    } else if (changes.isView.currentValue) {
      this.addForm.patchValue({
        formName: this.viewData.formName,
        formType: this.viewData.formType,
        active: this.viewData.active,
        sectionIdArray: this.viewData.sectionIdArray,
        mobileView: this.viewData.mobileView,
        dateActive: moment(this.viewData.createdDate).format("YYYY-MM-DD"),
      });
      this.addForm.controls["formName"].disable();
      this.addForm.controls["formType"].disable();
      this.addForm.controls["active"].disable();
      this.addForm.controls["sectionIdArray"].disable();
      this.addForm.controls["mobileView"].disable();
      this.addForm.controls["dateActive"].disable();
      this.allocatedSection = this.viewData.sectionIdArray;
      this.allocatedSection = _.sortBy(
        this.allocatedSection,
        "sectionOrderNumber"
      );

      const sectionList = JSON.parse(localStorage.getItem("sectionData"));
      this.sectionList = differenceBy(
        sectionList,
        this.allocatedSection,
        "_id"
      );
    }
  }

  checkFormValue() {}
  ngOnDestroy() {
    this._ds.destroy("form-section-items");
    this._ds.destroy("form-question-items");
  }
  fetchSectionList() {
    this.sectionService.fetchSection().subscribe((response) => {
      try {
        this.showLoader = false;
        if (response && response.data && response.data.length) {
          this.sectionList = response.data;
        }
      } catch (e) {
        this.showLoader = false;
        console.warn(e);
      }
    });
  }
  closePopup() {
    // if(JSON.stringify())
    if (
      (this.isEdit &&
        (this.viewData.formName !== this.addForm.value.formName ||
          this.viewData.active !== this.addForm.value.active ||
          this.viewData.mobileView !== this.addForm.value.mobileView ||
          this.viewData.mobileView !== this.addForm.value.mobileView ||
          JSON.stringify(this.viewData.formType) !==
            JSON.stringify(this.addForm.value.formType) ||
          JSON.stringify(this.viewData.sectionIdArray) !==
            JSON.stringify(this.addForm.value.sectionIdArray) || JSON.stringify(this.viewData.sectionIdArray) !== localStorage.getItem('originalSectionIdArray'))) ||
      this.isAllocatedQuestionsOrderChanged
    ) {
      this.confirmDialogService.confirm({
        message:
          "You have unsaved changes. Are you sure that you want to close?",
        accept: () => {
          this.userForm.reset();
          this.selectedSectionObj = {};
          this.closeEvents.emit(true);
        },
      });
    } else if (
      this.isNew &&
      (this.addForm.value.formName?.length ||
        this.addForm.value.formType?.length ||
        this.addForm.value.sectionIdArray?.length)
    ) {
      this.confirmDialogService.confirm({
        message:
          "You have unsaved changes. Are you sure that you want to close?",
        accept: () => {
          this.userForm.reset();
          this.selectedSectionObj = {};
          this.closeEvents.emit(true);
        },
      });
    } else {
      this.userForm.reset();
      this.selectedSectionObj = {};
      this.closeEvents.emit(true);
    }
    // if (
    //   (this.isNew &&
    //     (this.addForm.value.formName?.length ||
    //       typeof this.addForm.value.formType !== "string" ||
    //       this.addForm.value.sectionIdArray?.length ||
    //       this.allocatedSection?.length <
    //         this.addForm?.value?.sectionIdArray?.length)) ||
    //   (this.isEdit && this.showPopup)
    // ) {
    //   this.confirmDialogService.confirm({
    //     message:
    //       "You have unsaved changes. Are you sure that you want to close?",
    //     accept: () => {
    //       this.userForm.reset();
    //       this.selectedSectionObj = {};
    //       this.closeEvents.emit(true);
    //     },
    //   });
    // } else {
    //   this.userForm.reset();
    //   this.selectedSectionObj = {};
    //   this.closeEvents.emit(true);
    // }
  }

  saveForm() {
    this.addForm.patchValue({
      sections: this.allocatedSection,
    });
    this.errorMessage = false;
    let payLoad = { ...this.addForm.value };
    // return  console.log(this.userForm)
    delete payLoad.sections;
    payLoad.sectionIdArray = this.allocatedSection;
    payLoad.createdBy =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
    payLoad.reviewedBy =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
    payLoad.createdDate = moment().format("DD/MM/YYYY");
    if (!this.isEdit) {
      payLoad.staffUserPermission = [];
      payLoad.adminUserPermission = [];
    }
    this.isEdit ? this.updateForm() : this.createForm(payLoad);
  }
  updateForm() {
    // return console.log('this.userForm.value', this.userForm.value)
    console.log('(this.viewData.sectionIdArray', this.viewData);

    let payLoad = { ...this.addForm.value };
    payLoad.updatedBy =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
    payLoad.updatedDate = new Date();
    payLoad.staffUserPermission = this.viewData?.staffUserPermission ? this.viewData.staffUserPermission : []
    payLoad.adminUserPermission = this.viewData?.adminUserPermission ? this.viewData.adminUserPermission : []
    this.service
      .editFormSection(payLoad, this.viewData._id)
      .subscribe((resp: any) => {
        try {
          if (resp.status == 200) {
            this.messageService.add({
              severity: "success",
              summary: "Form Updated",
              detail: "New Question created successfully",
            });
            this.ref.close(this);
            this.userForm.reset();
            this.closeEvents.emit(true);
          }
        } catch (e) {
          console.warn(e);
        }
      });
  }
  createForm(payLoad) {
    payLoad.createdDate = new Date();
    this.service.insertForm(payLoad).subscribe((resp: any) => {
      try {
        if (resp && resp.data) {
          this.messageService.add({
            severity: "success",
            summary: "Create Question",
            detail: "New Question created successfully",
          });
          this.ref.close(this);
          this.closeEvents.emit(true);
        }
      } catch (e) {
        console.warn(e);
      }
    });
  }
  // addSection() {
  //   return new FormGroup({
  //     sectionID: new FormControl("A"),
  //     sectionIndex: new FormControl(1),
  //   });
  // }

  addAnswer() {
    return new FormGroup({
      answer: new FormControl(""),
    });
  }

  addNewSection(event) {
    this.openAddSection.emit();
  }

  allocateSection(event, selectedSection) {
    this.selectedSection.map((item) => {
      item.id = item._id;
      item.children = item?.children ? item?.children : [];
      item.isExpanded = false;
      if (this.allocatedSection.length > 0) {
        item.sectionOrderNumber = this.allocatedSection.length + 1;
      } else {
        item.sectionOrderNumber = 1;
      }
      this.allocatedSection.push(item);
    });
    this.prepareDragDrop(this.allocatedSection);
    this.allocatedSection = _.sortBy(
      this.allocatedSection,
      "sectionOrderNumber"
    );
    this.selectedSection = [];
    this.selectedQues = [];
    this.selectedSectionObj = [];
    this.questionsList = JSON.parse(localStorage.getItem("questionsList"));
    const sectionList = JSON.parse(localStorage.getItem("sectionData"));
    this.sectionList = differenceBy(sectionList, this.allocatedSection, "_id");
    this.showPopup = true;
    this.showSelectedQuestionForDetails = false;
    this.addForm.controls["sectionIdArray"].patchValue(this.allocatedSection);
  }

  deleteSection(event, index, givenFormControl) {}

  sectionSelected() {
    if (this.isSectionSelected) {
      this.selectedQuestions = [];
      this.selectedQues = undefined;
    } else {
    }
    this.isSectionSelected = !this.isSectionSelected;
  }
  onSectionSelect(event) {
    this.allocatedSection.forEach((ele: any, i) => {

      ele.isSelected = false;
    });
    this.hideAllocateSectionButton = false;
    this.showSelectedQuestionForDetails = false;
    this.selectedSectionObj = event.value;
    this.questionsList = JSON.parse(localStorage.getItem("questionsList"));
    let itemArray = this.selectedSectionObj.itemIdArray;
    itemArray = itemArray.map((item) => {
      item.isSelected = false;
      return item;
    });
    this.selectedSectionObj.itemIdArray = itemArray;
    let index = this.selectedSection.findIndex(
      (val) => val._id === event.value._id
    );
    if (index != -1) return;
    this.selectedSection = [event.value];
    // this.questionsList = _.difference(this.questionsList, this.selectedSectionObj.itemIdArray,'_id')
    this.questionsList = differenceBy(
      this.questionsList,
      this.selectedSectionObj.itemIdArray,
      "_id"
    );
    const sectionList = JSON.parse(localStorage.getItem("sectionData"));
    this.sectionList = differenceBy(sectionList, [event.value], "_id");
  }
  onQuestionSelect(event) {
    // console.log('this.viewData.sectionIdArray', this.viewData.sectionIdArray);
    // console.log('this.addForm.value.sectionIdArray', this.addForm.value.sectionIdArray);
    let index = this.selectedQues.findIndex(
      (val) => val._id === event.value._id
    );
    if (index != -1) return;
    this.selectedQues.push(event.value);
    this.questionsList = differenceBy(
      this.questionsList,
      this.selectedQues,
      "_id"
    );
    this.showPopup = true;
    // console.log('this.viewData.sectionIdArray', this.viewData.sectionIdArray);
    // console.log('this.addForm.value.sectionIdArray', this.addForm.value.sectionIdArray);

  }
  allocateQuestion(event, selectedQues) {
    this.showPopup = true;
    let obj = this.selectedSectionObj;
    obj.itemIdArray = [...obj.itemIdArray, ...this.selectedQues];

    this.selectedSectionObj.itemIdArray = obj.itemIdArray;
    this.selectedQues = [];
    // this.updateSection(obj);
  }

  addNewQues(event) {
    this.openAddQues.emit();
  }

  deleteQues(event) {
    let { itemIdArray } = this.selectedSectionObj;
    itemIdArray = itemIdArray
      .filter((item) => !item.isSelected)
      .map((val) => {
        delete val.isSelected;
        return val;
      });
    this.selectedSectionObj.itemIdArray = itemIdArray;
    const questions = JSON.parse(localStorage.getItem("questionsList"));
    this.questionsList = differenceBy(
      questions,
      this.selectedSectionObj.itemIdArray,
      "_id"
    );
    this.showPopup = true;
    // console.log()
    // this.updateSection(this.selectedSectionObj);
  }

  checkBoxchange(event, i) {
    this.isAllocatedQuestionsOrderChanged = true;
    if (event.checked === true) {
      this.toBeDeleted.push(i);
    } else {
      this.toBeDeleted = this.toBeDeleted.filter((item) => item !== i);
    }
  }

  indexChange(event, i, section) {
    // section.sectionOrderNumber = event?.target?.value;
  }

  ngAfterViewInit() {
    if (!this.isUpdate) {
      this.userForm.patchValue({
        email: "",
        password: "",
      });
    }
    this.clickOpen = true;
  }
  onChange(event) {
    this.errorMessage = false;
    this.cdr.detectChanges();
  }
  editEvent(event) {
    this.isEdit = true;
    this.isUpdate = true;
    this.isView = false;
    this.loadData();
  }

  createQuestion(e) {
    let value: any = "";
    value = this.userForm.value;
    this.errorMessage = false;
  }

  updateQuestion(event: any) {
    let value: any = "";
    value = this.userForm.value;
    value._id = this.data._id;

    this.errorMessage = false;
    //  delete value.cpassword;
  }

  createAnswer(event) {
    const ansArray: FormArray = this.userForm.get(
      "questionAnswers"
    ) as FormArray;
    ansArray.push(this.addAnswer());
  }

  deleteAns(index) {
    const ansArray: FormArray = this.userForm.get(
      "questionAnswers"
    ) as FormArray;
    ansArray.removeAt(index);
  }

  loadData() {
    if (this.isUpdate) {
      this.userForm = this.editForm;
    } else {
      this.userForm = this.addForm;
    }
    console.log('view data latest',this.viewData);
    if(this.isEdit) {

      localStorage.setItem('originalSectionIdArray', JSON.stringify(this.viewData.sectionIdArray));
    }
    if (this.viewData && Object.keys(this.viewData).length !== 0) {
      if (this.viewData && this.viewData.formName) {
        this.userForm.patchValue({
          formName: this.viewData.formName,
          formType: this.viewData.formType,
          active: this.viewData.active,
          sections: this.viewData.sectionIdArray,
          mobileView: this.viewData.mobileView,
          // archived: this.viewData.archived ? this.viewData.archived : false,
        });
      }
    }
  }

  closeEvent(event) {
    if (this.isEdit == false) {
      //     var element = document.getElementById("styleSelector");
      //   element.classList.remove("animate-block");
      //     element.classList.add("slide-out");
      this.slide = false;
      this.clickOpen = false;
      this.cdr.detectChanges();
      setTimeout((e) => {
        this.closeEvents.emit(true);
      }, 1000);
    } else {
      this.isEdit = false;
      this.isView = true;
      this.isUpdate = false;
      this.loadData();
    }
  }
  updateSection(payLoad) {
    let id = this.selectedSectionObj._id;
    delete payLoad._id;
    this.errorMessage = false;
    this.sectionService.editSection(payLoad, id).subscribe((data: any) => {
      if (data.status === 200) {
        this.selectedSectionObj = { ...payLoad, _id: id };
        let index = this.selectedSection.findIndex((val) => val._id === id);
        if (index != -1) {
          this.selectedSection[index] = this.selectedSectionObj;
        }
        this.selectedQues.length = 0;
        this.messageService.add({
          severity: "success",
          summary: "Update Section",
          detail: "Section details updated successfully",
        });
      } else if (data.status === 500) {
        this.messageService.add({
          severity: "error",
          summary: "Update Section",
          detail: data.error.message.errmsg,
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Update Section",
          detail: "Unable to update Section.Please try again.",
          //  detail: 'Failed to update the user details'
        });
      }
    });
  }

  private fetchFormTypes() {
    // function used to populate category dropdown
    this.formBuilderService?.fetchFormTypes().subscribe(
      (res: any) => {
        this.categoryList = [];
        // this.categoryList = res?.data;
        // res?.data?.forEach((ele) => {
        //   this.categoryList.push({ label: ele?.formType, value: ele?._id });
        // });
        this.categoryList = res.data;
      },
      (err) => {
        console.log("err: ", err);
      }
    );
  }

  // public removeSection(index?: number) {
  //   console.log("sections: ", this.selectedSection);
  //   console.log("allocatedSection: ", this.allocatedSection);
  //   this.selectedSection.splice(index, 1);
  //   this.allocatedSection.splice(index, 1);
  //   let sectionList = JSON.parse(localStorage.getItem("sectionData"));
  //   this.sectionList = differenceBy(this.allocatedSection, sectionList, "_id");
  //   console.log("this.sectionList", this.sectionList);
  //   if (this.sectionList.length == 0) {
  //     this.sectionList = sectionList;
  //   }
  //   console.log("this.sectionList", this.sectionList);
  //   this.addForm.controls["sectionIdArray"].patchValue(this.allocatedSection);
  // }

  public removeSection(sectionData, childIndex, sectionIndex) {
    if (sectionIndex !== undefined && sectionData === undefined) {
      this.allocatedSection.splice(sectionIndex, 1);
    }

    if (sectionIndex === undefined && sectionData !== undefined) {
      let index = this.allocatedSection.findIndex(
        (f) => f.sectionTitle === sectionData.sectionTitle
      );
      if (index !== -1) {
        this.allocatedSection[index].children.splice(childIndex, 1);
      }
    }
    this.selectedSection = [];
    let sectionList = JSON.parse(localStorage.getItem("sectionData"));
    this.sectionList = differenceBy(this.allocatedSection, sectionList, "_id");
    if (this.sectionList.length == 0) {
      this.sectionList = sectionList;
    }
    this.addForm.controls["sectionIdArray"].patchValue(this.allocatedSection);
    this.selectedSectionObj.itemIdArray = []
  }

  public onAllocatedSectionClick(section, index?: number) {
    // console.log('this.viewData.sectionIdArray', this.viewData.sectionIdArray);
    // console.log('this.addForm.value.sectionIdArray', this.addForm.value.sectionIdArray);
    this.formSectionDataSelected = true;
    this.showSelectedQuestionForDetails = false;
    this.selectedSection = []
    this.allocatedSection.forEach((ele: any, i) => {
      // if(!ele[i]?.isSelected) {
      //   ele[i] = {}
      // }
      ele.isSelected = false;
    });
    // this.allocatedSection[index].isSelected = true;
    section.isSelected = true;
    this.selectedSectionObj = section;
    // this.selectedSection = [section];
    const sectionList = JSON.parse(localStorage.getItem("sectionData"));
    this.sectionList = differenceBy(sectionList, this.allocatedSection, "_id");
    this.questionsList = JSON.parse(localStorage.getItem("questionsList"));
    this.questionsList = differenceBy(
      this.questionsList,
      section.itemIdArray,
      "_id"
    );
    this.hideAllocateSectionButton = true;
  }

  drag_handler(event) {}

  exitConformation() {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to cancel the Form?",
      accept: () => {
        //  });
      },
    });
  }
  showPopUpScreen() {
    this.showPopup = true;
  }

  checkFormDisable() {
    if (this.addForm.status) {
      if (this.allocatedSection?.length > 0) {
        return false;
      }
    }

    return true;
  }

  showQuestionsDetails(data: any) {
    let answers = "";
    this.showSelectedQuestionForDetails = true;
    this.selectedQuestionForDetails.itemText = data?.itemText;
    this.selectedQuestionForDetails.itemInputType =
      data?.itemData[0]?.itemInputType;
    data?.itemData[0]?.itemData.map((item) => {
      if (!answers?.length) {
        answers = item.answer;
      } else {
        answers = answers + " , " + item.answer;
      }
    });
    this.selectedQuestionForDetails.answers = answers;
  }

  dragMoved(event) {
    let e = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y
    );

    if (!e) {
      this.clearDragInfo();
      return;
    }
    let container = e.classList.contains("node-item")
      ? e
      : e.closest(".node-item");
    if (!container) {
      this.clearDragInfo();
      return;
    }
    this.dropActionTodo = {
      targetId: container.getAttribute("data-id"),
    };
    const targetRect = container.getBoundingClientRect();
    const oneThird = targetRect.height / 3;

    if (event.pointerPosition.y - targetRect.top < oneThird) {
      // before
      this.dropActionTodo["action"] = "before";
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
      // after
      this.dropActionTodo["action"] = "after";
    } else {
      // inside
      this.dropActionTodo["action"] = "inside";
    }
    this.showDragInfo();
  }

  drop(event) {
    if (!this.dropActionTodo) return;

    const draggedItemId = event.item.data;
    const parentItemId = event.previousContainer.id;
    const targetListId = this.getParentNodeId(
      this.dropActionTodo.targetId,
      this.allocatedSection,
      "main"
    );
    const draggedItem = this.nodeLookup[draggedItemId];

    const oldItemContainer =
      parentItemId != "main"
        ? this.nodeLookup[parentItemId].children
        : this.allocatedSection;
    const newContainer =
      targetListId != "main"
        ? this.nodeLookup[targetListId].children
        : this.allocatedSection;

    let i = oldItemContainer.findIndex((c) => c.id === draggedItemId);
    oldItemContainer.splice(i, 1);

    switch (this.dropActionTodo.action) {
      case "before":
      case "after":
        const targetIndex = newContainer.findIndex(
          (c) => c.id === this.dropActionTodo.targetId
        );
        if (this.dropActionTodo.action == "before") {
          newContainer.splice(targetIndex, 0, draggedItem);
        } else {
          newContainer.splice(targetIndex + 1, 0, draggedItem);
        }
        break;

      case "inside":
        if (!this.nodeLookup[this.dropActionTodo?.targetId].children?.length) {
          this.nodeLookup[this.dropActionTodo.targetId].children.push(
            draggedItem
          );
        }
        this.nodeLookup[this.dropActionTodo.targetId].isExpanded = true;
        break;
    }

    this.clearDragInfo(true);
    this.formatSectionOrder();
  }
  getParentNodeId(id: string, nodesToSearch, parentId: string): string {
    for (let node of nodesToSearch) {
      if (node.id == id) return parentId;
      let ret = this.getParentNodeId(id, node.children, node.id);
      if (ret) return ret;
    }
    return null;
  }
  showDragInfo() {
    this.clearDragInfo();
    if (this.dropActionTodo) {
      this.document
        .getElementById("node-" + this.dropActionTodo.targetId)
        .classList.add("drop-" + this.dropActionTodo.action);
    }
  }
  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach((element) => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach((element) => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach((element) => element.classList.remove("drop-inside"));
  }

  prepareDragDrop(nodes) {
    nodes.forEach((node) => {
      this.dropTargetIds.push(node.id);
      this.nodeLookup[node.id] = node;
      this.prepareDragDrop(node?.children);
    });
  }

  public showSection(section) {}

  private formatSectionOrder() {
    this.allocatedSection.map((m, i) => {
      m.sectionOrderNumber = i + 1;
      if (m.children.length) {
        m.children.forEach((eachChildren, eachChildrenIndex) => {
          eachChildren.sectionOrderNumber = `${i + 1}.${eachChildrenIndex + 1}`;
        });
      }
    });
    this.addForm.controls["sectionIdArray"].patchValue(this.allocatedSection);
  }

  dropItem(event: CdkDragDrop<string[]>) {
    this.isAllocatedQuestionsOrderChanged = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  public isAllocatedSectionsStructureValid() {
    let isValid = true;
    for (let eachSection of this.allocatedSection) {
      if (eachSection?.children[0]?.children?.length) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }
}
