import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleRoutingModule } from './vehicle-routing.module';
import {DropdownModule} from 'primeng/dropdown';
import {VehicleDataModule} from './vehicle-data/vehicle-data.module';
import {CheckboxModule} from 'primeng/checkbox';
import {ToastModule} from 'primeng/toast';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TooltipModule} from 'primeng/tooltip';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TooltipModule,
    InputSwitchModule,
    VehicleRoutingModule,
    DropdownModule,
    VehicleDataModule,
    CheckboxModule,
    ToastModule
  ]
})
export class VehicleModule { }
