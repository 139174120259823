import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";

@Injectable()
export class FormQuestionsService {
  constructor(private http: HttpClient) {}
  
  // question apis
  fetchQuestions() {
    return this.http.get(environment.formBuilderApiURL + "questions/getAllQuestions").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  insertQuestion(body) {
    return this.http.post( environment.formBuilderApiURL + "questions/insertQuestions", body);
  }
  editQuestion(body, id) {
    return this.http.post(environment.formBuilderApiURL + "questions/editQuestions?id=" + id, body);
  }

  deleteQuestion(id) {
    return this.http
      .delete(environment.formBuilderApiURL + "questions/deleteQuestion?id=" + id)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getQuestionDetails(id) {
    return this.http
      .get(environment.formBuilderApiURL + "questions/viewQuestion?id=" + id)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
}
