import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StaffDataRoutingModule } from './staff-data-routing.module';
import { StaffDataComponent } from './staff-data.component';
import { SharedModule } from '../../../theme/shared/shared.module';
import { NgbCarouselModule, NgbProgressbarModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { AddEditStaffComponent } from '../add-edit-staff/add-edit-staff.component';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { ImportStaffComponent } from "../import-staff/import-staff.component";
import { ExportStaffComponent } from "../export-staff/export-staff.component";
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [StaffDataComponent, AddEditStaffComponent, ImportStaffComponent, ExportStaffComponent],
  imports: [
    CommonModule,
    FileUploadModule,
    StaffDataRoutingModule,
    SharedModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    NgbCarouselModule,
    DropdownModule,
    ToastModule,
    CheckboxModule,
    TooltipModule,
    ConfirmDialogModule,
    MessageModule,
    DynamicDialogModule,
    MessagesModule,
    TableModule,
    NgxSpinnerModule
  ],
  exports: [AddEditStaffComponent, ImportStaffComponent]
})
export class StaffDataModule { }
