import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DragulaService } from "ng2-dragula";
import { FormQuestionsService } from "../../demo/form-questions/form-questions.service";
import { JobService } from "../../demo/job/job-data.service";
import { ChatService } from "./chat.service";
import { v4 as uuidv4 } from "uuid";
import { LoginService } from "src/app/login/login.service";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
  questionsForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  isUpdate = false;
  slide = true;
  clickOpen = false;
  isEdit = false;
  errorMessage = false;
  isAcumen: any;
  companyID: any;
  companyList = [{ label: "Please Select", value: "" }];
  messageCollection: any;
  showLoader: any;
  chatText: any;
  questionTypeList = [
    { label: "Checkbox", value: "checkbox" },
    { label: "File", value: "image_file" },
    { label: "Image", value: "image" },
    { label: "Location", value: "location" },
    // { label: "Image/File", value: "image_file" },
    { label: "Radio", value: "radio" },
    { label: "Text", value: "text" },
    { label: "Text Area", value: "text_area" },
  ];

  data: any = [];
  @Input("data") viewData: any = {};
  @Input("isView") isView = false;
  @Input("isNew") isNew = false;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  @Output("reportDetails") reportDetails = new EventEmitter<any>();
  userDetails: any;
  noMessage: boolean;
  intervalData: any;
  operatorDetails: any;
  constructor(
    private messageService: MessageService,
    private service: FormQuestionsService,
    private cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _ds: DragulaService,
    private fb: FormBuilder,
    private jobService: JobService,
    private chatService: ChatService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userData"));
    this.showLoader = true;
    console.log("this.viewData-------------", this.viewData);
    this.fetchChats();
    this.intervalData = setInterval(() => {
      this.fetchChats(true);
      console.log("interval is working");
    }, 5000);
    this.getReportersDetails();
  }

  fetchChats(loader?: any) {
    if (!loader) {
      this.showLoader = true;
    }
    this.chatService
      .fetchChatsFromReportId(this.viewData._id
      )
      .subscribe(
        (response) => {
          console.log(response);
          // this.messageCollection = response.data;
          if (
            JSON.stringify(this.messageCollection) !==
            JSON.stringify(response.data)
          ) {
            this.messageCollection = response.data;
            if (Object.keys(response.data)?.length) {
              this.scrollToView();
            }
          }
          this.showLoader = false;
          if (Object.keys(this.messageCollection).length) {
            this.noMessage = false;
          } else {
            this.noMessage = true;
          }
        },
        (error) => {
          this.showLoader = false;
          console.log(error);
        }
      );
  }
  ngOnDestroy() {
    this._ds.destroy("question-items");
    clearInterval(this.intervalData);
  }

  closeEvent(event) {
    this.closeEvents.emit(true);
    clearInterval(this.intervalData);
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  showSendIcon() {
    const length = this.chatText?.replaceAll(" ", "").length;
    if (length) {
      return true;
    }
    return false;
  }

  sendChat() {
    let data: any;
    if (!Object.keys(this.messageCollection).length) {
      data = {
        _id: uuidv4(),
        jobOrReportName: this.viewData.report_number,
        jobOrReportId: this.viewData._id,
        messages: [
          {
            _id: uuidv4(),
            message: this.chatText,
            senderName:
              this.userDetails?.firstName + " " + this.userDetails?.lastName,
            senderId: this.userDetails?._id,
            date: new Date(),
            sender: "Admin",
            fcmToken: "",
          },
        ],
      };

      // const notificationData = {
      //   notification: {
      //     title: "Acumen Waste Services",
      //     body: `${this.userDetails?.firstName} ${this.userDetails?.lastName} has sent new message`
      //   },
      //   data: {
      //     reportNumber: this.viewData._id,
      //     page: this.getPageName(),
      //     date: new Date(),
      //     title: `${this.userDetails?.firstName} ${this.userDetails?.lastName} has sent new message`
      //   },
      //   registration_ids: [],
      // };

      const notificationData = {
        message: {
          token: "",
          notification: {
            title: "Acumen Waste Services",
            body: `${this.userDetails?.firstName} ${this.userDetails?.lastName} has sent new message`
          },
          data: {
            reportNumber: this.viewData._id,
            page: this.getPageName(),
            date: new Date(),
            title: `${this.userDetails?.firstName} ${this.userDetails?.lastName} has sent new message`
          }
        }
      }
      
      const payload  = {
        data: data,
        notificationData: notificationData,
        driverId: this.viewData.userId
      }

      this.chatService.insertChat(payload).subscribe(
        (response) => {
          this.chatText = "";
          // this.sendNotification(data.messages[0]);
          console.log(response);
          this.fetchChats(true);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      data = {
        message: {
          _id: uuidv4(),
          message: this.chatText,
          senderName:
            this.userDetails?.firstName + " " + this.userDetails?.lastName,
          senderId: this.userDetails?._id,
          date: new Date(),
          sender: "Admin",
          fcmToken: "",
        },
      };

      // const notificationData = {
      //   notification: {
      //     title: "Acumen Waste Services",
      //     body: `${this.userDetails?.firstName} ${this.userDetails?.lastName} has sent new message`,
      //   },
      //   data: {
      //     reportNumber: this.viewData._id,
      //     page: this.getPageName(),
      //     date: new Date(),
      //     title: `${this.userDetails?.firstName} ${this.userDetails?.lastName} has sent new message`
      //   },
      //   registration_ids: [],
      // };

      const notificationData = {
        message: {
          token: "",
          notification: {
            title: "Acumen Waste Services",
            body: `${this.userDetails?.firstName} ${this.userDetails?.lastName} has sent new message`
          },
          data: {
            reportNumber: this.viewData._id,
            page: this.getPageName(),
            date: new Date(),
            title: `${this.userDetails?.firstName} ${this.userDetails?.lastName} has sent new message`
          }
        }
      }

      const payload  = {
        data: data,
        notificationData: notificationData,
        driverId: this.viewData.userId
      }
      

      this.chatService.updateChat(this.messageCollection?._id, payload).subscribe(
        (response) => {
          console.log(response);
          this.chatText = "";
          // this.sendNotification(data.message);
          this.fetchChats(true);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  scrollToView() {
    console.log(
      "this.messageCollection.message[this.messageCollection.messages.length]._id",
      this.messageCollection?.messages[
        this.messageCollection?.messages?.length - 1
      ]?._id
    );
    setTimeout(() => {
      const element: any = document.getElementById(
        this.messageCollection?.messages[
          this.messageCollection?.messages?.length - 1
        ]?._id
      );
      console.log(element);
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 1000);
  }

  sendNotification(data) {
    const body = {
      notification: {
        title: "Acumen Waste Services",
        body: data.senderName + " has sent new message",
      },
      data: {
        reportNumber: this.viewData._id,
        page: this.getPageName(),
        date: new Date(),
        title: data.senderName + " has sent new message",
      },
      registration_ids: [this.operatorDetails.fcmToken],
    };
    this.chatService.sendNotification(body).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getReportersDetails() {
    this.loginService.getUserDetails(this.viewData.userId).subscribe(
      (result: any) => {
        console.log(result);
        this.operatorDetails = result.data[0];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getPageName() {
    if (this.viewData?.isFrom === "job") {
      switch (this.viewData?.jobType) {
        case "direct_tip":
          return "directTip";
        case "skipsandbins":
          return "skips";
        case "hazardous":
          return "hazardous";
      }
    }

    if (this.viewData?.isFrom === "reports") {
      return "reports";
    }
    if (this.viewData?.isFrom === "wasteReports") {
      return "waste";
    }
  }
}
