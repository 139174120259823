
    <div class="ui-g-3 box-container">
        <div class="box-header">
            <label class="bold">{{boxData.title}}</label>
            <hr />
        </div>
        <div class="toggle-view-mode">
            <button class="btn btn-primary toggle-view-mode-btn" (click)="toggleViewMode()">
                <ng-container *ngIf="viewMode=='selection'; else elseBlock">
                    {{ 'View All ' + boxData.title }}
                </ng-container>
                <ng-template #elseBlock>
                    {{ 'View Selected ' + boxData.title }}
                </ng-template>
            </button>
        </div>
        <div class="typeahead">
            <input #boxData.id class="typeahead-input" type="text" (input)="onInput($event)" [placeholder]="'Search ' + boxData.title +'...'">
            <ul class="matchList">
                <ng-container *ngIf="viewMode!='selection'; else elseBlock2">
                    <app-dropdown-list-item *ngFor="let result of mapToList(resultsMap)" [data]="result" [inSelection]="inSelection(result.id)" [viewMode]="viewMode" [disabled]="disabled"  (liClicked)="toggleListItem($event)"></app-dropdown-list-item>
                </ng-container>
                <ng-template #elseBlock2>
                    <app-dropdown-list-item *ngFor="let result of mapToList(selectionMap)" [data]="result" [inSelection]="inSelection(result.id)" [viewMode]="viewMode" [disabled]="disabled" (liClicked)="toggleListItem($event)"></app-dropdown-list-item>
                </ng-template>
            </ul>
        </div>
        <div class="bottom-row">
            <!-- <button class="btn btn-primary" (click)="clickShowAll()">Show All</button> -->
            <button class="btn btn-primary" (click)="clickSelectAll()" [disabled]="disabled || disableSelect()">Select All</button>
            <button class="btn btn-primary" (click)="clickClearSelection()" [disabled]="disabled || isSelectionEmpty()">Clear All</button>
        </div>
        <!-- <div class="mid-container">
            <div>
                <div>
                    <div class="ui-g-12 cursor-pointer">
                        <ng-container></ng-container>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="box-footer"></div>
    </div>

