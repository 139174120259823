import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { InspectionReportRoutingModule } from "./new-inspection-report.routing.module";
import { NewInspectionReportComponent } from "./new-inspection-report.component";
import { SharedModule } from "../../theme/shared/shared.module";
import {
  NgbCarouselModule,
  NgbProgressbarModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { ToastModule } from "primeng/toast";
import { DropdownModule } from "primeng/dropdown";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";
import { CalendarModule } from "primeng/calendar";
import { ViewNewInspectionReportComponent } from "./view-inspection-report/view-new-inspection-report.component";
import { SharedComponentsModule } from "src/app/shared-components/shared-components.module";
@NgModule({
  declarations: [
    NewInspectionReportComponent,
    ViewNewInspectionReportComponent,
  ],
  imports: [
    CommonModule,
    InspectionReportRoutingModule,
    SharedModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    NgbCarouselModule,
    DropdownModule,
    ToastModule,
    TooltipModule,
    ButtonModule,
    ConfirmDialogModule,
    MessageModule,
    DynamicDialogModule,
    MessagesModule,
    TableModule,
    CalendarModule,
    SharedComponentsModule,
  ],
  exports: [ViewNewInspectionReportComponent],
})
export class NewInspectionReportModule {}
