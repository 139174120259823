<div   class="" >
  
    <div id="styleSelector"  class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
    <div class="container h-100">
        <div class="row align-items-center">
          <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
              <div class='row add-edit-header'>  
                  <div class="ui-g-10">
                     
                      <h4>Edit Job Number</h4>
                  </div> 
                  <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Job Edit" tooltipPosition="top">
                      <i class="fa fa-window-close fa-2x "></i>
                   </div>    
             </div> 
                       <div class="">                    
            <form [formGroup]="jobFormNumber" >
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                        <label for="netweight">Job Number</label>
                  <input class='ui-g-12' id="netweight" type="text" class="form-control" size="30" formControlName='jobreference' pInputText>
                </div>
              </div>   
            
              <div class='row button-cls'>
                <div class='ui-g-12'>
                  <p-button label="Update" (onClick)="updateJob($event)"></p-button> 
                </div>
              </div>
            
            </form>
        
            </div>
          </div>
          </div>
        </div>
      </div>
      </div>