import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormBuildersService } from "./form-builders.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { AddEditViewFormBuildersComponent } from "./add-edit-view-form-builders/add-edit-view-form-builders.component";
import { FormSectionsService } from "../form-sections/form-sections.service";
import { FormQuestionsService } from "../form-questions/form-questions.service";
import * as moment from "moment";
import { JobService } from "../job/job-data.service";
import { Router } from "@angular/router";
import * as underscore from "underscore";

@Component({
  selector: "app-form-builders",
  templateUrl: "./form-builders.component.html",
  styleUrls: ["./form-builders.component.scss"],
})
export class FormBuildersComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isNew = false;
  isAcumen: any;
  companyID: any;
  searchArr = [];
  showAdd = false;
  showAddQues = false;
  showAddSection = false;
  cols = [
    {
      header: "Form Name",
      field: "formName",
    },
    {
      header: "Form Type",
      field: "formType",
      formType: "formType",
    },
    {
      header: "Date Created",
      field: "createdDate",
      type: "date",
    },
    {
      header: "Created By",
      field: "createdBy",
    },
    {
      header: "Date Updated",
      field: "updatedDate",
      type: "date",
    },
    {
      header: "Updated By",
      field: "updatedBy",
    },
    // {
    //   header: "Entries",
    //   field: "companyName",
    // },
    {
      header: "Active",
      field: "active",
    },
  ];
  formData: any;
  questionsList: any = [];
  formsList = [];
  showLoader = true;
  selectedAdmin: any;
  sectionList: Array<any> = [];
  isEdit: boolean;

  constructor(
    private service: FormBuildersService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private sectionService: FormSectionsService,
    private questionsService: FormQuestionsService,
    private jobService: JobService,
    private router: Router
  ) {}

  ngOnInit() {
    this.clearIndex();
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");

    this.fetchForms();
    this.fetchSectionList();
    this.fetchQuestions();
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
    console.log("searchArr", this.searchArr);
  }

  reload(event) {
    this.showLoader = true;
    this.fetchForms();
  }

  closeEvents(event) {
    this.showAdd = false;
    this.isView = false;
    this.isEdit = false;
    this.formData = {};
    this.fetchForms();
    this.fetchSectionList();
    this.cdr.detectChanges();
  }
  fetchSectionList() {
    this.sectionService.fetchSection().subscribe((response) => {
      try {
        this.showLoader = false;
        if (response && response.data && response.data.length) {
          this.sectionList = response.data.filter((item) => item && item.active == 'true');
          let arrayVal = [];

          this.sectionList.map((item) => {
            item.trimedTitle = item.sectionTitle.toString().replace(" ", "").replace("?", "").toLowerCase()
            // arrayVal.push(item.sectionTitle.toString().replaceAll(" ", "").toLowerCase());
          });
          let sortedData = underscore.sortBy(this.sectionList, 'trimedTitle')
          // console.log('trimmed title', this.sectionList);

          // let data  = this.sectionList.sort((a,b) => a.trimedTitle - b.trimedTitle);
          // console.log('sorted  data', data);
          console.log('  sortedData', sortedData);

          localStorage.setItem("sectionData", JSON.stringify(sortedData));
        }
      } catch (e) {
        this.showLoader = false;
        console.warn(e);
      }
    });
  }
  clearIndex() {
    localStorage.removeItem("jobID");
    localStorage.removeItem("pageJobIndex");
    localStorage.removeItem("pageDisposalIndex");
    localStorage.removeItem("pageWasteIndex");
    localStorage.removeItem("pageReportIndex");
    localStorage.removeItem("pageInspectionReportIndex");
  }

  change(event, value) {
    let obj;
    obj = {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      company: value.company.companyId,
      //  password: value.password,
      active: value.active,
      _id: value._id,
    };
    console.log("event", obj);
    this.service.updateAdmin(obj).subscribe((data) => {});
  }

  showFormsPopup(event) {
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.isNew = false;
      this.formData = {};
      setTimeout((e) => {
        this.showAdd = true;
        this.isNew = true;
      }, 1000);
    } else {
      this.showAdd = true;
      this.isNew = true;
    }
    this.cdr.detectChanges();
  }

  duplicateForm(event, data) {
    let payLoad = { ...data };
    delete payLoad._id;
    payLoad.formName = payLoad.formName + " copy";
    payLoad.createdBy =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
    payLoad.createdDate = new Date();

    this.service.insertForm(payLoad).subscribe(
      (resp: any) => {
        try {
          if (resp && resp.data) {
            this.messageService.add({
              severity: "success",
              summary: "Create Question",
              detail: "New Question created successfully",
            });
            this.fetchForms();
          }
        } catch (e) {
          console.warn(e);
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: error.error.message,
          // detail: "Unable to add Question.Please try again.",
        });
      }
    );
  }

  editForm(event, data) {
    this.formData = data;
    this.showAdd = true;
    this.isView = false;
    this.isEdit = true;
    this.cdr.detectChanges();
  }

  viewForm(event, data) {
    // this.service.getDetails(data._id).subscribe((response) => {
    this.formData = data;
    this.isView = true;
    this.showAdd = true;
    this.isNew = false;
    this.cdr.detectChanges();
    // });

    // const ref = this.dialogService.open(AddEditViewFormBuildersComponent, {
    //       data: {
    //           viewData: this.formData,
    //           isUpdate: true
    //       },
    //       // header: 'Edit Staff Details',
    //       width: '600px',
    //   });

    // ref.onClose.subscribe((car) => {
    //     if (car) {
    //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
    //      this.fetchForms();
    //     }
    // });
  }

  deleteForm(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Form?",
      accept: () => {
        this.service.deleteForm(id).subscribe(
          (data: any) => {
            if (data.message === "Success") {
              this.messageService.add({
                severity: "success",
                summary: "Delete Form Questions",
                detail: "Form details deleted successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Delete Forms",
                detail: data.error.message,
              });
            }
            this.fetchForms();
          },
          (err) => {
            console.log("error", err);
            this.messageService.add({
              severity: "error",
              summary: "Delete Forms",
              detail: "Failed to delete the Form",
            });
            this.fetchForms();
          }
        );
        //  });
      },
    });
  }

  fetchForms() {
    this.service.fetchForms().subscribe((response) => {
      if (!response?.data?.length) {
        this.formsList = [];
      }
      try {
        this.showLoader = false;
        if (response && response.data && response.data.length) {
          this.formsList = response.data;
        }

        this.formsList = this.formsList.map((val) => {
          // val.createdDate = moment(val.createdDate).format('DD/MM/YYYY')
          // val.updatedDate =
          //   val && val.updatedDate
          //     ? moment(val.updatedDate).format("DD/MM/YYYY")
          //     : "";
          if (val.itemData && val.itemData.length) {
            val.itemInputType = val.itemData[0].itemInputType;
          }
          return val;
        });
      } catch (e) {
        this.showLoader = false;
        console.warn(e);
      }
    });
  }
  fetchQuestions() {
    this.questionsService.fetchQuestions().subscribe((response) => {
      try {
        this.showLoader = false;
        if (response && response.data && response.data.length) {
          this.questionsList = response.data;
          localStorage.setItem(
            "questionsList",
            JSON.stringify(this.questionsList)
          );
        }
      } catch (e) {
        this.showLoader = false;
        console.warn(e);
      }
    });
  }
  openAddQues(event) {
    console.log(event);
    this.showAddQues = true;
  }

  openAddSection(event) {
    console.log(event);
    this.showAddSection = true;
  }

  closeAddQues(event) {
    this.showAddQues = false;
    this.fetchQuestions();
  }

  closeAddSection(event) {
    this.showAddSection = false;
    this.fetchSectionList();
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  navigateToAdminAccess(data) {
    this.router.navigate(["form-buildfer-access"], {
      queryParams: {
        type: "Administrator",
        formName: data.formName,
        _id: data._id,
      },
    });
  }
  navigateToUsersAccess(data) {
    this.router.navigate(["form-buildfer-access"], {
      queryParams: { type: "Users", formName: data.formName, _id: data._id },
    });
  }
}
