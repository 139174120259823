import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { DisposalSitesService } from "../demo/disposal-sites/disposal-sites.service";
// import { logEvent, getAnalytics } from 'firebase/analytics';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public isPasswordVisible = false;
  loginForm: FormGroup;
  errorMessage = "";
  moduleName = "Login";
  loggedIn = false;
  userData: any;
  userAccessModules: any;
  moduleList: any;
  analytics:any;
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private loginService: LoginService,
    private cdr: ChangeDetectorRef,
    private service: DisposalSitesService,
  ) {}
  ngAfterViewInit() {
    this.loggedIn = localStorage.getItem("token") ? false : true;
    this.cdr.detectChanges();
  }
  navigateSignUp() {
    this.router.navigate(["register"]);
  }
  login() {
    this.loginService.login(this.loginForm.value).subscribe((response: any) => {
      if (response.error) {
        console.log(response.error);
        this.errorMessage = response.error.message.error;
        // logEvent(this.analytics, 'login-failed', { email: this.loginForm.value.email, message: this.errorMessage });
      } else {

    // logEvent(this.analytics, 'login', { email: this.loginForm.value.email, message: 'Success' });
        this.errorMessage = "";
        this.userData = response.data;
        // logEvent(this.analytics, 'login', {
        //   method: 'email',
        //   userId: this.userData._id, // Replace with the user ID of the logged-in user
        //   userName: this.userData.firstName +' '+ this.userData.lastName // Replace with the name of the logged-in user
        // });
        localStorage.setItem("token", this.userData.token);
        localStorage.setItem("companyID", this.userData.company);
        localStorage.setItem("isAcumen", this.userData.isAcumen);
        localStorage.setItem("userData", JSON.stringify(this.userData));
        this.fetchUserDetails();
      }
    });
  }

  fetchUserDetails() {
    this.loginService.getDetails(this.userData._id).subscribe(
      (result: any) => {
        this.userAccessModules = result.data[0].accessModules;
        console.log("USER ACCESS MODULES", this.userAccessModules)
        localStorage.setItem(
          "accessModule",
          JSON.stringify(this.userAccessModules)
        );
        this.loginService.updateUser(this.userData);
        localStorage.setItem("firstName", this.userData.firstName);
        localStorage.setItem("lastName", this.userData.lastName);

        this.loggedIn = false;
        this.fetchModules();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ngOnInit() {
    // this.analytics = getAnalytics();
    console.log('analytics ----->', this.analytics)
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", Validators.required),
    });
    this.loginService.user.subscribe((data) => {
      if (data !== -1) {
        this.router.navigate(["home"]);
      }
    });
  }
  forgotPassword(event) {
    this.router.navigate(["auth/reset-password-v2"]);
  }

  fetchModules() {
    this.service.getModules().subscribe(
      (data: any) => {
        console.log("parent data", data);
        this.moduleList = data.data;
        this.moduleList.push({
          "moduleName": "Messaging",
          "objectArray": {
            "create": true,
            "edit": true,
            "delete": true,
            "read": true,
            "superAdmin": false
          },
          "order": 21,
          "title": "Messaging",
          "type": "item",
          "url": "/messages/data",
          "_id": "xxxxxxxxxxxxx42332"
        });
        console.log("MODULE LIST", this.moduleList);
        localStorage.setItem(
          "available_modules",
          JSON.stringify(this.moduleList)
        );
        this.router.navigate(["dashboard/analytics"]);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
