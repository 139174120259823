<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card cardClass="table-card waste-description" blockClass="p-0">
      <div class="container h-100">
        <div class="row align-items-center h-100">
          <div class="col-12 mx-auto">
            <form [formGroup]="wasteDescriptionForm">
              <div class="row">
                <div class="ui-g-12 mt-3">
                  <label>Skips and Bins</label>
                  <p-editor
                    formControlName="description"
                    [style]="{ height: '400px' }"
                  >
                  </p-editor>
                </div>
              </div>

              <div
                class="row"
                *ngIf="!isUpdate && checkPermissions('edit', 'Skips and Bins')"
              >
                <div class="ui-g-12">
                  <p-button
                    label="Save"
                    [disabled]="!wasteDescriptionForm.valid"
                  ></p-button>
                </div>
              </div>
              <div
                class="row"
                *ngIf="isUpdate && checkPermissions('edit', 'Skips and Bins')"
              >
                <div class="ui-g-12">
                  <p-button
                    label="Update"
                    [disabled]="!wasteDescriptionForm.valid"
                    (onClick)="update($event)"
                  >
                  </p-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
