import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  ElementRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormSectionsService } from "../form-sections.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { DragulaService } from "ng2-dragula";
import { FormQuestionsService } from "../../form-questions/form-questions.service";
import { ConfirmDialogModule } from "primeng";
import { JobService } from "../../job/job-data.service";
// import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-edit-view-form-sections",
  templateUrl: "./add-edit-view-form-sections.component.html",
  styleUrls: ["./add-edit-view-form-sections.component.scss"],
})
export class AddEditViewFormSectionsComponent implements OnInit {
  sectionsForm: FormGroup;
  isUpdate = false;
  slide = true;
  clickOpen = false;
  isEdit = false;
  errorMessage = false;
  companyList = [{ label: "Please Select", value: "" }];
  dropdownValue;
  questionTypeList = [
    { label: "Checkbox", value: "checkbox" },
    { label: "Image/File", value: "image_file" },
    { label: "Radio", value: "radio" },
    { label: "Text", value: "text" },
    { label: "Text Area", value: "text_area" },
  ];
  selectedQuestions: Array<any> = [];

  data: any = [];
  @Input("data") viewData: any = {};
  @Input("isView") isView = false;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  @Output("openAddQues") openAddQues = new EventEmitter<any>();
  public toBeDeleted = [];
  visibility = true;
  constructor(
    private messageService: MessageService,
    private service: FormSectionsService,
    private questionService: FormQuestionsService,
    private cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _ds: DragulaService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService // private toastMessage: ToastrService
  ) {
    this.sectionsForm = new FormGroup({
      sectionName: new FormControl("", [Validators.required]),
      selectedQuestions: new FormControl([], [Validators.required]),
      questionType: new FormControl(false, [Validators.required]),
      adminOnly: new FormControl(false, [Validators.required]),
      visibleMobile: new FormControl(false, [Validators.required]),
      active: new FormControl(false, [Validators.required]),
    });
    this._ds.createGroup("section-items", {});
  }

  checkBoxchange(event, i) {
    if (event.checked === true) {
      this.toBeDeleted.push(i);
    } else {
      this.toBeDeleted = this.toBeDeleted.filter((item) => item !== i);
    }
  }

  allocate(event, ques) {
    let temp = this.sectionsForm.controls["selectedQuestions"].value;
    if (ques) {
      temp.push(ques);
    }
    this.sectionsForm.controls["selectedQuestions"].setValue(temp);
    this.sectionsForm.controls["questionSelection"].reset();
  }

  addNewQues(event) {
    console.log("add ques button pressed");
    this.openAddQues.emit();
  }

  deleteQues() {
    let temp = this.sectionsForm.controls["selectedQuestions"].value;
    this.toBeDeleted.sort((a, b) => b - a);
    this.toBeDeleted.forEach((i) => {
      const ii = temp[i];
      temp = temp.filter((item) => item !== ii);
    });
    this.toBeDeleted = [];
    this.sectionsForm.controls["selectedQuestions"].setValue(temp);
    console.log(this.sectionsForm);
    console.log(this.viewData);
    const body = {
      active: this.sectionsForm.value.active,
      adminOnly: this.sectionsForm.value.adminOnly,
      createdBy: this.viewData.createdBy,
      createdDate: this.viewData.createdDate,
      itemIdArray: this.sectionsForm.value.selectedQuestions,
      mobileDisplay: this.sectionsForm.value.visibleMobile,
      reviewedBy: this.viewData.reviewedBy,
      reviewedDate: this.viewData.reviewedDate,
      sectionNumber: this.viewData.sectionNumber,
      sectionTitle: this.viewData.sectionTitle,
    };
    this.service.editSection(body, this.viewData._id).subscribe(
      (result) => {
        this.selectedQuestions = this.sectionsForm.value.selectedQuestions;
        this.closeEvent(true);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  confirmDeleteQuestion() {
    this.closeEvent(true);
    this.confirmDialogService.confirm({
      message:
        "Are you sure that you want to delete question from  Form Section?",
      accept: () => {
        this.deleteQues();
      },
    });
  }

  addAnswer() {
    return new FormGroup({
      answer: new FormControl(""),
      selected: new FormControl(true),
    });
  }

  questionListUpdate() {
    console.log(this.dropdownValue);
  }

  ngAfterViewInit() {
    console.log("before ngafterviewinit", this.sectionsForm);
    if (!this.isUpdate) {
      this.sectionsForm.patchValue({
        email: "",
        password: "",
      });
    }
    this.clickOpen = true;

    console.log("ngafterviewinit", this.sectionsForm);
  }
  onChange(event) {
    console.log("event", event);
    this.errorMessage = false;
    this.cdr.detectChanges();
  }
  editEvent(event) {
    this.isEdit = true;
    this.isUpdate = true;
    this.isView = false;
    this.selectedQuestions.length = 0;
    this.loadData();
  }
  createQuestion(e) {
    let value: any = "";
    value = this.sectionsForm.value;
    // return console.log('this.sectionsForm', this.sectionsForm)
    let payLoad = {
      sectionNumber: "1",
      sectionTitle: value.sectionName,
      itemIdArray: this.selectedQuestions,
      mobileDisplay: value.visibleMobile,
      adminOnly: value.adminOnly,
      createdBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"),
      createdDate: new Date(),
      reviewedBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"),
      reviewedDate: new Date(),
      active: JSON.stringify(value.active),
    };
    this.errorMessage = false;
    this.service.insertSection(payLoad).subscribe(
      (data: any) => {
        if (data.data) {
          this.messageService.add({
            severity: "success",
            summary: "Create Section",
            detail: "New Section created successfully",
          });
          this.ref.close(this);
          this.closeEvents.emit(true);
        } else if (data.status === 500) {
          this.messageService.add({
            severity: "error",
            summary: "Create Section",
            detail: data.error.message.errmsg,
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Create Section",
            detail: "Unable to add Section.Please try again.",
            //  detail: 'Failed to create the User'
          });
        }
        this.closeEvent(true);
      },
      //  });
      (err) => {
        console.log("Error", err);
        this.closeEvent(true);

        this.messageService.add({
          severity: "error",
          summary: "Create Section",
          // detail: 'Failed to create the Waste types'
          detail: err.error.message,
        });

        // this.toastMessage.error(err.error.message);
      }
    );
  }

  updateQuestion(event: any) {
    console.log;
    let value: any = "";
    value = this.sectionsForm.value;
    let id = this.viewData && this.viewData._id;
    let payLoad = {
      sectionNumber: value.sectionNumber,
      sectionTitle: value.sectionName,
      itemIdArray: this.selectedQuestions || [],
      mobileDisplay: value.visibleMobile,
      adminOnly: value.adminOnly,
      createdBy: this.viewData.createdBy,
      createdDate: new Date(),
      reviewedBy: this.viewData.reviewedBy,
      reviewedDate: this.viewData.reviewedDate,
      active: value.active.toString(),
    };
    this.errorMessage = false;
    //  delete value.cpassword;
    this.service.editSection(payLoad, id).subscribe((data: any) => {
      if (data.status === 200) {
        this.messageService.add({
          severity: "success",
          summary: "Update Section",
          detail: "Section details updated successfully",
        });
        if (this.isEdit == true) {
          console.log("data", this.data);
          // this.service.getDetails(this.data._id).subscribe((response) => {
          // this.viewData = payLoad;
          // this.selectedQuestions = this.viewData.itemIdArray
          this.closeEvent(true);
          // });
          // this.ref.close(this);
        } else {
          // this.closeEvents.emit(true);
          this.closeEvent(true);
        }
      } else if (data.status === 500) {
        this.messageService.add({
          severity: "error",
          summary: "Update Section",
          detail: data.error.message.errmsg,
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Update Section",
          detail: "Unable to update Section.Please try again.",
          //  detail: 'Failed to update the user details'
        });
      }
    });
  }

  ngOnInit() {
    if(this.isEdit) {
      localStorage.setItem('originalSectionData', JSON.stringify(this.viewData));
    }
    console.log('viewData for section',this.viewData);
    this.loadData();
  }

  ngOnDestroy() {
    this._ds.destroy("section-items");
  }

  loadData() {
    this.fetchQuestions();
    if (this.viewData && Object.keys(this.viewData).length !== 0) {
      this.isUpdate = true;
      // if (this.isView) {
      //   this.viewData.active = this.viewData.active == 'true' ? "Yes" : "No";
      // }
      this.selectedQuestions = this.viewData.itemIdArray
        ? this.viewData.itemIdArray.filter((val) => val)
        : [];
      this.sectionsForm.patchValue({
        sectionName: this.viewData.sectionTitle,
        selectedQuestions: this.selectedQuestions,
        active: JSON.parse(this.viewData.active),
        adminOnly: this.viewData.adminOnly,
        visibleMobile: this.viewData.mobileDisplay,
      });
    }
  }
  fetchQuestions() {
    this.questionService.fetchQuestions().subscribe((response) => {
      try {
        if (response && response.data && response.data.length) {
          this.questionTypeList = response.data;
        } else {
          this.closeEvent(true);
          return this.messageService.add({
            severity: "error",
            summary: "Please create questions first",
            detail: "",
            //  detail: 'Failed to create the User'
          });
        }
      } catch (e) {
        console.warn(e);
      }
    });
  }
  onQnsSelect(event) {
    let data: any = event.value;
    let index = this.selectedQuestions.findIndex(
      (item) => item._id === data._id
    );
    if (index != -1) return;
    this.selectedQuestions.push(data);
    this.sectionsForm.patchValue({
      selectedQuestions: this.selectedQuestions,
    });
  }

  closeEvent(event?: any) {
    if(!event && this.isEdit) {
      const originalData = JSON.parse(localStorage.getItem('originalSectionData'))
          let value = this.sectionsForm.value;
          console.log('original data',JSON.parse(localStorage.getItem('originalSectionData')))
          console.log('section data ',this.viewData);

          if(originalData.sectionTitle !== value?.sectionName || JSON.stringify(originalData.itemIdArray) !== JSON.stringify(this.selectedQuestions)) {
            this.visibility = false;
            return this.confirmDialogService.confirm({
              message: "You have unsaved changes. Are you sure that you want to close?",
              accept: () => {
                if (this.isEdit == false) {
                  this.slide = false;
                  this.clickOpen = false;
                  this.cdr.detectChanges();
                  setTimeout((e) => {
                    this.closeEvents.emit(true);
                  }, 1000);
                } else {
                  this.isEdit = false;
                  this.isView = true;
                  this.isUpdate = false;
                  // this.fetchQuestions();
                }
              },
              reject: () => {
                this.visibility = true;
              }
            });
          }
    }

    if (this.isEdit == false) {
      this.slide = false;
      this.clickOpen = false;
      this.cdr.detectChanges();
      setTimeout((e) => {
        this.closeEvents.emit(true);
      }, 1000);
    } else {
      this.isEdit = false;
      this.isView = true;
      this.isUpdate = false;
      // this.fetchQuestions();
    }

  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  public onDrop(event) {
    console.log("event in parent: ", event);
    this.selectedQuestions = event;
  }
}
