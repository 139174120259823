<div
  id="styleSelector"
  class="style-block job-container  open {{
    slide ? 'animate-block' : 'slide-out'
  }}"
  *ngIf="itemList.length > 0"
>
  <div class="container h-100">
    <ng-container>
      <div class="row align-items-center">
        <div class="col-10 mx-auto">
          <form>
            <div class="row add-edit-header">
              <div class="ui-g-10">
                <h4>{{ title }}</h4>
              </div>
              <div
                class="ui-g-2"
                (click)="closeEvent($event)"
                *ngIf="isDisposal"
                pTooltip="Click to close a Disposal site"
                tooltipPosition="top"
              >
                <i class="fa fa-window-close fa-2x"></i>
              </div>
              <div
                class="ui-g-2"
                (click)="closeEvent($event)"
                *ngIf="isCategory"
                pTooltip="Click to close a Waste category"
                tooltipPosition="top"
              >
                <i class="fa fa-window-close fa-2x"></i>
              </div>
            </div>
            <div
              class="checkbox-container"
              *ngIf="checkPermissions('edit', 'Customers')"
            >
              <input
                id="selectall"
                type="checkbox"
                class="checkbox"
                value="selectall"
                [(ngModel)]="selected"
                (change)="onChange($event)"
                [ngModelOptions]="{ standalone: true }"
              />
              <label for="selectall" class="checkboxLabel">Select all</label>
            </div>
            <div
              class="checkbox-container"
              *ngFor="let item of itemList; let i = index"
            >
              <input
                id="{{ item.value }}"
                type="checkbox"
                class="checkbox"
                value="{{ item.value }}"
                [(ngModel)]="item.checked"
                (change)="changeEvent($event)"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="
                  checkPermissionsToDisableCheckbox('edit', 'Customers')
                "
              />
              <label for="{{ item.value }}" class="checkboxLabel">{{
                item.label
              }}</label>
            </div>

            <div
              class="row button-cls margin-bottom"
              *ngIf="checkPermissions('edit', 'Customers')"
            >
              <div class="ui-g-12">
                <p-button label="Save" (onClick)="linkItems($event)"></p-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
  </div>
</div>
