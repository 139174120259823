<div class="">
    <div>
        <div class="ui-g-12">
            <div class="ui-g-6" style="text-align: left">
                <button type="button" class="btn btn-warning" (click)="clickClose()">
                    <i class="fa fa-edit fa-2x"></i>CLOSE
                  </button>
            </div>
        </div>
        <div *ngIf="dataLoaded()" class="ui-g-12 box-wrap">
            <app-search-filter-add *ngFor="let boxObject of boxObjects" [boxData]="boxObject" [disabled]="boxObject['states']['disabled']"
            [dataMap]="dataMap" 
            (fetchDependentLists)="fetchDependencies($event)" (clearDependentLists)="clearDependencies($event)" [(toClear)]="boxesToClear" #boxes></app-search-filter-add>
        </div>
        <div class="container h-100">
            <div class="row align-items-center h-100 justify-content-center">
                <div class="col-10 mx-auto">
                    <form [formGroup]="messageForm">
                        <div class="row">
                            <div class="ui-g-12 mt-3">
                                <label><h5>Compose a message</h5></label>
                                <h5 *ngIf="scheduleMode">Scheduled for {{ formatDate(scheduleDate) }}</h5>
                                <p-editor
                                    formControlName="message"
                                    [style]="{ height: '260px' }"
                                >
                                </p-editor>
                            </div>
                        </div>

                        
                        <div class="d-flex flex-row mx-auto">
                            <div class="ui-g-12 d-flex justify-content-center">
                                <p-button
                                label="{{scheduleMode ? 'Schedule Message': 'Send Message'}}"
                                [disabled]="!messageForm.valid"
                                (onClick)="isUpdate() ? updateMessage($event) : createNewMessage($event)"
                                >
                                </p-button>
                            </div>
                            <div *ngIf="(isNew() || isDraft()) && !scheduleMode" class="ui-g-12 d-flex justify-content-center">
                                <p-button
                                label="Save Draft"
                                [disabled]="!messageForm.valid"
                                (onClick)="isUpdate() ? updateToDraft($event) : saveDraft($event)"
                                >
                                </p-button>
                            </div>
                            <div class="ui-g-12 d-flex justify-content-center">
                                <p-button
                                label="{{scheduleMode? 'Change Schedule': 'Set Schedule'}}"
                                [disabled]="!messageForm.valid"
                                (onClick)="schedule($event)"
                                >
                                </p-button>
                            </div>
                            <div *ngIf="isScheduled()" class="ui-g-12 d-flex justify-content-center">
                                <button
                                class="delete-btn"
                                (click)="deleteMessage($event)"
                                >
                                Delete Message
                                </button>
                            </div>
                            <div *ngIf="isDraft()" class="ui-g-12 d-flex justify-content-center">
                                <button
                                class="delete-btn"
                                (click)="deleteMessage($event)"
                                >
                                Delete Draft
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showSchedule">
    <app-schedule-message (closeEvents)="closeSchedule($event)" [date]="scheduleDate" [dateSet]="scheduleSet" (checkBoxDependenciesEmpty)="checkIfSelectionIsEmpty($event)"
    (scheduleDate)="setScheduleDate($event)" (clearScheduleEvent)="unSetScheduleDate()" (toggleScheduleMode)="toggleScheduleMode()"></app-schedule-message>

</div>