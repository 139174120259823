import { Component, OnInit } from "@angular/core";
import { InspectionReportService } from "../inspection-report.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { ImageViewComponent } from "../../../theme/shared/components/image-view/image-view.component";
import { JobService } from "../../job/job-data.service";
@Component({
  selector: "app-view-reports",
  templateUrl: "./view-inspection-report.component.html",
  styleUrls: ["./view-inspection-report.component.scss"],
})
export class ViewInspectionReportComponent implements OnInit {
  reportDetails: any;
  isEditView = false;
  showLoader = true;
  data_list: any;
  improvement: any;
  username: any;
  constructor(
    private service: InspectionReportService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobService
  ) {}
  ngOnInit() {
    this.fetchRecords();
    // this.getJSONData();
  }
  closeEvent(event) {
    this.router.navigate(["inspectionReport"]);
  }
  fetchRecords() {
    this.service
      .fetchReportsById(this.route.snapshot.params.id)
      .subscribe((data) => {
        console.log(data);
        this.reportDetails = data;
        this.showLoader = false;
        let data_keys = [];
        let data_list = new Array();
        let submitted_form_json: any;
        submitted_form_json = JSON.parse(
          this.reportDetails.submitted_form_json
        );
        data_keys = Object.keys(submitted_form_json);
        for (const iterator in data_keys) {
          let key = data_keys[iterator];
          let data_val = [key, submitted_form_json[key]];
          if (key != "Check on Actions for Improvement:") {
            data_list.push(data_val);
          } else {
            this.improvement = submitted_form_json[key];
          }
        }
        console.log(data_list);
        this.data_list = data_list;
        this.username =
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("lastName");
      });
  }
  backToReport(e) {
    this.router.navigate(["inspectionReport"]);
  }
  viewGoogleMap(location) {
    if (location) {
      window.open(
        "https://maps.google.com/?q=" + location.lat + "," + location.long,
        "_blank"
      );
    }
  }

  editReport(event) {
    this.isEditView = true;
  }
  openPopUp(photo) {
    console.log(photo);
    //this.ds.createModal(PhotoViewerComponent, { header: photo.title, modal: true }, { fileURL: photo.fileURL });
  }
  viewImage(data) {
    let img;
    img = { url: data.fileURL };
    const ref = this.dialogService.open(ImageViewComponent, {
      data: {
        viewData: img,
      },
      width: "600px",
    });

    ref.onClose.subscribe((i) => {});
  }
  saveReport(event) {
    if (this.improvement) {
      let submitted_form_json: any;
      let improvement_key = "Check on Actions for Improvement:";
      submitted_form_json = JSON.parse(this.reportDetails.submitted_form_json);
      submitted_form_json[improvement_key] = this.improvement;
      this.reportDetails.submitted_form_json =
        JSON.stringify(submitted_form_json);
    }
    console.log(this.reportDetails.notes);
    this.service.saveReports(this.reportDetails).subscribe((data) => {
      this.messageService.add({
        severity: "success",
        summary: "Update Reports",
        detail: "Reports details updated successfully",
      });
      this.isEditView = false;
      this.fetchRecords();
    });
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
