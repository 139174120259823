import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormSectionsService } from "./form-sections.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { FormQuestionsService } from "../form-questions/form-questions.service";
import * as moment from "moment";
import { JobService } from "../job/job-data.service";

@Component({
  selector: "app-form-sections",
  templateUrl: "./form-sections.component.html",
  styleUrls: ["./form-sections.component.scss"],
})
export class FormSectionsComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen: any;
  companyID: any;
  searchArr = [];
  questionsList = [];
  showAdd = false;
  showAddQues = false;
  cols = [
    {
      header: "Section Name",
      field: "sectionTitle",
    },
    {
      header: "Created date",
      field: "createdDate",
    },
    {
      header: "Allocated questions",
      field: "questionsStr",
    },
  ];
  sectionData: any;
  sectionList = [];
  showLoader = true;
  selectedAdmin: any;

  constructor(
    private service: FormSectionsService,
    private formQuestionsService: FormQuestionsService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService
  ) {}

  ngOnInit() {
    this.clearIndex();
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");
    this.fetchSectionList();
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
  }

  reload(event) {
    this.showLoader = true;
    this.fetchSectionList();
  }

  callList(event) {
    console.log("call list");
    this.showAdd = false;
    this.isView = false;
    this.sectionData = {};
    this.cdr.detectChanges();
    this.fetchSectionList();
  }

  closeAddQues(event) {
    this.showAddQues = false;
  }

  openAddQues(event) {
    console.log("add ques value changed");
    this.showAddQues = true;
  }

  clearIndex() {
    localStorage.removeItem("jobID");
    localStorage.removeItem("pageJobIndex");
    localStorage.removeItem("pageDisposalIndex");
    localStorage.removeItem("pageWasteIndex");
    localStorage.removeItem("pageReportIndex");
    localStorage.removeItem("pageInspectionReportIndex");
  }

  change(event, value) {
    let obj;
    obj = {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      company: value.company.companyId,
      //  password: value.password,
      active: value.active,
      _id: value._id,
    };
    console.log("event", obj);
    this.service.updateAdmin(obj).subscribe((data) => {});
  }

  createSection(event) {
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.sectionData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
    }
    this.cdr.detectChanges();
  }

  duplicateSection(event, data) {
    let value: any = "";
    value = { ...data };
    // let payLoad = {
    //   sectionNumber: this.sectionList.length+1,
    //   sectionTitle: value.sectionTitle,
    //   itemIdArray: value.itemIdArray,
    //   mobileDisplay: value.visibleMobile == "true",
    //   adminOnly: value.adminOnly == "true",
    //   createdBy: "5b5f1776d231d43b37bccbb6",
    //   createdDate: new Date(),
    //   reviewedBy: "5b5f1776d231d43b37bccbb6",
    //   reviewedDate: new Date(),
    //   active: value.active == "true",
    // };
    value.sectionTitle = value.sectionTitle + "  copy";
    value.createdBy =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
    value.createdDate = new Date();
    delete value.reviewedBy;
    delete value.reviewedDate;

    this.service.insertSection(value).subscribe(
      (res: any) => {
        this.fetchSectionList();
        if (res.data) {
          this.messageService.add({
            severity: "success",
            summary: "Create Admin",
            detail: "New Admin created successfully",
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Create Admin",
            detail: "Unable to add Admin.Please try again.",
          });
        }
      },
      (err) => {
        console.log("Error", err);
        this.messageService.add({
          severity: "error",
          summary: err.error.message,
          // detail: "Unable to add Question.Please try again.",
        });
      }
    );
  }

  editSection(event, data) {
    this.sectionData = data;
    this.showAdd = true;
    this.cdr.detectChanges();
  }

  viewSection(event, data) {
    this.sectionData = data;
    this.isView = true;
    this.showAdd = true;
    this.cdr.detectChanges();
  }

  deleteSection(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Form Section?",
      accept: () => {
        try {
          this.service.deleteSection(id).subscribe(
            (resp: any) => {
              if (resp && resp.data) {
                this.messageService.add({
                  severity: "success",
                  summary: "Delete Form Section",
                  detail: "Form Section details deleted successfully",
                });
                this.fetchSectionList();
              }
            },
            (err) => {
              console.log("error", err);
              this.messageService.add({
                severity: "error",
                summary: "Delete Form Section",
                detail: "Failed to delete the Form Section",
              });
              this.fetchSectionList();
            }
          );
        } catch (e) {
          console.warn(e);
        }
      },
    });
  }

  fetchSectionList() {
    this.service.fetchSection().subscribe((response) => {
      try {
        this.showLoader = false;
        console.log(this.sectionList);
        if (!response.data.length) {
          return (this.sectionList = []);
        }
        if (response && response.data && response.data.length) {
          this.sectionList = response.data;
          this.sectionList = this.sectionList.map((val) => {
            val.createdDate = moment(val.createdDate).format("DD/MM/YYYY");
            val.questionsStr = "";
            if (val.itemIdArray && val.itemIdArray.length) {
              val.questionsStr = val.itemIdArray
                .filter((val) => val.itemName)
                .map((val) => val.itemName)
                .join(", ");
            }
            return val;
          });
        }
      } catch (e) {
        this.showLoader = false;
        console.warn(e);
      }
    });
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
