<perfect-scrollbar
  *ngIf="this.gradientConfig['layout'] === 'vertical'"
  (clickOutside)="navMob()"
  [excludeBeforeClick]="true"
  [exclude]="'#mobile-collapse'"
  id="nav-ps-gradient-able"
  ngClass="gradientscroll"
  [style.max-width]="'300px'"
  [style.max-height]="'calc(100vh - 56px)'"
  [usePSClass]="'gradient-able'"
  [disabled]="null"
>
  <div class="navbar-content">
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></app-nav-group>
        <app-nav-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
        ></app-nav-collapse>
        <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
    <!-- <app-card [hidHeader]="true" cardClass="text-center">
      <i class="feather icon-sunset f-40"></i>
      <h6 class="mt-3">Help?</h6>
      <p>Please contact us on our email for need any support</p>
      <a href="javascript:" target="_blank" class="btn btn-primary btn-sm text-white m-0">Support</a>
    </app-card> -->
  </div>
</perfect-scrollbar>

<div
  *ngIf="this.gradientConfig['layout'] === 'horizontal'"
  #navbarWrapper
  class="navbar-content sidenav-horizontal"
  id="layout-sidenav"
>
  <a
    href="javascript:"
    class="sidenav-horizontal-prev"
    [ngClass]="prevDisabled"
    (click)="scrollMinus()"
  ></a>
  <div class="sidenav-horizontal-wrapper">
    <!-- add 14-4 viral -->
    <ul
      #navbarContent
      id="side-nav-horizontal"
      class="nav pcoded-inner-navbar sidenav-inner"
      (clickOutside)="fireLeave()"
      (mouseleave)="fireLeave()"
    >
      <ng-container *ngFor="let item of navigation">
        <app-nav-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></app-nav-group>
        <app-nav-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
        ></app-nav-collapse>
        <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </div>
  <a
    href="javascript:"
    class="sidenav-horizontal-next"
    [ngClass]="nextDisabled"
    (click)="scrollPlus()"
  ></a>
</div>
