import { 
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from "@angular/router";
import { MessagingDataService } from './messaging-data.service';
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Table } from "primeng/table/table";
import { saveAs } from "file-saver";
import { MessagingService } from '../messaging.service';
import { JobService } from '../../job/job-data.service';

enum MessageStatus {
  New = 'new',
  Sent = 'sent',
  Draft = 'draft',
  Scheduled = 'scheduled'
}

interface messageStatus {
  name: string,
  value: string
}


@Component({
  encapsulation:ViewEncapsulation.None,
  selector: 'app-messaging-data',
  templateUrl: './messaging-data.component.html',
  styleUrls: ['./messaging-data.component.scss']
})

export class MessagingDataComponent implements OnInit {
  @ViewChild("dt", { static: false }) table: Table;
  
  pageMessageIndex;
  cols = [];
  messageList = [];
  messageStatus: messageStatus;
  statusOptions : messageStatus[];
  currentMessageStatus: MessageStatus;
  selectedMessage: any;
  showSend = false;
  companyID: any;
  isAcumen: any;
  jobData = {};
  startDate; //= new Date();
  endDate; // = new Date();
  showLoader = true;
  searchArr = [];
  constructor(
    private MessagingService: MessagingService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    private router: Router,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService
  ) { 
    this.statusOptions = [
      {"name": "All",
        "value": null
      },
      {"name": "Sent",
        "value": "sent"
      },
      {"name": "Draft",
        "value": "draft"
      },
      {"name": "Scheduled",
        "value": "scheduled"
      }
    ];
  }

  ngOnInit(): void {
    console.log("on init: ", this.startDate, " : ", this.endDate);
    const searchParams = this.MessagingService.getParams();
    console.log("on init searchParams: ", searchParams);

    if (searchParams.startDate) {
      this.startDate = searchParams.startDate;
    } else {
      this.startDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 30);
    }
    if (searchParams.endDate) {
      this.endDate = searchParams.endDate;
    } else {
      this.endDate = new Date();
    }

    if (searchParams.status) {
      this.messageStatus = {
        "name": this.statusOptions.find(option => option.value === searchParams.status)["name"],
        "value": searchParams.status
      };
    } else {
      this.messageStatus = this.statusOptions[0];
    }

    this.pageMessageIndex = parseInt(localStorage.getItem("pageMessageIndex"));
    if (this.pageMessageIndex) {
      localStorage.removeItem("pageMessageIndex");
    }
    this.isAcumen = localStorage.getItem("isAcumen");
    this.fetchMessageList({ startDate: this.startDate, endDate: this.endDate, status: this.messageStatus.value });
    if (this.isAcumen == true || this.isAcumen == "true") {
      this.cols = [
        {
          header: "Sender name",
          field: "sender_name",
        },
        {
          header: "Sender email",
          field: "sender_email",
        },
        {
          header: "Date modified",
          field: "modified_at",
        },
        {
          header: "Date of dispatch",
          field: "date_of_dispatch",
        },
        {
          header: "Status",
          field: "status",
        },
      ];
    } else {
      this.cols = [
        {
          header: "Sender name",
          field: "sender_name",
        },
        {
          header: "Sender email",
          field: "sender_email",
        },
        {
          header: "Date modified",
          field: "modified_at",
        },
        {
          header: "Date of dispatch",
          field: "date_of_dispatch",
        },
        {
          header: "Status",
          field: "status",
        },
      ];
    }
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
    if (localStorage.getItem("send-message") != null) {
      const dataMap = JSON.parse(localStorage.getItem("send-message"));
      for (let category in dataMap["selection"]) {
        if (Object.keys(dataMap["selection"][category]).length > 0) {
          this.showSend = true;
          break
        }
      }
      
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('send-message');
  }

  searchMessages(event) {
    console.log("search: ", this.startDate, " : ", this.endDate);
    this.MessagingService.setParams(this.startDate, this.endDate, this.messageStatus.value);
    this.showLoader = true;
    this.fetchMessageList({ startDate: this.startDate, endDate: this.endDate, status: this.messageStatus.value });
  }

  reload(event) {
    console.log("reload event called: ", this.startDate, " : ", this.endDate);
    this.showLoader = true;
    this.fetchMessageList({ startDate: this.startDate, endDate: this.endDate, status: this.messageStatus.value});
  }

  onPageEvent(event) {
    console.log("event", event);
    this.pageMessageIndex = event.first / event.rows + 1;
  }

  setPage(n: number) {
    this.table.reset();
    let paging = {
      first: (n - 1) * this.table.rows,
      rows: this.table.rows,
    };
    console.log("table values", paging);
    this.table.first = paging.first;
    this.table.rows = paging.rows;
  }

  viewMessage(event, data) {
    if (this.pageMessageIndex != 1) {
      let pageNum = this.pageMessageIndex.toString();
      localStorage.setItem("pageMessageIndex", pageNum);
    }
    this.router.navigate(["messages/data/" + data._id]);
  }

  fetchMessageList(searchParams) {
    let messageArr = [];
    this.MessagingService.messageList(searchParams)
    .subscribe((data) => {
      if (data.length > 0) {
        messageArr = data;
      }
      this.messageList = messageArr;
      console.log("Message list 1", this.messageList);
      this.showLoader = false;
      if (this.pageMessageIndex && this.pageMessageIndex > 1) {
        console.log("page index", this.pageMessageIndex);
        this.setPage(this.pageMessageIndex);
      }
    },
    (error) => {
      console.log(error);
      this.showLoader = false;
      this.messageList = [];
    })

    
  }


  createMessage(event) {
    this.currentMessageStatus = MessageStatus.New;
    this.showSend = true;
  }

  closeEvents(event) {
    this.showSend = false;
    this.fetchMessageList({ startDate: this.startDate, endDate: this.endDate, status: this.messageStatus.value});
  }

  openEvents(event) {
    this.showSend = true;
  }

  deleteMessage(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete this Message?",
      accept: () => {
        this.MessagingService.deleteMessage(id)
        .subscribe(
          (response) => {
            if (response.message == "success") {
              this.messageService.add({
                severity: "success",
                summary: "Message Deleted",
                detail: "Message deleted successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Message Deleted",
                detail: response.detail,
              });
            }
            this.fetchMessageList({ startDate: this.startDate, endDate: this.endDate, status: this.messageStatus.value});
            
            
          },
          (error) => {
            console.log(error);
            this.messageService.add({
              severity: "error",
              summary: "Message Deleted",
              detail: "Failed to delete the Customer",
            });
            this.fetchMessageList({ startDate: this.startDate, endDate: this.endDate, status: this.messageStatus.value});
          }
        )
      }
    })
    
  }

  formatDate(date) {
    if (date === null) {
      return "NA";
    }
    let datetime = new Date(date);
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    try {
      return new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'full',
        timeStyle: 'long',
      }).format(datetime);
    } catch (error) {
      return "";
    }
    
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

}
