import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FormQuestionsService } from "../form-questions.service";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DragulaService } from "ng2-dragula";
import { JobService } from "../../job/job-data.service";
import { pluck } from "underscore";

@Component({
  selector: "app-add-edit-view-form-questions",
  templateUrl: "./add-edit-view-form-questions.component.html",
  styleUrls: ["./add-edit-view-form-questions.component.scss"],
})
export class AddEditViewFormQuestionsComponent implements OnInit {
  questionsForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  isUpdate = false;
  slide = true;
  clickOpen = false;
  isEdit = false;
  errorMessage = false;
  isAcumen: any;
  companyID: any;
  companyList = [{ label: "Please Select", value: "" }];

  questionTypeList = [
    { label: "Checkbox", value: "checkbox" },
    { label: "File", value: "image_file" },
    { label: "Image", value: "image" },
    { label: "Location", value: "location" },
    // { label: "Image/File", value: "image_file" },
    { label: "Radio", value: "radio" },
    { label: "Text", value: "text" },
    { label: "Text Area", value: "text_area" },
  ];
  public isReorderVisible = false;
  data: any = [];
  @Input("data") viewData: any = {};
  @Input("isView") isView = false;
  @Input("isNew") isNew = false;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  constructor(
    private messageService: MessageService,
    private service: FormQuestionsService,
    private cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _ds: DragulaService,
    private fb: FormBuilder,
    private jobService: JobService
  ) {
    this.questionsForm = new FormGroup({
      questionName: new FormControl("", Validators.required),
      questionText: new FormControl("", Validators.required),
      questionType: new FormControl("checkbox", Validators.required),
      questionAnswers: new FormArray([], Validators.required),
      active: new FormControl(true, Validators.required),
    });
    this._ds.createGroup("question-items", {});
  }

  addAnswer(ans?: string) {
    // prepopulate if ans is present
    let dataToFill = ans ? ans : "";
    return new FormGroup({
      answer: new FormControl(dataToFill),
    });
  }

  onChange(event) {
    this.errorMessage = false;
    this.cdr.detectChanges();
  }
  editEvent(event) {
    this.isEdit = true;
    this.isUpdate = true;
    this.isView = false;
  }

  // Creating the question
  createQuestion(e) {
    console.log(this.questionsForm.status);
    console.log(this.questionsForm);
    let value = this.questionsForm.value || {};
    const payload = {
      itemName: value.questionName,
      itemText: value.questionText,
      itemData: [
        {
          itemInputType: value.questionType,
          itemData: value.questionAnswers,
        },
      ],
      createdBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"), // TODO: user id should go here
      createdDate: new Date(),
      reviewedBy: "", // TODO: user id should go here
      // reviewedDate: new Date(),
      active: value.active,
    };
    this.errorMessage = false;
    this.service.insertQuestion(payload).subscribe(
      (resp: any) => {
        try {
          if (resp && resp.data) {
            this.messageService.add({
              severity: "success",
              summary: "Create Question",
              detail: "New Question created successfully",
            });
            this.ref.close(this);
            this.closeEvents.emit(true);
          }
        } catch (e) {
          console.warn(e);
        }
      },
      (err) => {
        console.log(err);
        if (err?.error?.message) {
          this.ref.close(this);
          this.closeEvents.emit(true);
          this.messageService.add({
            severity: "error",
            detail: err.error.message,
          });
        }
      }
    );
  }

  updateQuestion(event: any) {
    let value = this.questionsForm.value || {};
    let id = this.viewData._id;

    const payload = {
      itemName: value.questionName,
      itemText: value.questionText,
      itemData: [
        {
          itemInputType: value.questionType,
          itemData: value.questionAnswers,
        },
      ],
      // createdBy: this.viewData.createdBy, // TODO: user id should go here
      // createdDate: new Date(),
      reviewedBy:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"), // TODO: user id should go here
      reviewedDate: new Date(),
      active: value.active,
    };
    this.errorMessage = false;
    //  delete value.cpassword;
    this.service.editQuestion(payload, id).subscribe((data: any) => {
      if (data.status === 200) {
        this.messageService.add({
          severity: "success",
          summary: "Update Question",
          detail: "Question details updated successfully",
        });
        this.closeEvent(true);
      } else if (data.status === 500) {
        this.messageService.add({
          severity: "error",
          summary: "Update Admin",
          detail: data.error.message.errmsg,
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Update Admin",
          detail: "Unable to update User.Please try again.",
        });
      }
    });
  }

  createAnswer(event) {
    const ansArray: FormArray = this.questionsForm.get(
      "questionAnswers"
    ) as FormArray;
    ansArray.push(this.addAnswer());
  }

  deleteAns(index) {
    const ansArray: FormArray = this.questionsForm.get(
      "questionAnswers"
    ) as FormArray;
    ansArray.removeAt(index);
  }

  ngOnInit() {
    console.log(this.isView);
    console.log(this.viewData);
    console.log(this.isNew);
    this.viewData.active = JSON.parse(this.viewData.active);
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");
    this.loadData();
  }
  ngOnDestroy() {
    this._ds.destroy("question-items");
  }

  loadData() {
    if (this.viewData && Object.keys(this.viewData).length !== 0) {
      this.questionsForm.patchValue({
        questionName: this.viewData.itemName,
        questionText: this.viewData.itemText,
        active: this.viewData.active,
        questionType: this.viewData.itemInputType,
        questionAnswers:
          this.viewData.itemData &&
          this.viewData.itemData.length &&
          this.viewData.itemData[0].itemData,
      });
      console.log(this.questionsForm);
      const ansArray: FormArray = this.questionsForm.get(
        "questionAnswers"
      ) as FormArray;
      let itemArray =
        (this.viewData.itemData &&
          this.viewData.itemData.length &&
          this.viewData.itemData[0].itemData) ||
        [];
      itemArray.forEach((element) => {
        ansArray.push(this.fb.group(element));
      });
    }
  }

  closeEvent(event) {
    this.closeEvents.emit(true);
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  onQuestionTypeSelect(event) {
    console.log(event);
    if (
      event.value === "image_file" ||
      event.value === "image" ||
      event.value === "text" ||
      event.value === "text_area" ||
      event.value === "location"
    ) {
      this.questionsForm.controls["questionAnswers"].clearValidators();
      this.questionsForm.get("questionAnswers").updateValueAndValidity();
    } else {
      this.questionsForm.controls["questionAnswers"].setValidators(
        Validators.required
      );
      this.questionsForm.get("questionAnswers").updateValueAndValidity();
    }
  }

  hideAnswers() {
    if (this.questionsForm?.value?.questionType === "image_file") {
      return false;
    }
    if (this.questionsForm?.value?.questionType === "image") {
      return false;
    }
    if (this.questionsForm?.value?.questionType === "text") {
      return false;
    }
    if (this.questionsForm?.value?.questionType === "text_area") {
      return false;
    }
    if (this.questionsForm?.value?.questionType === "location") {
      return false;
    }

    return true;
  }

  public getFormArray() {
    let array = pluck(
      this.questionsForm.get("questionAnswers")["controls"],
      "value"
    );
    return array;
  }

  public onDropQuestions(event) {
    let ansArray: FormArray = this.questionsForm.get(
      "questionAnswers"
    ) as FormArray;
    ansArray.clear();
    event.forEach((eachAnswer) => {
      ansArray.push(this.addAnswer(eachAnswer?.answer));
    });
  }

  public onReorder() {
    this.isReorderVisible = !this.isReorderVisible;
  }
}
