import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Injectable()
export class HazardousService {
    constructor(private http: HttpClient) { }

    fetchHazardous() {
        return this.http.post(`${environment.stitchApiURL}hazardous/incoming_webhook/fetchHazardous?isAcumen=true`, {})
    }

    updateHazardous(data) {
        console.log('udpate hazardous: ', data);
        return this.http.post(`${environment.stitchApiURL}hazardous/incoming_webhook/updateHazardous?isAcumen=true&id=${data._id}`, data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
}
