import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation,
  ɵConsole,
} from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { WasteDescriptionService } from "../waste-description/waste-description.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { HazardousService } from "./hazardous.service";
import { JobService } from "../job/job-data.service";
@Component({
  selector: "app-hazardous",
  templateUrl: "./hazardous.component.html",
  styleUrls: ["./hazardous.component.scss"],
})
export class HazardousComponent implements OnInit {
  wasteDescriptionForm: FormGroup;
  subjectList = [];
  data: any;
  wasteCategoryList = [];
  allwasteCategoryList = [];
  wasteTypeData: any = [];
  isSubjectVisible = false;
  isUpdate = false;
  // CompanyForm: FormGroup;
  isView = false;
  _data: any = [];

  constructor(
    private service: HazardousService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService
  ) {
    this.wasteDescriptionForm = new FormGroup({
      waste_description: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.wasteDescriptionForm = new FormGroup({
      description: new FormControl("", [Validators.required]),
    });
    this.getData();
  }
  getData() {
    this.service.fetchHazardous().subscribe((resp: any) => {
      console.log("data", resp.data);
      if (resp.data) {
        this.data = resp.data;
        this.isUpdate = true;
        this.wasteDescriptionForm
          .get("description")
          .setValue(this.data.description);
      } else {
        this.isUpdate = false;
      }
    });
  }
  update(event) {
    let temp;
    temp = {
      _id: this.data._id,
      description: this.wasteDescriptionForm.value.description,
    };
    console.log(temp);
    this.service.updateHazardous(temp).subscribe((data: any) => {
      this.messageService.add({
        severity: "success",
        summary: "Update Waste Description",
        detail: "Waste Description updated successfully",
      });
      this.getData();
    });
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
