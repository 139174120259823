import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagingDataService } from '../messaging-data/messaging-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, Table } from 'primeng';
import { FormControl, FormGroup } from '@angular/forms';
import { MessagingService } from '../messaging.service';
import { JobService } from '../../job/job-data.service';


enum MessageStatus {
  Sent = 'sent',
  Draft = 'draft',
  Scheduled = 'scheduled'
}
 

@Component({
  selector: 'app-view-message-details',
  templateUrl: './view-message-details.component.html',
  styleUrls: ['./view-message-details.component.scss']
})
export class ViewMessageDetailsComponent implements OnInit {
  @ViewChild("dt", { static: false }) table: Table;
  data: any = [];
  status: MessageStatus;
  editorData: { [key: string]: any } = {};
  recipientsList = [];
  messageList = [];
  searchArr = [];
  messageId: string;
  messageForm: FormGroup;
  selectedRecipient;
  showSend = false;
  showLoader = false;
  pageMessageIndex;
  title: string;
  message: string;
  cols = [
    {
      header: "Email",
      field: "email",
    },
    {
      header: "Name",
      field: "full_name",
    },
    {
      header: "Department Name",
      field: "department_name",
    },
    {
      header: "Company Name",
      field: "company_name",
    },
  ]
  constructor(
    private MessagingService: MessagingService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobService
  ) { 
    this.messageForm = new FormGroup({
      message: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.messageForm = new FormGroup({
      message: new FormControl(""),
    });
    const id = this.route.snapshot.paramMap.get("id");
    this.messageId = id;
    this.getData(id);
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
    this.showLoader = false;
    
  }

  getData(id: string) {
    this.showLoader = true;
    this.MessagingService.getMessageDetails(id)
    .subscribe((data) => {
      this.data = data;
      this.recipientsList = data.recipients;
      this.status = data.status;
      this.editorData["messageData"] = { "textData": data.message_data["text_data"]};
      if (this.canEdit()) {
        this.editorData["selection"] = data.editor_data["selection"];
      }
      if (this.status === MessageStatus.Scheduled) {
        this.editorData["messageId"] = id;
        this.editorData["scheduleMode"] = true;
        this.editorData["scheduleDate"] = data.date_of_dispatch;
      }
      this.messageForm.get("message").setValue(data.message_data["text_data"]);
      if (localStorage.getItem("send-message") !== null) {
        const savedDataMap = JSON.parse(localStorage.getItem("send-message"));
        this.removeListItems(savedDataMap);
        const notEdited = this.compareSelections(savedDataMap["selection"], this.editorData["selection"]);
        if (notEdited === false) {
          this.showSend = true;
        } else if (this.scheduleChanged(savedDataMap, data)) {
          this.showSend = true;
        }
      }
    },
    (error) => {
      console.log(error);
      this.router.navigate(["messages/data"]);
    });
  }

  scheduleChanged(savedDataMap, remoteDataMap) {
    if (savedDataMap["messageStatus"] !== remoteDataMap["status"]) {
      console.log("OOOOOOOOOOOO");
      return true;
    }
    else if (remoteDataMap["date_of_dispatch"] == null) {
      return false;
    }
    else if (savedDataMap["scheduleDate"] !== remoteDataMap["date_of_dispatch"]){
      return true
    } else return false;
  }

  compareSelections(selection1, selection2) {
    for (let category in selection1) {
      const ids1 = Object.keys(selection1[category]);
      const ids2 = Object.keys(selection2[category]);
      console.log(ids1, ids2);
      if (ids1.length !== ids2.length) {
        return false;
      }

      for (let id of ids1) {
        if (!ids2.includes(id)) {
            return false;
        }
      }
      
    }
    return true;
  }


  removeListItems(dataMap) {
    let listItemsToRemove = dataMap["listItemsToRemove"];
    for (let key in dataMap["selection"]) {
      listItemsToRemove[key].forEach((id) => {
        delete dataMap["selection"][key][id];
        listItemsToRemove[key] = [];
      });
    }
  }



  canEdit() {
    if (this.status === MessageStatus.Draft || this.status === MessageStatus.Scheduled) {
      return true;
    } else {
      return false;
    }
  }

  closeEvent(event) {
    console.log("close event");
    localStorage.removeItem("send-message");
    this.router.navigate(["messages/data"]);
  }


  openEditor() {
    console.log(new Date(this.data.date_of_dispatch), new Date())
    if (new Date(this.data.date_of_dispatch) < new Date()) {
      this.status = MessageStatus.Sent
      this.messageService.add({
        severity: "error",
        summary: "Message has already been sent",
      });
    } else {
      this.showSend = true;
    }
    
  }

  onPageEvent(event) {
    console.log("event", event);
    this.selectedRecipient = event.first / event.rows + 1;
  }

  closeEditor(event) {
    localStorage.removeItem("send-message");
    this.showSend = false;
    this.getData(this.messageId);
    this.showLoader = false;
  }

  formatDate(date) {
    let datetime = new Date(date);
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    try {
      return new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'full',
        timeStyle: 'long',
      }).format(datetime);
    } catch (error) {
      return "";
    }
    
  }

  isScheduled() {
    return this.status === MessageStatus.Scheduled;
  }

  isSent() {
    return this.status === MessageStatus.Sent;
  }

  enumTitle() {
    if (this.status === MessageStatus.Draft) {
      return `Saved by ${this.data.sender_name} on ${this.formatDate(this.data.modified_at)}`
    } else if (this.status === MessageStatus.Scheduled) {
      return `Scheduled by ${this.data.sender_name} for ${this.formatDate(this.data.date_of_dispatch)}`
    } else if (this.status === MessageStatus.Sent) {
      return `Sent by ${this.data.sender_name} on ${this.formatDate(this.data.date_of_dispatch)}`
    }
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

}
