<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card moduleName="Sections" module="Section" (addQuestion)="createSection($event)" (jobReload)="reload($event)"
      cardClass="table-card" blockClass="p-0">
      <p-table #dt [value]="sectionList" [(selection)]="selectedAdmin" dataKey="_id" styleClass="ui-table-customers"
        [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="searchArr">
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <!-- <th>Form Allocation</th> -->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of cols" (click)="viewSection($event, car)">
              {{ car[col.field] }}
            </td>
            <td>
              <span (click)="viewSection($event, car)" pTooltip="Click to view a Section" tooltipPosition="top"
                class="mob_icon style-toggler">
                <i class="fa fa-eye fa-2x"></i>
              </span>
              <span (click)="editSection($event, car)" pTooltip="Click to edit a Section" tooltipPosition="top"
                class="mob_icon style-toggler delete_icon" *ngIf="checkPermissions('edit', 'Form Sections')">
                <i class="fa fa-edit fa-2x"></i>
              </span>
              <span (click)="duplicateSection($event, car)" pTooltip="Click to duplicate a Section"
                tooltipPosition="top" class="mob_icon style-toggler delete_icon"
                *ngIf="checkPermissions('create', 'Form Sections')">
                <i class="fa fa-clone fa-2x"></i>
              </span>
              <span (click)="deleteSection(car._id)" pTooltip="Click to delete a Section" tooltipPosition="top"
                class="style-toggler mob_icon delete_icon" *ngIf="checkPermissions('delete', 'Form Sections')">
                <i class="fa fa-trash fa-2x"></i>
              </span>
            </td>
            <!-- <td>{{car['name']}}</td> -->
          </tr>
        </ng-template>
        <ng-template class="noRecords" *ngIf="sectionList.length == 0" pTemplate="emptymessage">
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length + 2">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>
    </app-card>
  </div>
  <!-- tabs card end -->
</div>

<div *ngIf="showAdd">
  <app-add-edit-view-form-sections [isView]="isView" [data]="sectionData" (closeEvents)="callList($event)"
    (openAddQues)="openAddQues($event)">
  </app-add-edit-view-form-sections>
</div>

<div *ngIf="showAddQues">
  <app-add-edit-view-form-questions (closeEvents)="closeAddQues($event)">
  </app-add-edit-view-form-questions>
</div>