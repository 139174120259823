<div class="row">
  <div class="col-sm-12">
    <app-card cardClass="table-card view-job-details" blockClass="p-0">
      <div class="row">
        <div class="ui-g-11">
          <h1 class="parent">{{ reportDetails?.live_report_number }}</h1>
          <p-button
            label="Messages"
            class="chat"
            (click)="openChat()"
            *ngIf="!openChatWindow"
          ></p-button>
        </div>
        <div class="ui-g-1">
          <div
            (click)="closeEvent($event)"
            pTooltip="Click to close a Inspection Report"
            tooltipPosition="top"
          >
            <i class="fa fa-window-close fa-2x"></i>
          </div>
        </div>
      </div>
      <div class="row view-job-tab">
        <div class="ui-g-12">
          <div class="container h-100 job-details">
            <div class="row align-items-center h-100">
              <div class="col-12 mx-auto">
                <div class="row report_details-wrap">
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col pull-right"
                        *ngIf="checkPermissions('edit', 'Reports')"
                      >
                        <!-- <p-button
                          label="Edit"
                          *ngIf="
                            !checkReportToAndSignOffSubmission() && !isEditView
                          "
                          (onClick)="editReport($event)"
                        >
                        </p-button> -->
                        <p-button
                          label="Save"
                          *ngIf="isEditView"
                          (onClick)="saveReport($event)"
                        ></p-button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="contentArea">
                  <div class="row my-3">
                    <div class="col">
                      <div class="row">
                        <div class="col bolderTxt">
                          <b>Report Number</b
                          ><span class="mx-3">{{
                            reportDetails?.live_report_number
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <b>Date and Time for report</b
                          ><span class="mx-3">{{
                            reportDetails?.date_time_submit | date: "dd-MM-yyyy"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col">
                      <div class="row">
                        <div class="col mb-3">
                          <b>Report Name</b
                          ><span class="mx-3">{{
                            reportDetails?.formName
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col">
                      <div class="row">
                        <div class="col mb-3">
                          <b>Reporter Name</b
                          ><span class="mx-3">{{
                            reportDetails?.reporter_name
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col">
                      <div class="row">
                        <div class="col mb-3">
                          <b>Company Name</b
                          ><span class="mx-3">{{
                            reportDetails?.company.companyName
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col">
                      <div class="row">
                        <div class="col mb-3">
                          <b>Submitted {{ reportDetails?.formType }} Report</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <!-- <div class="col-12">
                      <div class="row">
                        <div class="col  mb-3">
                          <b>Submitted {{ reportDetails?.formType }} Report</b>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-12">
                      <div *ngFor="let data of data_list">
                        <b>{{ data[0] }}</b>
                        <p></p>
                        <div *ngFor="let questions of data[1]">
                          <p>{{ questions?.question }}</p>
                          <p>{{ questions?.answer }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>
                      <b
                        >Report To:
                        <span *ngIf="superAdmin">
                          {{ getReportToNames("reportTo") }}</span
                        ></b
                      >
                    </p>
                    <div class="row mb-3">
                      <div class="col-6">
                        <b>Check on Actions for Improvement </b>
                      </div>
                      <div class="col-12 mb-3" *ngIf="!isEditViewForReport">
                        {{ reportDetails?.reportTo?.improvement }}
                      </div>
                      <div class="col-12 mb-3" *ngIf="isEditViewForReport">
                        <textarea
                          class="mx-3"
                          style="width: 95%"
                          [(ngModel)]="reportToData.improvement"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-6"><b>Notes </b></div>
                      <div class="col-12 mb-3" *ngIf="!isEditViewForReport">
                        {{ reportDetails?.reportTo?.notes }}
                      </div>
                      <div class="col-12 mb-3" *ngIf="isEditViewForReport">
                        <textarea
                          class="mx-3"
                          style="width: 95%"
                          [(ngModel)]="reportToData.notes"
                        ></textarea>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col">
                        <b>Report Accepted</b>
                        <span class="mx-3" *ngIf="!isEditViewForReport">{{
                          reportDetails?.reportTo?.reportAccepted === "true"
                            ? "Yes"
                            : "No"
                        }}</span>
                        <select
                          *ngIf="isEditViewForReport"
                          class="w-50 mx-3"
                          [(ngModel)]="reportToData.reportAccepted"
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>

                      <div class="col">
                        <b>Accepted By</b>
                        <!-- <span class="mx-3" *ngIf="!isEditViewForReport">{{
                          reportDetails?.reportTo?.acceptedBy
                        }}</span> -->
                        <span class="mx-3"
                          >{{ reportToData?.acceptedBy }}
                        </span>
                      </div>
                      <div class="col">
                        <b>Date</b>
                        <span class="mx-3"
                          >{{ reportToData?.acceptedDate | date: "dd-MM-yyy" }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="reportDetails?.reportTo?.reportAccepted === 'true'"
                  >
                    <p>
                      <b
                        >SignOff :
                        <span *ngIf="superAdmin">
                          {{ getReportToNames("signoff") }}</span
                        ></b
                      >
                    </p>
                    <div class="row mb-3">
                      <div class="col-6">
                        <b>Check on Actions for Improvement </b>
                      </div>
                      <div class="col-12 mb-3" *ngIf="!isEditViewForSignOff">
                        {{ reportDetails?.signOff?.improvement }}
                      </div>
                      <div class="col-12 mb-3" *ngIf="isEditViewForSignOff">
                        <textarea
                          class="mx-3"
                          style="width: 95%"
                          [(ngModel)]="signOffData.improvement"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-6"><b>Notes </b></div>
                      <div class="col-12 mb-3" *ngIf="!isEditViewForSignOff">
                        {{ reportDetails?.signOff?.notes }}
                      </div>
                      <div class="col-12 mb-3" *ngIf="isEditViewForSignOff">
                        <textarea
                          class="mx-3"
                          style="width: 95%"
                          [(ngModel)]="signOffData.notes"
                        ></textarea>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col">
                        <b>Report Accepted</b>
                        <span class="mx-3" *ngIf="!isEditViewForSignOff">{{
                          reportDetails?.signOff?.reportAccepted === "true"
                            ? "Yes"
                            : "No"
                        }}</span>
                        <select
                          *ngIf="isEditViewForSignOff"
                          class="w-50 mx-3"
                          [(ngModel)]="signOffData.reportAccepted"
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>

                      <div class="col">
                        <b>Accepted By</b>
                        <!-- <span class="mx-3" *ngIf="!isEditViewForSignOff">{{
                          reportDetails?.signOff?.acceptedBy
                        }}</span> -->
                        <!-- <input type="text" class="mx-3" [(ngModel)]="reportDetails.acceptedBy" *ngIf='isEditView'> -->
                        <span class="mx-3">{{ signOffData?.acceptedBy }} </span>
                      </div>
                      <div class="col">
                        <b>Date</b>
                        <span class="mx-3"
                          >{{ signOffData?.acceptedDate | date: "dd-MM-yyy" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-3 contentFooter">
                  <div class="col-12">
                    <div class="row">
                      <div class="col">
                        <p-button
                          label="Cancel"
                          (onClick)="backToReport($event)"
                          *ngIf="isEditViewForSignOff || isEditViewForReport"
                        ></p-button>
                      </div>
                      <div
                        class="col pull-right"
                        *ngIf="checkPermissions('edit', 'Reports')"
                      >
                        <p-button
                          label="Edit"
                          *ngIf="
                            checkReportToAndSignOffSubmission() &&
                            !(isEditViewForSignOff || isEditViewForReport)
                          "
                          (onClick)="editReport($event)"
                        >
                        </p-button>
                        <p-button
                          label="Save"
                          *ngIf="isEditViewForSignOff || isEditViewForReport"
                          (onClick)="saveReport($event)"
                        ></p-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="loader" *ngIf="showLoader"></div>
        </div>
      </div>
    </app-card>
  </div>
</div>

<div *ngIf="openChatWindow">
  <app-chat [data]="reportDetails" (closeEvents)="closeChatWindow($event)">
  </app-chat>
</div>
