import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

enum MessageStatus {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Sent = 'sent'
}


@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  status: MessageStatus;
  startDate: any;
  endDate: any;
  date;
  constructor(private http: HttpClient) { }


  setDate(date) {
    this.date = date;
  }

  messageList(data?: any) {
    if (data && data.endDate) {
      let tempDate = new Date(data.endDate);
      tempDate.setHours(23, 59, 59, 999);
      data["to_date"] = tempDate;
      delete data.endDate;
    }
    if (data && data.startDate) {
      data["from_date"] = data.startDate
    }
    if (data && Object.keys(data).length == 0) {
      return this.http.get(environment.messagingApiURL + "messages/").pipe(
        tap((response: any) => {
          return response;
        })
      );
    } else {
      return this.http.post(environment.messagingApiURL + "messages/", data).pipe(
        tap((response: any) => {
          return response;
        })
      );
    }
  }

  getMessageDetails(id: string) {
    return this.http.get(environment.messagingApiURL + `messages/${id}`).pipe(
      tap((response: any) => {
        return response;
      })
    )
  }

  setParams(startDate, endDate, status) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.status = status;
  }

  getParams() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      status: this.status
    };
  }

  deleteMessage(id) {
    return this.http.delete(environment.messagingApiURL + `messages/delete/${id}`).pipe(
      tap((response: any) => {
        return response;
      })
    )
  }

  createNewMessage(message) {
    return this.http.post(environment.messagingApiURL + "messages/new", message).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateMessage(message) {
    return this.http.put(environment.messagingApiURL + "messages/update", message).pipe(
      tap((response: any) => {
        return response;
      })
    )
  }

  getAllItemsForFilter(matchString: string, path: string, filter?: any) {
    let queryString: string = path;
    if (matchString !== null) {
      queryString = `${path}?match_string=${matchString}`;
    }
    if (filter === null) {
      console.log(environment.messagingApiURL + queryString);
      return this.http.get(environment.messagingApiURL + queryString).pipe(
        tap((response: any) => {
          return response;
        })
      );
    } else {
      return this.http.post(environment.messagingApiURL + queryString, filter).pipe(
        tap((response: any) => {
          return response;
        })
      )
    }
  }

}
