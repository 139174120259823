import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchFilterAddService {


  constructor(private http: HttpClient) { }

  listCompanies(matchString?: string) {
    let queryPath: string;
    if (matchString != null) {
      queryPath = `/company/?match_string=${matchString}`;
    } else {
      queryPath = '/company/';
    }
    return this.http.get(environment.messagingApiURL + queryPath).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  listDepartments(matchString?: string, companies?: string[]) {
    const data = {
      match_string: matchString,
      companies: companies
    };
    return this.http.post(environment.messagingApiURL  +'/department/', data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }


  listUsers(matchString?: string, companies?: string[], departments?: string[]) {
    const data = {
      match_string: matchString,
      companies: companies,
      departments: departments
    };
    return this.http.post(environment.messagingApiURL +'/user/', data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  list() {

  }

  search(matchString) {

  }

  getAllItemsForFilter(matchString: string, path: string, filter?: any) {
    let queryString: string = path;
    if (matchString !== null) {
      queryString = `${path}?match_string=${matchString}`;
    }
    if (filter === null) {
      console.log(environment.messagingApiURL + queryString);
      return this.http.get(environment.messagingApiURL + queryString).pipe(
        tap((response: any) => {
          return response;
        })
      );
    } else {
      return this.http.post(environment.messagingApiURL + queryString, filter).pipe(
        tap((response: any) => {
          return response;
        })
      )
    }
  }

}



