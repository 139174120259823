import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation,
} from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { WasteDescriptionService } from "./waste-description.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { JobService } from "../job/job-data.service";
@Component({
  selector: "app-waste-description",
  templateUrl: "./waste-description.component.html",
  styleUrls: ["./waste-description.component.scss"],
})
export class WasteDescriptionComponent implements OnInit {
  wasteDescriptionForm: FormGroup;
  subjectList = [];
  data: any;
  wasteCategoryList = [];
  allwasteCategoryList = [];
  wasteTypeData: any = [];
  isSubjectVisible = false;
  isUpdate = false;
  // CompanyForm: FormGroup;
  isView = false;
  _data: any = [];

  constructor(
    private service: WasteDescriptionService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService
  ) {
    this.wasteDescriptionForm = new FormGroup({
      waste_description: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.wasteDescriptionForm = new FormGroup({
      waste_description: new FormControl("", [Validators.required]),
    });
    this.getData();
    // this.checkPermissions("edit", "Waste Description")
    //   ? this.wasteDescriptionForm.controls["waste_description"].disable()
    //   : "";
  }
  getData() {
    this.service.wasteDescriptionList().subscribe((data: any) => {
      console.log("data", data);
      if (data.length > 0) {
        this.data = data;
        this.isUpdate = true;
        this.wasteDescriptionForm
          .get("waste_description")
          .setValue(data[0].waste_description);
      } else {
        this.isUpdate = false;
      }
    });
  }
  create(event) {
    this.service
      .addWasteDescription(this.wasteDescriptionForm.value)
      .subscribe((data: any) => {
        this.messageService.add({
          severity: "success",
          summary: "Create Waste Description",
          detail: "Waste Description created successfully",
        });
        this.getData();
      });
  }
  update(event) {
    let temp;
    temp = {
      _id: this.data[0]._id,
      waste_description: this.wasteDescriptionForm.value.waste_description,
    };
    this.service.updateWasteDescription(temp).subscribe((data: any) => {
      this.messageService.add({
        severity: "success",
        summary: "Update Waste Description",
        detail: "Waste Description updated successfully",
      });
      this.getData();
    });
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
