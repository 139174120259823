<div class="">
  <div
    id="styleSelector"
    class="style-block job-container {{
      slide ? 'animate-block' : 'slide-out'
    }} open"
  >
    <div class="container h-100 job-details">
      <div class="row align-items-center h-100">
        <div class="col-12 mx-auto">
          <div class="row details-header">
            <div class="ui-g-10">
              <h4>View Job Details</h4>
            </div>
            <div class="ui-g-2" (click)="closeEvent($event)">
              <i class="fa fa-window-close fa-2x"></i>
            </div>
          </div>
          <div class="details-content">
            <div class="row view-more">
              <div class="ui-g-6"></div>
              <div class="ui-g-6">
                <button
                  (click)="viewMore($event)"
                  ngbtooltip="btn-outline-primary"
                  class="outline-btns btn btn-outline-primary"
                >
                  View more
                </button>
                <!-- <a href="javascript:void(0)" (click)="viewMore($event)">View More</a> -->
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Job No.</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.job_reference }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Name of the Employee</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.employeeName }}</span>
              </div>
            </div>

            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Employee ID</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.employeeId }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Action Required</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.action_required }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Company Name</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.company.companyName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Division/Department</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.department.departmentName
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Tip Site</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.tip_location }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Disposal Site</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.disposalLocationName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Waste Type</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.waste_type }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Completed</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{ viewJob.isCompleted }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Distance from Start to Disposal</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.disposalDistace
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Distance from Disposal to final</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.finalDistace
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Total distance</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.travelDistance
                }}</span>
              </div>
            </div>

            <div class="row">
              <div class="ui-g-6">
                <span for="userName">PPM for vehicle used</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.ppm
                }}</span>
              </div>
            </div>

            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Vehicle type used</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.vehicleType
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Estimated tonnage</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.estimatedTonnage
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Disposal cost</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.tonnageCost
                }}</span>
              </div>
            </div>

            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Travel cost</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.travelCost
                }}</span>
              </div>
            </div>

            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Metal in load </span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.metal_rebate
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Estimated weight of metal</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.metalRebateCost
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Driver cost</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.driverCost
                }}</span>
              </div>
            </div>

            <div class="row">
              <div class="ui-g-6">
                <span for="userName">Total cost</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{
                  viewJob.disposeLocationDetails.totalCost
                }}</span>
              </div>
            </div>
            <div class="row view-more more-margin">
              <div class="ui-g-6"></div>
              <div class="ui-g-6">
                <button
                  (click)="viewMore($event)"
                  ngbtooltip="btn-outline-primary"
                  class="outline-btns btn btn-outline-primary"
                >
                  View more
                </button>
                <!-- <a href="javascript:void(0)" (click)="viewMore($event)">View More</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
