<div class="">
  <div
    id="styleSelector"
    class="style-block vehicle-container open {{
      slide ? 'animate-block' : 'slide-out'
    }}"
  >
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="{{ isView ? 'col-12' : 'col-10' }} mx-auto">
          <div class="row add-edit-header" *ngIf="!isView">
            <div class="ui-g-10">
              <h4 *ngIf="!isUpdate">Create Vehicle</h4>
              <h4 *ngIf="isUpdate">Edit Vehicle</h4>
            </div>
            <div
              class="ui-g-2"
              (click)="closeEvent($event)"
              pTooltip="Click to close a Vehicle"
              tooltipPosition="top"
            >
              <i class="fa fa-window-close fa-2x"></i>
            </div>
          </div>
          <form [formGroup]="vehicleForm" *ngIf="!isView">
            <div class="row form-group">
              <div class="ui-g-12 mt-3">
                <label for="icon">Registration Number</label>
                <input
                  class="ui-g-12"
                  id="icon"
                  class="form-control"
                  type="text"
                  size="30"
                  (ngModelChange)="onChange($event)"
                  formControlName="vehicle_reg_number"
                  pInputText
                />
              </div>
            </div>
            <div class="row form-group">
              <div class="ui-g-12 mt-3">
                <label for="vehicle_type">Vehicle Type</label>
                <p-dropdown
                  class="ui-g-12 form-control"
                  [options]="vehicleTypeList"
                  [style]="{ width: '100%' }"
                  formControlName="vehicle_type"
                ></p-dropdown>
              </div>
            </div>
            <div class="row form-group" *ngIf="isAcumen == 'true'">
              <div class="ui-g-12 mt-3">
                <label for="linked_type"> Parent Company</label>
                <p-dropdown
                  class="ui-g-12 form-control"
                  [options]="companyList"
                  [style]="{ width: '100%' }"
                  formControlName="company"
                ></p-dropdown>
              </div>
            </div>

            <!-- <div class='row form-group' >
                <div class='ui-g-12 checkbox mt-3'>
                    <div  class="custom-control custom-switch">
                      <input  formControlName="isActive" type="checkbox" id="customswitch1" class="custom-control-input">
                      <label  for="customswitch1" class="custom-control-label">Is active</label></div>
                </div>
            
          </div> -->

            <div class="row button-cls" *ngIf="!(isView || isUpdate)">
              <div class="ui-g-12">
                <p-button
                  label="Create"
                  [disabled]="!vehicleForm.valid"
                  (onClick)="createVehicle($event)"
                ></p-button>
              </div>
            </div>
            <div class="row button-cls" *ngIf="isUpdate">
              <div class="ui-g-12">
                <p-button
                  label="Update"
                  [disabled]="!vehicleForm.valid"
                  (onClick)="updateVehicle($event)"
                ></p-button>
              </div>
            </div>
          </form>
          <div *ngIf="isView">
            <div class="row details-header">
              <div class="ui-g-10">
                <h4>View Vehicle Details</h4>
              </div>
              <div
                class="ui-g-1"
                (click)="editEvent($event)"
                pTooltip="Click to edit a Vehicle"
                tooltipPosition="top"
                *ngIf="checkPermissions('edit', 'Vehicles')"
              >
                <i class="fa fa-edit fa-2x"></i>
              </div>
              <div
                class="ui-g-1"
                (click)="closeEvent($event)"
                pTooltip="Click to close a Vehicle"
                tooltipPosition="top"
              >
                <i class="fa fa-window-close fa-2x"></i>
              </div>
            </div>
            <div class="details-content">
              <div class="row">
                <div class="ui-g-6">
                  <span for="userName">Registration Number</span>
                </div>
                <div class="ui-g-6">
                  <span for="userName">{{
                    vehicleData.vehicle_reg_number
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="ui-g-6">
                  <span for="userName">Vehicle Type </span>
                </div>
                <div class="ui-g-6">
                  <span for="userName">{{
                    vehicleData.vehicle_type[0].name
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="ui-g-6">
                  <span for="userName">Parent Company</span>
                </div>
                <div class="ui-g-6">
                  <span for="userName">{{
                    vehicleData.company[0].companyName
                  }}</span>
                </div>
              </div>
              <!-- <div class='row'>
              <div class="ui-g-6">
                <span for="userName">Is active</span>
              </div>
              <div class="ui-g-6">
                <span for="userName">{{vehicleData.isActive}}</span>
              </div>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
