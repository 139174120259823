import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { VehicleTypesService } from "../vehicle-types.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { JobService } from "../../job/job-data.service";
@Component({
  selector: "app-add-edit-vehicle-types",
  templateUrl: "./add-edit-vehicle-types.component.html",
  styleUrls: ["./add-edit-vehicle-types.component.scss"],
})
export class AddEditVehicleTypesComponent implements OnInit {
  userForm: FormGroup;
  vehicleTypeForm: FormGroup;
  editForm: FormGroup;
  isUpdate = false;
  slide = true;
  isEdit = false;
  isAcumen: any;
  companyID: any;
  companyList = [{ label: "Please Select", value: "" }];
  data: any = [];
  @Input("data") viewData: any = {};
  @Input("isView") isView = false;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private service: VehicleTypesService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private jobService: JobService
  ) {
    this.vehicleTypeForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      ppm: new FormControl("", [Validators.required]),
      co2: new FormControl("", [Validators.required]),
    });
  }

  ngAfterViewInit() {
    console.log("before ngafterviewinit", this.vehicleTypeForm);

    console.log("ngafterviewinit", this.vehicleTypeForm);
  }
  editEvent(event) {
    this.isEdit = true;
    this.isUpdate = true;
    this.isView = false;
    this.loadData();
  }

  createVehicleType(e) {
    let value: any = "";
    console.log("Value", this.vehicleTypeForm.value);
    value = this.vehicleTypeForm.value;
    //  value.username = this.studentForm.controls['student_number'].value;
    //  value.college_id = localStorage.getItem('college_id');
    //  if (value.email === value.cemail) {

    this.service.createVehicleType(value).subscribe(
      (data) => {
        if (data) {
          this.messageService.add({
            severity: "success",
            summary: "Create Vehicle Type",
            detail: "New Vehicle Type created successfully",
          });
          // this.ref.close(this);
          // this.closeEvents.emit(true);
        } else if (data.status === 500) {
          this.messageService.add({
            severity: "error",
            summary: "Create Vehicle Type",
            detail: data.error.message.errmsg,
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Create Vehicle Type",
            detail: "Unable to add Vehicle Type.Please try again.",
            //  detail: 'Failed to create the User'
          });
        }
        this.closeEvent(true);
      },
      //  });
      (err) => {
        console.log("Error", err);
        this.messageService.add({
          severity: "error",
          summary: "Create Vehicle Type",
          // detail: 'Failed to create the Waste types'
          detail: "Unable to add User.Please try again.",
        });
        // this.dialogService.closeAll();
      }
    );
    // } else {
    //     this.messageService.add({
    //         severity: 'failure',
    //         summary: 'Create Student',
    //         detail: 'Student Email and confirm email are not matching'
    //     });
    // }
  }
  updateVehicleType(event: any) {
    // console.log('update student', value.email, value.cemail);
    let value: any = "";
    value = this.vehicleTypeForm.value;
    value._id = this.data._id;
    console.log("Update value", this.vehicleTypeForm.value, value);

    this.service.updateVehicleType(value).subscribe((data) => {
      console.log(data);

      if (data.status === 200) {
        this.messageService.add({
          severity: "success",
          summary: "Update Vehicle Type",
          detail: "Vehicle Type details updated successfully",
        });
        // this.ref.close(this);
        if (this.isUpdate == true) {
          this.service
            .getVehicleTypeDetails(this.data._id)
            .subscribe((response) => {
              console.log("user  details", response);
              this.viewData = response[0];
              this.closeEvent(true);
            });
        } else {
          // this.closeEvents.emit(true);
          this.closeEvent(true);
        }
      } else if (data.status === 500) {
        this.messageService.add({
          severity: "error",
          summary: "Update Vehicle Type",
          detail: data.error.message.errmsg,
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Update Vehicle Type",
          detail: "Unable to update Vehicle Type.Please try again.",
          //  detail: 'Failed to update the user details'
        });
      }
    });
    // } else {
    //     this.messageService.add({
    //         severity: 'failure',
    //         summary: 'Create Student',
    //         detail: 'Student Email and confirm email are not matching'
    //     });
    // }
  }
  ngOnInit() {
    console.log("this.config", this.config);
    //   var element = document.getElementById("styleSelector");
    //                 element.classList.remove("slide-out");
    //                 element.classList.add("animate-block");
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");
    this.loadData();
  }
  loadData() {
    if (this.viewData && Object.keys(this.viewData).length !== 0) {
      console.log(" true vehicleData", this.viewData);
      this.data = this.viewData;

      this.isUpdate = true;
      if (this.isUpdate) {
        this.vehicleTypeForm.controls["name"].setValue(this.data.name);
        this.vehicleTypeForm.controls["ppm"].setValue(this.data.ppm);
        this.vehicleTypeForm.controls["co2"].setValue(this.data.co2);
      }
    }
  }
  closeEvent(event) {
    if (this.isEdit == false) {
      //     var element = document.getElementById("styleSelector");
      //   element.classList.remove("animate-block");
      //     element.classList.add("slide-out");
      this.slide = false;
      this.cdr.detectChanges();
      setTimeout((e) => {
        this.closeEvents.emit(true);
      }, 1000);
    } else {
      this.isEdit = false;
      this.isView = true;
      this.isUpdate = false;
      this.loadData();
    }
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
