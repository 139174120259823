<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card cardClass="table-card list-category" blockClass="p-0">
      <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->
      <div class="row">
        <div class="ui-g-11">
          <h1 *ngIf="data">EWC codes for the site : {{ data.Name }}</h1>
        </div>
        <div class="ui-g-1">
          <div
            (click)="closeEvent($event)"
            pTooltip="Click to close a EWC code"
            tooltipPosition="top"
          >
            <i class="fa fa-window-close fa-2x"></i>
          </div>
        </div>
      </div>

      <p-table
        #dt
        [value]="categoryList"
        [(selection)]="selectedCatgeory"
        dataKey="_id"
        styleClass="ui-table-customers"
        [rowHover]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [globalFilterFields]="searchArr"
      >
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search"
            />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>

            <!-- <th>Edit</th> -->
            <!-- <th>View</th>
                        <th>Delete</th> -->
            <th *ngIf="checkPermissions('edit', 'Disposal Sites')">
              <span
                (click)="addCategory($event)"
                class="fa-plusBtn"
                pTooltip="Click to add a EWC code"
                tooltipPosition="top"
              >
                <i class="fa fa-plus-circle fa-4x"></i>
              </span>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
              <input
                pInputText
                type="text"
                class="ui-inputtext"
                (input)="dt.filter($event.target.value, col.field, 'contains')"
              />
            </th>
            <!-- <th></th> -->
            <!-- <th></th> -->
            <th *ngIf="checkPermissions('edit', 'Disposal Sites')"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of cols">
              {{ car[col.field] }}
            </td>

            <!-- <td>
                          <span (click)='editSubject($event,car)'>
                            <i class='fa fa-pencil fa-2x'></i>
                          </span>
                        </td> -->
            <td *ngIf="checkPermissions('edit', 'Disposal Sites')">
              <!-- <span (click)='viewVehicleType($event,car)' class="mob_icon style-toggler">
                            <i class='fa fa-eye fa-2x'></i>
                          </span> -->
              <span
                (click)="editCategory($event, car)"
                pTooltip="Click to edit a EWC code"
                tooltipPosition="top"
                class="mob_icon style-toggler delete_icon"
              >
                <i class="fa fa-edit fa-2x"></i>
              </span>
              <p-button
                label="Remove"
                class="mob_icon style-toggler delete_icon"
                pTooltip="Click to remove a EWC code"
                tooltipPosition="top"
                (onClick)="removeCatgeory($event, car)"
              ></p-button>

              <!-- <span (click)='deleteVehicleType(car._id)'  class="style-toggler mob_icon delete_icon">
                            <i class='fa fa-trash fa-2x'></i>
                          </span> -->
              <!-- <span *ngIf="!car.delete">
                              -
                            </span> -->
            </td>
          </tr>
        </ng-template>
        <ng-template
          class="noRecords"
          *ngIf="categoryList.length == 0"
          pTemplate="emptymessage"
        >
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length + 1">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>
    </app-card>
  </div>
  <!-- tabs card end -->
</div>

<div *ngIf="showAdd">
  <app-add-edit-ewc-code
    [category]="category"
    [isUpdate]="isUpdate"
    [selectSubCategory]="selectSubCategory"
    [title]="title"
    [parentWasteType]="parentWasteType"
    [disposalData]="data"
    [id]="id"
    (closeEvents)="callList($event)"
  ></app-add-edit-ewc-code>
</div>
