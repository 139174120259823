import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DisposalSitesService } from "../../disposal-sites/disposal-sites.service";
import { AdminUserService } from "../admin-users.service";
import * as _ from "underscore";
@Component({
  selector: "app-user-permission",
  templateUrl: "./user-permission.component.html",
  styleUrls: ["./user-permission.component.scss"],
})
export class UserPermissionComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen: any;
  companyID: any;
  showAdd = false;
  showLoader = true;
  data: any;
  selectedCatgeory: any;
  category: any;
  selectSubCategory = false;
  title: any;
  parentWasteType: any;
  wasteTypeData: any;
  ide: any;
  categoryList = [];
  cols = [
    {
      header: "Modules",
      field: "moduleName",
    },
  ];
  searchArr = [];
  vehicleTypeData: any;
  adminUserList = [];
  selectedVehicle: any;
  vehicleTypeList = [];
  id: any;
  disposalData: any;
  moduleList: any;
  userAccessModule: any;
  userDetails: any;
  tempArray: any;
  constructor(
    private service: DisposalSitesService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private router: Router,
    private adminUserService: AdminUserService
  ) {}

  closeEvent(event) {
    if (!this.checkIfDataChanged()) {
      return this.confirmExit();
    }
    return history.back();
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.fetchModules();
    this.getUserDetails(true);
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
  }
  fetchModules() {
    this.service.getModules().subscribe(
      (data: any) => {
        console.log("parent data", data);
        this.moduleList = data.data;
        this.showLoader = false;
        this.data = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  saveUsersAccess() {
    console.log("save");
    let payload = {
      _id: this.userDetails?._id,
      accessModules: this.userAccessModule,
    };
    this.adminUserService.updateAdminUserAccessModule(payload).subscribe(
      (result) => {
        console.log(result);
        this.getUserDetails();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getUserDetails(firstLoad?: any) {
    this.adminUserService.getDetails(this.id).subscribe(
      (result: any) => {
        console.log(result);
        this.userDetails = result.data[0];
        this.userAccessModule = result.data[0].accessModules;
        localStorage.setItem(
          "tempAccessModule",
          JSON.stringify(this.userAccessModule)
        );
        if (!firstLoad) {
          this.messageService.add({
            severity: "success",
            summary: "User Access modified",
          });
          history.back();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkForAccessRole(item, type) {
    let tempAccessModule = JSON.parse(localStorage.getItem("tempAccessModule"));
    if (tempAccessModule?.length) {
      const data = tempAccessModule.filter(
        (filterData) => filterData && filterData._id === item._id
      );
      switch (type) {
        case "read":
          if (data[0] && data[0].objectArray.read) {
            return true;
          }
          return false;
        case "create":
          if (data[0] && data[0].objectArray.create) {
            return true;
          }
          return false;
        case "edit":
          if (data[0] && data[0].objectArray.edit) {
            return true;
          }
          return false;
        case "delete":
          if (data[0] && data[0].objectArray.delete) {
            return true;
          }
          return false;
        case "superAdmin":
          if (data[0] && data[0].objectArray.superAdmin) {
            return true;
          }
          return false;
      }
    }
  }

  changePermission(event, data, type) {
    console.log(event?.target?.checked);
    this.userAccessModule.map((item) => {
      if (item._id === data._id) {
        switch (type) {
          case "read":
            item.objectArray.read = event?.target?.checked;
            break;
          case "create":
            item.objectArray.create = event?.target?.checked;
            break;
          case "edit":
            item.objectArray.edit = event?.target?.checked;
            break;
          case "delete":
            item.objectArray.delete = event?.target?.checked;
            break;
          case "superAdmin":
            item.objectArray.superAdmin = event?.target?.checked;
            break;
        }
      }
    });

    console.log(
      "----------this.userAccessModule------------",
      this.userAccessModule
    );

    console.log("----------this.tempArray------------", this.tempArray);
  }

  checkIfDataChanged() {
    if (
      JSON.stringify(this.userAccessModule) ===
      localStorage.getItem("tempAccessModule")
    ) {
      return true;
    }

    return false;
  }

  confirmExit() {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to exit wihout saving changes?",
      accept: () => {
        history.back();
      },
    });
  }
}
