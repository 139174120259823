import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { VehicleTypesService } from "./vehicle-types.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { JobService } from "../job/job-data.service";
@Component({
  selector: "app-vehicle-types",
  templateUrl: "./vehicle-types.component.html",
  styleUrls: ["./vehicle-types.component.scss"],
})
export class VehicleTypesComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen: any;
  companyID: any;
  showAdd = false;
  cols = [
    {
      header: "Name",
      field: "name",
    },
    {
      header: "PPM",
      field: "ppm",
    },
    {
      header: "Co2 Emission rate",
      field: "co2",
    },
  ];
  vehicleTypeData: any;
  adminUserList = [];
  showLoader = true;
  selectedVehicle: any;
  vehicleTypeList = [];
  searchArr = [];
  constructor(
    private service: VehicleTypesService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService
  ) {}

  ngOnInit() {
    this.clearIndex();
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");

    this.fetchVehicleTypeList();
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
  }
  clearIndex() {
    localStorage.removeItem("jobID");
    localStorage.removeItem("pageJobIndex");
    localStorage.removeItem("pageDisposalIndex");
    localStorage.removeItem("pageWasteIndex");
    localStorage.removeItem("pageReportIndex");
    localStorage.removeItem("pageInspectionReportIndex");
  }
  callList(event) {
    console.log("call list");
    this.showAdd = false;
    this.isView = false;
    this.vehicleTypeData = {};
    this.cdr.detectChanges();
    this.fetchVehicleTypeList();
  }
  createVehicleType(event) {
    console.log("create staff called");

    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.vehicleTypeData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
    }
    this.cdr.detectChanges();
  }
  editVehicleType(event, data) {
    this.service.getVehicleTypeDetails(data._id).subscribe((response) => {
      console.log("user  details", response);
      this.vehicleTypeData = response[0];
      this.showAdd = true;
      console.log("user  details", this.vehicleTypeData);

      this.cdr.detectChanges();
      // editData = response.data[0];
      // this.showLoader = false;
      // const ref = this.dialogService.open(AddEditStaffComponent, {
      //     data: {
      //         viewData: editData,
      //         isUpdate: true
      //     },
      //     header: 'Edit Staff Details',
      //     width: '600px',
      // });

      // ref.onClose.subscribe((car) => {
      //     if (car) {
      //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
      //      this.fetchVehicleTypeList();
      //     }
      // });
    });
  }
  viewVehicleType(event, data) {
    this.service.getVehicleTypeDetails(data._id).subscribe((response) => {
      console.log("user  details", response);
      this.vehicleTypeData = response[0];
      this.isView = true;
      this.showAdd = true;
      console.log("user  details", this.vehicleTypeData);

      this.cdr.detectChanges();
    });
  }
  deleteVehicleType(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Vehicle Type?",
      accept: () => {
        this.service.deleteVehicleType(id).subscribe(
          (data: any) => {
            if (data) {
              this.messageService.add({
                severity: "success",
                summary: "Delete Vehicle Type",
                detail: "Vehicle Type details deleted successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Delete Vehicle Type",
                detail: data.error.message,
              });
            }
            this.fetchVehicleTypeList();
          },
          (err) => {
            console.log("error", err);
            this.messageService.add({
              severity: "error",
              summary: "Delete Vehicle Type",
              detail: "Failed to delete the Vehicle Type",
            });
            this.fetchVehicleTypeList();
          }
        );
        //  });
      },
    });
  }
  fetchVehicleTypeList() {
    const staffArr = [];
    this.service.vehicleTypeList().subscribe((response) => {
      console.log("staff  details", response);

      // response.data.forEach((ele) => {
      //     ele.isActive = ele.active ? 'Yes' : 'No';
      // });
      // if (response.data.length > 0){
      //   response.data.forEach(element => {
      //     console.log('element',element,this.companyID);
      //     if (this.isAcumen == 'true' || this.isAcumen == true){
      //       staffArr.push(element);
      //     } else{
      //       if(element.company.companyId == this.companyID){
      //         staffArr.push(element);
      //       }
      //     }
      //   });
      // }
      this.vehicleTypeList = response;

      this.showLoader = false;
    });
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
