<div [ngClass]="visibility ? '' : 'hideScreen' ">
  <div
    id="styleSelector"
    class="style-block staff-container  open {{
      slide ? 'animate-block' : 'slide-out'
    }}"
  >
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="{{ isView ? 'col-12' : 'col-10' }} mx-auto user-form ">
          <div class="row add-edit-header" *ngIf="!isView">
            <div class="ui-g-10">
              <h4 *ngIf="!isUpdate">Create a new section</h4>
              <h4 *ngIf="isUpdate">Edit Section</h4>
            </div>
            <div
              class="ui-g-2"
              (click)="closeEvent()"
              pTooltip=""
              tooltipPosition="top"
            >
              <i class="fa fa-window-close fa-2x"></i>
            </div>
          </div>

          <div class="">
            <form [formGroup]="sectionsForm" *ngIf="!isView">
              <div class="row form-group">
                <div class="ui-g-12 mt-3">
                  <label for="firstname">Section Name</label>
                  <input
                    class="ui-g-12"
                    id="firstname"
                    class="form-control"
                    type="text"
                    size="30"
                    formControlName="sectionName"
                    pInputText
                  />
                </div>
              </div>
              <div class="row form-group">
                <div class="ui-g-12 mt-3">
                  <label for="lastname">Allocate Questions</label>
                  <p-dropdown
                    class="ui-g-12 dropdown"
                    placeholder="Search Question"
                    [options]="questionTypeList"
                    optionLabel="itemName"
                    [style]="{ width: '100%' }"
                    [filter]="true"
                    [filterBy]="'label'"
                    (onChange)="onQnsSelect($event)"
                  >
                  </p-dropdown>
                  <div *ngIf="selectedQuestions?.length">
                    <app-sort-drag-list
                      [isIndexBindingRequired]="true"
                      [_dataToShow]="selectedQuestions"
                      [dataKeyNameForLabel]="'itemName'"
                      [isDeleteRequired]="true"
                      (drop)="onDrop($event)"
                    >
                    </app-sort-drag-list>
                  </div>
                  <!-- <div *ngFor="let ans of selectedQuestions; let i = index">
                    <div class="padding-5px border">
                      {{ i + 1 + " .  " + ans?.itemName }}
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="row form-group">
                <div class="ui-g-4 mt-3">
                  <label for="activeCheckbox">Active</label>
                  <p-checkbox
                    name="activeCheckbox"
                    id="activeCheckbox"
                    ngDefaultControl
                    formControlName="active"
                    class="ui-g-4 map-checkbox text-allign-btns"
                    binary="false"
                  ></p-checkbox>
                </div>
                <!-- <div class="ui-g-4 mt-3">
                  <label for="adminOnly">Admin Only</label>
                  <p-checkbox
                    name="adminOnly"
                    id="adminOnly"
                    ngDefaultControl
                    formControlName="adminOnly"
                    class="ui-g-4 map-checkbox text-allign-btns"
                    binary="false"
                  ></p-checkbox>
                </div>

                <div class="ui-g-4 mt-3">
                  <label for="visbleOnly">Visible Mobile</label>
                  <p-checkbox
                    name="visbleOnly"
                    id="visbleOnly"
                    ngDefaultControl
                    formControlName="visibleMobile"
                    class="ui-g-4 map-checkbox text-allign-btns"
                    binary="false"
                  ></p-checkbox>
                </div> -->
              </div>

              <!-- <div class='row form-group' *ngIf='!isUpdate'> -->
              <!-- <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="answers">Question Answers</label>
                  <button type="button" class="btn btn-primary mb-2" style="float: right;" (click)='createAnswer($event)'
                    pTooltip="Click to add an answer" tooltipPosition="top">
                    <i class="feather icon-plus-circle"></i>ADD</button>

                  <div dragula='question-items' [(dragulaModel)]="sectionsForm.get('questionAnswers')['controls']">
                    <ng-container formArrayName="questionAnswers"
                      *ngFor="let answer of sectionsForm.get('questionAnswers')['controls']; let i=index">
                      <div [id]="i" [formGroupName]="i">
                        <input id="answers" type="text" class="ui-g-11 form-control" (change)="onChange($event)"
                          autocomplete="new-password" size="30" formControlName='answer' pInputText>
                        <i class="fa fa-trash fa-2x ui-g-1" (click)="deleteAns(i)"></i>
                      </div>
                    </ng-container>
                  </div>
                </div>


                </div> -->
              <!-- <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="cpassword">Confirm Password</label>
                  <input class='ui-g-12' id="cpassword" type="password" (change)="onChange($event)" class="form-control" size="30"
                    formControlName='cpassword' pInputText>
                </div>
              </div> -->
              <!-- <span *ngIf="errorMessage" class="error-message">Password and Confirm Password does not match</span> -->
              <!-- <div class='row form-group' *ngIf="isAcumen == 'true'"> -->
              <!-- <div class='ui-g-12 ui-float-label mt-3'>
                  <input class='ui-g-12' id="companyassociatedto" type="text" size="30" formControlName='comapny_associated_to' pInputText>
                  <label for="companyassociatedto">Company Associated to</label>
                </div> -->
              <!-- <div class='ui-g-12  mt-3'>
                  <label for="linked_type">Company Associated to</label>
                  <p-dropdown class='ui-g-12 form-control' [options]="companyList" [style]="{'width':'100%'}"
                    formControlName='company'></p-dropdown>

                </div> -->
              <!-- </div> -->
              <!-- <div class='row form-group hide-block'>
                <div class='ui-g-12 checkbox mt-3'>
                  <div class="custom-control custom-switch">
                    <input formControlName="active" type="checkbox" id="customswitch1" class="custom-control-input">
                    <label for="customswitch1" class="custom-control-label">Active</label></div>
                </div>
              </div>-->
              <div class="row button-cls" *ngIf="!(isView || isUpdate)">
                <div class="ui-g-12">
                  <p-button
                    label="Create"
                    [disabled]="!sectionsForm.valid"
                    (onClick)="createQuestion($event)"
                  ></p-button>
                </div>
              </div>
              <div class="row button-cls" *ngIf="isUpdate">
                <div class="ui-g-12">
                  <p-button
                    label="Update"
                    [disabled]="!sectionsForm.valid"
                    (onClick)="updateQuestion($event)"
                  ></p-button>
                </div>
              </div>
            </form>
            <div *ngIf="isView">
              <div class="row details-header">
                <div class="ui-g-10">
                  <h4>Section Details</h4>
                </div>
                <!-- <div class="ui-g-1" (click)="editEvent($event)">
                  <i class="fa fa-edit fa-2x " pTooltip="Click to edit the Question" tooltipPosition="top"></i>
                </div> -->
                <div class="ui-g-1" (click)="closeEvent($event)">
                  <i
                    class="fa fa-window-close fa-2x"
                    pTooltip="Click to close the Question"
                    tooltipPosition="top"
                  ></i>
                </div>
              </div>
              <div class="details-content">
                <div class="row pt-2 pb-2">
                  <div class="ui-g-4 pl-0">
                    <span for="userName">Section Name :</span>
                  </div>
                  <div class="ui-g-8">
                    <span for="userName">{{ viewData.sectionTitle }}</span>
                  </div>
                </div>
                <div class="row">
                  <form
                    [formGroup]="sectionsForm"
                    *ngIf="isView"
                    style="width: 100%"
                  >
                    <div class="ui-g-12 allocate-section mt-5">
                      <label class="ml-2 bold">Allocated Questions</label>

                      <div>
                        <div
                          *ngFor="let ans of selectedQuestions; let i = index"
                        >
                          <div class="ui-g-12">
                            <p-checkbox
                              name="active"
                              (onChange)="checkBoxchange($event, i)"
                              class="ui-g-1"
                              binary="false"
                              *ngIf="
                                checkPermissions('edit', 'Form Sections') &&
                                !isView
                              "
                            ></p-checkbox>
                            <div class="ui-g-11">{{ ans?.itemName }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="ui-g-12"
                        *ngIf="
                          checkPermissions('edit', 'Form Sections') && !isView
                        "
                      >
                        <div class="ui-g-12" style="text-align: end">
                          <button
                            type="button"
                            class="btn btn-danger"
                            (click)="confirmDeleteQuestion()"
                          >
                            DELETE
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                class="ui-g-12 edit-btn"
                *ngIf="checkPermissions('edit', 'Form Sections')"
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="editEvent($event)"
                >
                  <i class="fa fa-edit fa-2x"></i>EDIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
