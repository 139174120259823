import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SortDragListComponent } from "./sort-drag-list.component";

@NgModule({
  declarations: [SortDragListComponent],
  imports: [CommonModule, DragDropModule],
  exports: [SortDragListComponent],
})
export class SortDragListModule {}
