<div
  class="example-list"
  cdkDropList
  [cdkDropListData]="dataToShow"
  class="example-list"
  (cdkDropListDropped)="dropItem($event)"
>
  <div
    class="example-box"
    cdkDrag
    *ngFor="let data of dataToShow; let i = index"
  >
    <div class="ui-g-12">
      <div class="ui-g-11 cursor-point">
        {{ isIndexBindingRequired ? i + 1 : "" }}
        {{ isIndexBindingRequired ? "." : "" }}
        {{ dataKeyNameForLabel ? data[dataKeyNameForLabel] : data }}
        <span
          class="float-right"
          *ngIf="isDeleteRequired"
          (click)="onDelete(i)"
        >
          <i class="fa fa-trash fa-2x"></i>
        </span>
      </div>
    </div>
  </div>
</div>
