<div class="row width-200">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card moduleName="Builders" module="Builder" (addForm)="showFormsPopup($event)" (jobReload)="reload($event)"
      cardClass="table-card" blockClass="p-0">
      <p-table #dt [value]="formsList" [(selection)]="selectedAdmin" dataKey="_id" styleClass="ui-table-customers"
        [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="searchArr">
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of cols" (click)="viewForm($event, car)">
              {{
              col?.type === "date"
              ? (car[col.field] | date: "dd-MM-yyyy")
              : car[col.field]?.formType
              ? car[col.field]?.formType
              : car[col.field]
              }}
            </td>
            <td>
              <span (click)="navigateToAdminAccess(car)">
                <a href="javascript:void(0);"> Admin</a>
              </span>
              |
              <span (click)="navigateToUsersAccess(car)">
                <a href="javascript:void(0);"> User</a>
              </span>
            </td>
            <td>
              <span (click)="viewForm($event, car)" pTooltip="Click to view form" tooltipPosition="top"
                class="mob_icon style-toggler">
                <i class="fa fa-eye fa-2x"></i>
              </span>
              <span (click)="editForm($event, car)" pTooltip="Click to edit form" tooltipPosition="top"
                class="mob_icon style-toggler delete_icon" *ngIf="checkPermissions('edit', 'Form Builder')">
                <i class="fa fa-edit fa-2x"></i>
              </span>
              <span (click)="duplicateForm($event, car)" pTooltip="Click to duplicate form" tooltipPosition="top"
                class="mob_icon style-toggler delete_icon" *ngIf="checkPermissions('edit', 'Form Builder')">
                <i class="fa fa-clone fa-2x"></i>
              </span>
              <span (click)="deleteForm(car._id)" pTooltip="Click to delete a Form" tooltipPosition="top"
                class="style-toggler mob_icon delete_icon" *ngIf="checkPermissions('delete', 'Form Builder')">
                <i class="fa fa-trash fa-2x"></i>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template class="noRecords" *ngIf="formsList.length == 0" pTemplate="emptymessage">
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length + 2">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>
    </app-card>
  </div>
  <!-- tabs card end -->
</div>

<p-dialog [(visible)]="showAdd" [style]="{ width: '100vw', height: '100vh' }" [baseZIndex]="1000" [closable]="false">
  <app-add-edit-view-form-builders (openAddQues)="openAddQues($event)" (openAddSection)="openAddSection($event)"
    [isView]="isView" [data]="formData" [isNew]="isNew" [edit]="isEdit" [sectionList]="sectionList"
    [questionsList]="questionsList" (closeEvents)="closeEvents($event)" *ngIf="showAdd">
  </app-add-edit-view-form-builders>
</p-dialog>

<div *ngIf="showAddSection">
  <app-add-edit-view-form-sections (closeEvents)="closeAddSection($event)">
  </app-add-edit-view-form-sections>
</div>

<div *ngIf="showAddQues">
  <app-add-edit-view-form-questions [isNew]="true" (closeEvents)="closeAddQues($event)">
  </app-add-edit-view-form-questions>
</div>