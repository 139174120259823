<li [class.dropdown-list-item]="!disabled" [class.dropdown-list-item-disabled]="disabled">
    <!-- <ng-container *ngIf="viewMode=='default'; else elseBlock">
      <ng-container *ngIf="inSelection==false; else elseBlock2">
        <span>{{ data.display_value }}</span>
        <span *ngIf="!disabled" class="clickable-text" (click)="toggleAdded()">{{ 'Select' }}</span>
      </ng-container>
      <ng-template #elseBlock2>
        <span>{{ data.display_value }}</span>
        <span *ngIf="!disabled" class="clickable-text" (click)="toggleAdded()">{{ 'Remove' }}</span>
      </ng-template>
    </ng-container>
    <ng-template #elseBlock>
      <ng-container *ngIf="inSelection==true; else elseBlock3">
        <span>{{ data.display_value }}</span>
        <span *ngIf="!disabled" class="clickable-text" (click)="toggleAdded()">{{ 'Remove' }}</span>
      </ng-container>
      <ng-template #elseBlock3>
        <span>{{ data.display_value }}</span>
        <span *ngIf="!disabled" class="clickable-text" (click)="toggleAdded()">{{ 'Select' }}</span>
      </ng-template>
    </ng-template> -->
    <ng-container>
      <span [innerHtml]="highlightSubstring()"></span>
      <span><p-checkbox  class="map-checkbox text-allign-btns cursor-pointer" ngDefaultControl [(ngModel)]="inSelection" binary="true" [disabled]="disabled" (onChange)="toggleAdded()"></p-checkbox></span>
    </ng-container>
  </li>
