<div id="styleSelector"  class="style-block job-container  open {{slide?'animate-block':'slide-out'}}">

        <div class="container h-100">
                <div class="row align-items-center " >
                  <div class="col-10 mx-auto">
                <form [formGroup]="priceForm">
                                <div class='row add-edit-header'  >  
                                                <div class="ui-g-10">
                                                    <h4 *ngIf="!isUpdate">Add Price</h4>
                                                    <h4 *ngIf="isUpdate">Edit Price</h4>
                                                </div> 
                                                <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Price" tooltipPosition="top">
                                                    <i class="fa fa-window-close fa-2x "></i>
                                                    </div>    
                                                        </div> 
                <div class="row  header-label"><h5>Waste</h5>  </div>      

                <div class='row form-group'>
                                <div class='ui-g-12  mt-3'>
                                  <label for="icon"> Waste Category</label>
                                  <p-dropdown class='ui-g-12 form-control' [options]="wasteTypeList" [style]="{'width':'100%'}" formControlName='waste_type'></p-dropdown>
                                </div>
                              </div>
                              <div class='row form-group'>
                                <div class='ui-g-12  mt-3'>
                                                <label for="icon"> Min tonnage</label>
                                  <input class='ui-g-12 form-control' id="icon" type="number" step="0.25" (change)="setTwoNumberDecimal($event,'min_tonnage')"   formControlName='min_tonnage' pInputText>
                                </div>
                              </div>
                              
                              <div class='row form-group'>
                                <div class='ui-g-12  mt-3'>
                                                <label for="icon"> Cost per tonne</label>
                                  <input class='ui-g-12 form-control' id="icon" type="number" step="0.25" (change)="setTwoNumberDecimal($event,'cost_per_tonne')"   formControlName='cost_per_tonne' pInputText>
                                </div>
                              </div>

                              <div class="row header-label hide-block" ><h5>Metal</h5>  </div>

                              <div class='row  form-group hide-block'>
                                        <div class='ui-g-12  mt-3'>
                                                        <label for="icon"> Metal tonnage</label>
                                          <input class='ui-g-12 form-control' id="icon" type="number" size="30" formControlName='metal_tonnage' (change)="setTwoNumberDecimal($event,'metal_tonnage')"  pInputText>
                                        </div>
                                      </div>
                                    <div class='row form-group hide-block'>
                                        <div class='ui-g-12  mt-3'>
                                                        <label for="icon"> Metal rebate</label>
                                          <input class='ui-g-12 form-control' id="icon" type="number" size="30" formControlName='metal_rebate' (change)="setTwoNumberDecimal($event,'metal_rebate')"  pInputText>
                                        </div>
                                      </div>

                                      <div class='row button-cls margin-bottom' *ngIf='!(isView || isUpdate)'>
                                                <div class='ui-g-12'>
                                                  <p-button label="Save"  [disabled]='!priceForm.valid' (onClick)="createPrice($event)"></p-button>
                                      
                                                </div>
                                              </div>
                                              <div class='row button-cls margin-bottom' *ngIf='isUpdate'>
                                                <div class='ui-g-12'>
                                                  <p-button label="Update"  [disabled]='!priceForm.valid' (onClick)="updatePrice($event)"></p-button>
                                      
                                                </div>
                                              </div>
</form>
</div>
</div>
</div>
</div>