<div class="">
    <div id="styleSelector" class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
        <div class="container h-100">
            <div class="row align-items-center">
                <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
                    <div class='row add-edit-header' *ngIf="!isView">
                        <div class="ui-g-10">
                            <h4 *ngIf='!isUpdate'>Export Staff</h4>
                        </div>
                    </div>
                    <div class="">

                        <form>

                            <div class='row add-edit-header'>
                                <div class="ui-g-10">
                                    <!-- <h4>TEST TITLE</h4> -->
                                </div>

                                <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Export"
                                    tooltipPosition="top">
                                    <i class="fa fa-window-close fa-2x "></i>
                                </div>
                            </div>

                            <div class="checkbox-container">
                                <input id="selectall" type="checkbox" class="checkbox" value="selectall"
                                    [(ngModel)]="selected" (change)="onChange($event, null)"
                                    [ngModelOptions]="{standalone: true}" />
                                <label for="selectall" class="checkboxLabel">Select all</label>
                            </div>

                            <div class=" checkbox-container" *ngFor="let item of companys; let i=index">
                                <input id="{{item._id}}" type="checkbox" class="checkbox" value="{{item.ewc_code}}"
                                    [(ngModel)]="item.checked" (change)="onChange($event, item)"
                                    [ngModelOptions]="{standalone: true}" />
                                <label for="{{item._id}}" class="checkboxLabel">{{item.companyName}}</label>
                                <ng-container *ngIf="item.checked">
                                    <div class="sub-sub-category" *ngFor="let j of item.departments">
                                        <input id="{{j.deptId}}" type="checkbox" class="checkbox" value="{{j.deptId}}"
                                            [(ngModel)]="j.checked" [ngModelOptions]="{standalone: true}"
                                            (change)="onChange($event, j)" />
                                        <label for="{{j.deptId}}" class="checkboxLabel">{{j.departmentName}}</label>
                                    </div>
                                </ng-container>
                            </div>


                        </form>

                        <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                            <div class='ui-g-12'>
                                <div class="text-center m-t-20">
                                    <button class="btn btn-primary" (click)="exportStaffData($event)">Export</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner template="<img src='assets/images/loader.gif' />">
    <p style="font-size: 20px;font-size: 20px;
  color: white;
  position: relative;
  top: 22vh;">Importing...</p>
</ngx-spinner>