<div class="row">
    <div class="col-sm-12">
        <app-card cardClass="table-card view-job-details" blockClass="p-0">
            <div class="row">
                <div class="ui-g-11">
                    <h1 *ngIf="!showLoader" class="parent">{{enumTitle()}}</h1>
                </div>
                <div class="ui-g-1">
                    <div (click)="closeEvent($event)" pTooltip="Click to close Message Window" tooltipPosition="top">
                        <i class="fa fa-window-close fa-2x"></i>
                    </div>
                </div>
            </div>
            <div class="row view-job-tab">
                <div class="ui-g-12">
                    <p-tabView>
                        <p-tabPanel header="Message Details">
                            <div class="row">
                                <div class="ui-g-6">
                                    <span>Last Modified at: </span>
                                </div>
                                <div *ngIf="!showLoader" class="ui-g-6">
                                    <span>{{ formatDate(data.modified_at) }}</span>
                                </div>
                            </div>
                            <div *ngIf="!showLoader" class="row">
                                <div class="ui-g-6">
                                    <span>Created at: </span>
                                </div>
                                <div class="ui-g-6">
                                    <span>{{ formatDate(data.created_at) }}</span>
                                </div>
                            </div>
                            <div *ngIf="isScheduled() && !showLoader" class="row">
                                <div class="ui-g-6">
                                    <span>Scheduled to be sent at: </span>
                                </div>
                                <div class="ui-g-6">
                                    <span>{{ formatDate(data.date_of_dispatch) }}</span>
                                </div>
                            </div>
                            <div *ngIf="isSent() && !showLoader" class="row">
                                <div class="ui-g-6">
                                    <span>Sent at: </span>
                                </div>
                                <div class="ui-g-6">
                                    <span>{{ formatDate(data.date_of_dispatch) }}</span>
                                </div>
                            </div>
                            <form [formGroup]="messageForm">
                                <div class="row">
                                    <div class="ui-g-12 mt-3 view-message">
                                        <p-editor
                                            formControlName="message"
                                            [style]="{ height: '260px' }"
                                            [readonly]="true"
                                            p-editor-toolbar ="{background-color: 'white'}"
                                        >
                                        </p-editor>
                                    </div>
                                </div>
                                <div class="row" *ngIf="checkPermissions('edit', 'Messaging')">
                                    <div class="ui-g-12">
                                        <p-button
                                          label="Edit Message"
                                          [disabled]="!canEdit()"
                                          (click)="openEditor()"
                                        ></p-button>
                                    </div>
                                </div>
                            </form>
                        </p-tabPanel>
                        <p-tabPanel header="Recipients">
                            <div class="row">
                                <div class="col-12">
                                    <p-table #dt [value]="recipientsList" [(selection)]="selectedRecipient" dataKey="email"
                                    styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                                    [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                                    [globalFilterFields]="searchArr">
                                    
                                    <ng-template pTemplate="caption">
                                        <div class="ui-table-globalfilter-container">
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Global Search" />
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                    <tr>
                                      <th *ngFor="let col of cols" [pSortableColumn]="col.field">
                                        <b>{{ col.header }}</b>
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th *ngFor="let col of cols" [ngSwitch]="col.field">
                                        <input pInputText type="text" class="ui-inputtext"
                                          (input)="dt.filter($event.target.value, col.field, 'contains')" />
                                      </th>
                                    
                                    </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-car>
                                    <tr *ngIf="!showLoader">
                                      <td *ngFor="let col of cols" style="cursor: default;">
                                        {{ car[col.field] }}
                                      </td>
                                    </tr>
                                  </ng-template>
                                  <ng-template class="noRecords" *ngIf="messageList.length == 0" pTemplate="emptymessage">
                                    <tr class="noRecords" *ngIf="!showLoader">
                                      <td [attr.colspan]="cols.length + 1">
                                        <!-- <div class="my-center-text"> -->
                                        No Records to display
                                        <!-- </div> -->
                                      </td>
                                    </tr>
                                  </ng-template>
                                    </p-table>
                                </div>
                                <div class="loader" *ngIf="showLoader"></div>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </app-card>
    </div>
</div>
<p-dialog *ngIf="canEdit()" [(visible)]="showSend" [style]="{ width: '100vw', height: '100vh' }" [baseZIndex]="1000" [closable]="false">
    <app-send-edit-message (closeEvents)="closeEditor($event)" (openEvents)="openEditor()" [messageStatus]="status" [messageId]="messageId"></app-send-edit-message>
</p-dialog>