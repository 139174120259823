<div class="">
  <div
    id="styleSelector"
    class="style-block staff-container  open {{
      slide ? 'animate-block' : 'slide-out'
    }}"
  >
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="col-10 mx-auto user-form">
          <div class="row add-edit-header" *ngIf="!isView">
            <div class="ui-g-10">
              <h4>Messages</h4>
            </div>
            <div
              class="ui-g-2"
              (click)="closeEvent($event)"
              pTooltip="Click to close a Admin"
              tooltipPosition="top"
            >
              <i class="fa fa-window-close fa-2x"></i>
            </div>
          </div>

          <div *ngIf="!showLoader">
            <div class="row form-group">
              <div *ngIf="messageCollection" class="width-100 overflow">
                <div
                  class="ui-g-12 mt-3"
                  [id]="msg?._id"
                  *ngFor="let msg of messageCollection?.messages"
                >
                  <div
                    class="chat-border {{
                      userDetails?._id === msg.senderId ? '' : ''
                    }}"
                  >
                    <div
                      class="chat-header-user-name-and-time-stamp {{
                        msg?.position === 'right' ? '' : ''
                      }}"
                    >
                      {{ msg?.senderName }}
                      <span class="time-stamp">{{
                        msg?.date | date: "dd-MM-yyyy, h:mm a"
                      }}</span>
                    </div>
                    <div
                      class="message {{ msg?.position === 'left' ? '' : '' }}"
                    >
                      {{ msg.message }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="noMessage" class="no-message">
                <div class="">No messages</div>
              </div>
              <div class="row text-box-with-icons mt-1 ml-1 mb-3">
                <div class="ui-g-10 margin-left-10px">
                  <textarea
                    pInputText
                    class="form-control"
                    placeholder="type here to send message"
                    [(ngModel)]="chatText"
                  ></textarea>
                </div>
                <div class="ui-g-2" *ngIf="showSendIcon()" (click)="sendChat()">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      class="send-icon"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z" />
                      <path
                        d="M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="loader" *ngIf="showLoader"></div>
        </div>
      </div>
    </div>
  </div>
</div>
