import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormQuestionsService } from "./form-questions.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import * as moment from "moment";
import { JobService } from "../job/job-data.service";

@Component({
  selector: "app-form-questions",
  templateUrl: "./form-questions.component.html",
  styleUrls: ["./form-questions.component.scss"],
})
export class FormQuestionsComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen: any;
  companyID: any;
  searchArr = [];
  showAdd = false;
  cols = [
    {
      header: "Question Name",
      field: "itemName",
    },
    {
      header: "Question Type",
      field: "itemInputType",
    },
    {
      header: "Date Created",
      field: "createdDate",
    },
    {
      header: "Created By",
      field: "createdBy",
    },
    {
      header: "Date Updated",
      field: "reviewedDate",
    },
    {
      header: "Updated By",
      field: "reviewedBy",
    },
    {
      header: "Active",
      field: "active",
    },
  ];
  questionData: any;
  questionsList = [];
  showLoader = true;
  selectedAdmin: any;
  isNew = false;

  constructor(
    private service: FormQuestionsService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService
  ) {}

  ngOnInit() {
    this.clearIndex();
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");

    this.fetchQuestions();
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
      console.log(this.searchArr);
    }
  }

  reload(event) {
    this.showLoader = true;
    this.fetchQuestions();
  }

  callList(event) {
    this.showAdd = false;
    this.isView = false;
    this.questionData = {};
    this.cdr.detectChanges();
    this.fetchQuestions();
  }

  clearIndex() {
    localStorage.removeItem("jobID");
    localStorage.removeItem("pageJobIndex");
    localStorage.removeItem("pageDisposalIndex");
    localStorage.removeItem("pageWasteIndex");
    localStorage.removeItem("pageReportIndex");
    localStorage.removeItem("pageInspectionReportIndex");
  }

  createQuestion(event) {
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isNew = false;
      this.isView = false;
      this.questionData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
      this.isNew = true;
    }
    this.cdr.detectChanges();
  }

  duplicateQuestion(event, data) {
    let value: any = {};
    value = { ...data };
    delete value.itemData[0]._id;
    value.itemName = value.itemName + "  copy";
    value.itemText = value.itemText + "  copy";
    value.createdBy =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
    value.createdDate = new Date();
    delete value.reviewedBy;
    delete value.reviewedDate;

    this.service.insertQuestion(value).subscribe(
      (data: any) => {
        if (data.message == "Success") {
          this.messageService.add({
            severity: "success",
            summary: "Create Admin",
            detail: "New Question created successfully",
          });
          this.showLoader = true;
          this.fetchQuestions();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Create Question",
            detail: "Unable to add Question.Please try again.",
          });
        }
      },
      (err) => {
        this.messageService.add({
          severity: "error",
          summary: err.error.message,
          // detail: "Unable to add Question.Please try again.",
        });
      }
    );
  }

  editQuestion(data) {
    this.questionData = data;
    this.showAdd = true;
    this.isNew = false;
    this.isView = false;
  }

  viewQuestion(data) {
    this.questionData = data;
    this.isView = true;
    this.showAdd = true;
    this.isNew = false;
  }

  deleteQuestion(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Form Questions?",
      accept: () => {
        try {
          this.service.deleteQuestion(id).subscribe(
            (resp: any) => {
              if (resp && resp.data) {
                this.messageService.add({
                  severity: "success",
                  summary: "Delete Form Questions",
                  detail: "Form Questions details deleted successfully",
                });
                this.fetchQuestions();
              }
            },
            (err) => {
              console.log("error", err);
              this.messageService.add({
                severity: "error",
                summary: "Delete Form Questions",
                detail: "Failed to delete the Form Questions",
              });
              this.fetchQuestions();
            }
          );
        } catch (e) {
          console.warn(e);
        }
      },
    });
  }

  fetchQuestions() {
    this.service.fetchQuestions().subscribe((response) => {
      try {
        this.showLoader = false;
        if (!response.data.length) {
          return (this.questionsList = []);
        }
        if (response && response.data && response.data.length) {
          this.questionsList = response.data;
          this.questionsList = this.questionsList.map((val) => {
            val.createdDate = moment(val.createdDate).format("DD/MM/YYYY");
            val.reviewedDate =
              val && val.reviewedDate
                ? moment(val.reviewedDate).format("DD/MM/YYYY")
                : "";
            if (val.itemData && val.itemData.length) {
              val.itemInputType = val.itemData[0].itemInputType;
            }
            return val;
          });
        }
      } catch (e) {
        this.showLoader = false;
        console.warn(e);
      }
    });
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
