import { Component, OnInit } from "@angular/core";
import { ReportsService } from "../reports.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { JobService } from "../../job/job-data.service";
@Component({
  selector: "app-view-reports",
  templateUrl: "./view-reports.component.html",
  styleUrls: ["./view-reports.component.scss"],
})
export class ViewReportsComponent implements OnInit {
  reportDetails: any;
  isEditView = false;
  showLoader = true;
  openChatWindow: boolean;
  constructor(
    private service: ReportsService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobService
  ) {}
  ngOnInit() {
    this.fetchRecords();
  }
  closeEvent(event) {
    this.router.navigate(["reports"]);
  }
  fetchRecords() {
    this.service
      .fetchReportsById(this.route.snapshot.params.id)
      .subscribe((data) => {
        console.log(data);
        this.reportDetails = data;
        this.showLoader = false;
      });
  }
  backToReport(e) {
    this.router.navigate(["reports"]);
  }
  viewGoogleMap(location) {
    if (location) {
      window.open(
        "https://maps.google.com/?q=" + location.lat + "," + location.long,
        "_blank"
      );
    }
  }
  editReport(event) {
    this.isEditView = true;
  }
  openPopUp(photo) {
    console.log(photo);
    //this.ds.createModal(PhotoViewerComponent, { header: photo.title, modal: true }, { fileURL: photo.fileURL });
  }
  saveReport(event) {
    this.service.saveReports(this.reportDetails).subscribe((data) => {
      this.messageService.add({
        severity: "success",
        summary: "Update Reports",
        detail: "Reports details updated successfully",
      });
      this.isEditView = false;
      this.fetchRecords();
    });
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
  closeChatWindow(event) {
    this.openChatWindow = false;
  }
  openChat() {
    this.openChatWindow = true;
    this.reportDetails.isFrom = "wasteReports";
  }
}
