<div class="">
  <div>
    <div class="ui-g-12">
      <div class="ui-g-6" style="text-align: left">
        <button type="button" class="btn btn-warning" (click)="closePopup()">
          <i class="fa fa-edit fa-2x"></i>CANCEL
        </button>
      </div>
      <div class="ui-g-6" style="text-align: right">
        <button
          type="button"
          class="btn btn-success"
          (click)="saveForm()"
          [disabled]="checkFormDisable()"
          *ngIf="!isView && !isEdit"
        >
          <i class="fa fa-edit fa-2x"></i>SAVE
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="updateForm()"
          [disabled]="checkFormDisable()"
          *ngIf="!isView && isEdit"
        >
          <i class="fa fa-edit fa-2x"></i>UPDATE
        </button>
      </div>
    </div>
    <form [formGroup]="userForm">
      <div class="ui-g-12">
        <div class="ui-g-6">
          <div style="display: flex">
            <label class="ui-g-3">Form Name</label>
            <input
              formControlName="formName"
              type="text"
              class="ui-g-9 sequence bottom-border"
              pInputText
              (keydown)="showPopUpScreen()"
            />
          </div>
          <!-- <div style="display: flex" class="mt-1">
            <label class="ui-g-3">Category</label>
            <input formControlName="formType" type="text" class="ui-g-9" />
          </div> -->

          <div style="display: flex" class="mt-1">
            <label for="cat" class="ui-g-3">Category</label>
            <p-dropdown
              class="ui-g-9 border"
              placeholder="Search Category"
              formControlName="formType"
              [options]="categoryList"
              optionLabel="formType"
              [style]="{ width: '100%' }"
              [filter]="true"
              [filterBy]="'label'"
              (onChange)="showPopUpScreen()"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="ui-g-6">
          <div class="ui-g-2">
            <label>
              <p-checkbox
                name="active"
                class="displayBlock"
                ngDefaultControl
                formControlName="active"
                class="map-checkbox text-allign-btns"
                binary="false"
                (onChange)="showPopup = true"
              ></p-checkbox
              >Active
            </label>
          </div>
          <div class="ui-g-3">
            <label>
              <p-checkbox
                name="active"
                class="displayBlock"
                ngDefaultControl
                formControlName="mobileView"
                class="map-checkbox text-allign-btns"
                binary="false"
                (onChange)="showPopup = true"
              ></p-checkbox
              >Visible Mobile
            </label>
          </div>
          <div class="ui-g-5">
            <label
              >Date Active :
              <input
                class="date-input-border"
                type="date"
                formControlName="dateActive"
                value="{{ addForm?.value?.dateActive | date: 'yyyy-MM-dd' }}"
                (onChange)="showPopup = true"
              />
            </label>
          </div>
          <!-- <div class="ui-g-3">
            <label>Date Inactive</label>
            <input type="date" formControlName="dateInactive" />
          </div> -->
          <!-- <div class="ui-g-2">
            <label>Archived</label>
            <p-checkbox
              name="active"
              class="displayBlock"
              ngDefaultControl
              formControlName="archived"
              class="map-checkbox text-allign-btns"
              binary="false"
            ></p-checkbox>
          </div> -->
        </div>
      </div>
      <!-- <div class="ui-g-12"> -->
    </form>
    <div class="ui-g-12 box-wrap">
      <div class="ui-g-3 box-container">
        <div class="box-header">
          <label class="bold">Form Sections</label>
          <hr />
        </div>
        <div class="mid-container">
          <div
            cdkDropList
            [cdkDropListData]="allocatedSection"
            [id]="'main'"
            [cdkDropListConnectedTo]="dropTargetIds"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListSortingDisabled]="true"
          >
            <div
              *ngFor="let section of allocatedSection; let sectionIndex = index"
              cdkDrag
              [cdkDragDisabled]="isView"
              [cdkDragData]="section?.id"
              (cdkDragMoved)="dragMoved($event)"
            >
              <div
                class="ui-g-12 cursor-pointer"
              >
                <ng-container
                  *ngTemplateOutlet="
                    tmplNode;
                    context: { node: section, sectionIndex: sectionIndex }
                  "
                ></ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="box-footer"></div>
      </div>
      <div class="ui-g-3 box-container" *ngIf="!isView">
        <div class="box-header">
          <label class="bold">Allocate Sections</label>
          <hr />
        </div>
        <p-dropdown
          placeholder="Search Sections"
          [options]="sectionList"
          optionLabel="sectionTitle"
          [style]="{ width: '100%' }"
          [filter]="true"
          [filterBy]="'label'"
          (onChange)="onSectionSelect($event)"
        >
        </p-dropdown>
        <hr />
        <div class="mid-container">
          <div class="ui-g-12" *ngFor="let section of selectedSection">
            {{ section?.sectionTitle }}
          </div>
        </div>
        <div class="footer-wrap">
          <div class="box-footer">
            <button
              type="button"
              class="btn btn-success footer-buttons"
              (click)="allocateSection($event, selectedSection)"
            >
              ALLOCATE
            </button>
            <button
              type="button"
              class="btn btn-primary footer-buttons"
              (click)="addNewSection($event)"
            >
              ADD NEW
            </button>
          </div>
        </div>
      </div>
      <div class="ui-g-3 box-container">
        <div class="box-header">
          <label class="bold">Allocated Questions</label>
          <hr />
        </div>
        <div class="mid-container">
          <div
            class="example-list"
            cdkDropList
            [cdkDropListData]="selectedSectionObj?.itemIdArray"
            class="example-list"
            (cdkDropListDropped)="dropItem($event)"
            *ngIf="selectedSectionObj?.itemIdArray?.length"
          >
            <div
              class="example-box"
              cdkDrag
              *ngFor="
                let ques of selectedSectionObj?.itemIdArray;
                let i = index
              "
            >
              <div class="ui-g-12">
                <p-checkbox
                  name="active"
                  (onChange)="checkBoxchange($event, i)"
                  class="ui-g-1"
                  binary="false"
                  [(ngModel)]="ques.isSelected"
                  *ngIf="!isView"
                ></p-checkbox>
                <div
                  class="ui-g-11 cursor-point"
                  (click)="showQuestionsDetails(ques)"
                >
                  {{ ques.itemName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-wrap ">
          <div
            class="box-footer margin-top-40px"
            *ngIf="selectedSectionObj?.itemIdArray?.length"
          >
            <button
              type="button"
              class="btn btn-danger"
              (click)="deleteQues($event)"
              *ngIf="!isView"
            >
              DELETE
            </button>
          </div>
        </div>
      </div>
      <div class="ui-g-2 box-container" *ngIf="!isView">
        <div class="box-header">
          <label class="bold">Allocate Questions</label>
          <hr />
        </div>

        <div class="mid-container">
          <p-dropdown
            placeholder="Search Questions"
            [options]="questionsList || []"
            optionLabel="itemName"
            [style]="{ width: '100%' }"
            [filter]="true"
            [filterBy]="'label'"
            (onChange)="onQuestionSelect($event)"
            *ngIf="selectedSection?.length || formSectionDataSelected"
          >
          </p-dropdown>
          <hr *ngIf="selectedSection?.length || formSectionDataSelected"/>
          <div class="ui-g-12" *ngFor="let que of selectedQues">
            {{ que.itemName }}
          </div>
          <h5 class="message" *ngIf="!selectedSection?.length && !formSectionDataSelected">
            Please select section before questions allocation
          </h5>
        </div>
        <div class="footer-wrap" *ngIf="selectedSection?.length || formSectionDataSelected">
          <div class="box-footer margin-top-40px">
            <button
              type="button"
              class="btn btn-success footer-buttons"
              (click)="allocateQuestion($event, selectedQues)"
            >
              ALLOCATE
            </button>
            <button
              type="button"
              class="btn btn-primary footer-buttons"
              (click)="addNewQues($event)"
            >
              ADD NEW
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- </div> -->
  </div>
  <div *ngIf="showSelectedQuestionForDetails">
    <div class="question-details-container">
      <label>Question details</label>
      <div class="ui-g-12">
        <label>Name: {{ selectedQuestionForDetails?.itemText }}</label>
      </div>
      <div class="ui-g-12">
        <label>Type: {{ selectedQuestionForDetails?.itemInputType }}</label>
      </div>
      <div class="ui-g-12">
        <label>Answers: {{ selectedQuestionForDetails?.answers }}</label>
      </div>
    </div>
  </div>
</div>

<ng-template
  #tmplNode
  let-node="node"
  let-section="section"
  let-childIndex="childIndex"
  let-sectionIndex="sectionIndex"
>
  <!-- {{ section | json }} -->
  <div
    class="node-item"
    [attr.data-id]="node?.id"
    [attr.id]="'node-' + node?.id"
  >
    <div
      class="node-title example-list"
      [ngClass]="node?.isSelected ? 'selected-sections' : ''"
      (click)="onAllocatedSectionClick(node)"
    >
      <input
      [ngClass]="node?.isSelected ? 'selected-sections' : ''"
        [disabled]="isView"
        step="any"
        class="ui-g-2 sequence margin-top-5px"
        type="number"
        [(ngModel)]="node.sectionOrderNumber"
        [value]="node?.sectionOrderNumber"
      />
      <span class="ui-g-8" (click)="node.isExpanded = !node.isExpanded">
        <span class="bold custom-font-size">
          {{
            node.children.length
              ? node.isExpanded
                ? "- &nbsp;"
                : "+"
              : "&nbsp;&nbsp;&nbsp;"
          }}
        </span>
        &nbsp;&nbsp;&nbsp; {{ node?.sectionTitle }}
      </span>
      <button
        title="Remove section"
        (click)="removeSection(section, childIndex, sectionIndex)"
        type="button"
        class="btn ui-g-2 trash margin-top-5px"
        *ngIf="!isView"
      >
        <i class="fa fa-minus-circle"></i>
      </button>
    </div>

    <div
      *ngIf="node.isExpanded && node.children.length"
      class="node-children"
      cdkDropList
      [cdkDropListData]="node.children"
      [id]="node.id"
      [cdkDropListConnectedTo]="dropTargetIds"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListSortingDisabled]="true"
    >
      <div
        *ngFor="let child of node.children; let childIndex = index"
        cdkDrag
        [cdkDragDisabled]="isView"
        [cdkDragData]="child.id"
        (cdkDragMoved)="dragMoved($event)"
      >
        <!-- <input placeholder="Enter" /> -->
        <ng-container
          *ngTemplateOutlet="
            tmplNode;
            context: { node: child, section: node, childIndex: childIndex }
          "
        ></ng-container>
      </div>
    </div>
  </div>
</ng-template>
