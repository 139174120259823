import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { StaffService } from "../staff-data.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { JobService } from "../../job/job-data.service";

@Component({
  selector: "app-add-edit-staff",
  templateUrl: "./add-edit-staff.component.html",
  styleUrls: ["./add-edit-staff.component.scss"],
})
export class AddEditStaffComponent implements OnInit {
  studentForm: FormGroup;
  userForm: FormGroup;
  addForm: FormGroup;
  depat;
  editForm: FormGroup;
  isUpdate = false;
  slide = true;
  isEdit = false;
  errorMessage = false;
  isAcumen: any;
  companyID: any;
  companyList = [{ label: "Please Select", value: "" }];
  departmentList = [{ label: "Please Select", value: "" }];
  data: any = [];
  @Input("data") staffData: any = {};
  @Input("isView") isView = false;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  get viewData(): any {
    return this.data;
  }
  set viewData(data: any) {
    this.data = data[0];
    if (data) {
      console.log("Data", data);
      this.isUpdate = true;
      this.userForm = this.editForm;
      this.userForm.controls["phone"].setValue(this.data.phone);
      this.userForm.controls["email"].setValue(this.data.email);
      this.userForm.controls["firstName"].setValue(this.data.firstName);
      this.userForm.controls["lastName"].setValue(this.data.lastName);

      this.userForm.controls["employeeId"].setValue(this.data.employeeId);
      this.userForm.controls["password"].setValue(this.data.password);
      this.userForm.controls["cpassword"].setValue(this.data.password);
      if (this.data.company) {
        this.userForm.controls["company"].setValue(this.data.company.companyId);
        this.userForm.controls["department"].setValue(this.data.department);
      }
    }
  }

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private staffService: StaffService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private jobService: JobService
  ) {
    this.addForm = new FormGroup({
      phone: new FormControl(""),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.email]),
      company: new FormControl(""),
      department: new FormControl(""),
      // employeeId: new FormControl('', [Validators.required]),
      employeeId: new FormControl(""),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      cpassword: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      //   isActive:new FormControl(false)
    });
    this.editForm = new FormGroup({
      phone: new FormControl(""),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.email]),
      company: new FormControl(""),
      department: new FormControl(""),
      // employeeId: new FormControl('', [Validators.required]),
      employeeId: new FormControl(""),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      cpassword: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      //  isActive:new FormControl(false)
    });
    this.companyDepartmentList();
  }
  ngAfterViewInit() {
    console.log("before ngafterviewinit", this.userForm);
    if (!this.isUpdate) {
      this.userForm.patchValue({
        email: "",
        password: "",
      });
    }
    console.log("ngafterviewinit", this.userForm);
  }
  editEvent(event) {
    this.isEdit = true;
    this.isUpdate = true;
    this.isView = false;
    this.loadData();
  }
  companyChange(event) {
    console.log("companyChange", event, this.data);
    this.departmentList = [{ label: "Please Select", value: "" }];
    this.staffService.departmentList(event.value).subscribe((data) => {
      if (data.status === 200) {
        //  this.departmentList=[];
        console.log(" abcdef department list", data, this.data.department);
        data.data.forEach((element) => {
          this.departmentList.push({
            label: element.departmentName,
            value: element.deptId,
          });
        });
        if (this.isUpdate) {
          this.userForm.controls["department"].setValue(this.data.department);
        }
        this.departmentList.forEach((ele) => {
          if (ele.value === this.data.department) this.depat = ele.label;
          console.log("deprt", this.depat);
        });
        console.log("department list", this.departmentList);
        // this.userForm.controls['department'].setValue('');
        console.log("department userform list", this.userForm);
      } else {
      }
    });
  }
  companyDepartmentList() {
    this.staffService.companyList().subscribe((data) => {
      if (data.status === 200) {
        console.log("company list", data);
        data.data.forEach((element) => {
          if (this.isAcumen == "true" || this.isAcumen == true) {
            this.companyList.push({
              label: element.companyName,
              value: element.companyId,
            });
          } else {
            if (element.companyId == this.companyID) {
              let data = {
                label: element.companyName,
                value: element.companyId,
              };
              this.companyList.push({
                label: element.companyName,
                value: element.companyId,
              });
              if (
                this.isAcumen == "false" ||
                (this.isAcumen == false && !this.isUpdate)
              ) {
                console.log("abcdefghijk");
                this.userForm.controls["company"].setValue(element.companyId);
                this.companyChange(data);
              }
            }
          }
        });

        if (this.isUpdate) {
          let data;
          data = {
            value: this.data.company.companyId,
          };
          this.companyChange(data);
          this.userForm.controls["company"].setValue(
            this.data.company.companyId
          );
        }
      } else {
      }
    });
  }
  createStaff(e) {
    let value: any = "";
    console.log("Value", this.userForm.value);
    value = this.userForm.value;
    //  value.username = this.studentForm.controls['student_number'].value;
    //  value.college_id = localStorage.getItem('college_id');
    //  if (value.email === value.cemail) {
    if (
      this.userForm.get("cpassword").value !==
      this.userForm.get("password").value
    ) {
      this.userForm.get("cpassword").setValue("");
      this.userForm.get("password").setValue("");
      this.errorMessage = true;
      // this.messageService.add({
      //     severity: 'error',
      //     summary: 'Error',
      //     detail: 'Password and confirm password does not match'
      // });
    } else {
      this.errorMessage = false;
      delete value.cpassword;
      this.staffService.createUser(value).subscribe(
        (data) => {
          if (data.status === 200) {
            this.messageService.add({
              severity: "success",
              summary: "Create User",
              detail: "New User created successfully",
            });
            // this.ref.close(this);
            // this.closeEvents.emit(true);
            this.closeEvent(true);
          } else if (data.status === 500) {
            this.messageService.add({
              severity: "error",
              summary: "Create User",
              detail: data.error.message.errmsg,
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Create User",
              detail: "Unable to add User.Please try again.",
              //  detail: 'Failed to create the User'
            });
          }
        },
        //  });
        (err) => {
          console.log("Error", err);
          this.messageService.add({
            severity: "error",
            summary: "Create User",
            // detail: 'Failed to create the Waste types'
            detail: "Unable to add User.Please try again.",
          });
          // this.dialogService.closeAll();
        }
      );
      // } else {
      //     this.messageService.add({
      //         severity: 'failure',
      //         summary: 'Create Student',
      //         detail: 'Student Email and confirm email are not matching'
      //     });
      // }
    }
  }
  updateStaff(event: any) {
    // console.log('update student', value.email, value.cemail);
    let value: any = "";
    value = this.userForm.value;
    value._id = this.data._id;
    // delete value.password;
    delete value.cpassword;
    console.log("Update value", this.userForm);
    if (
      this.userForm.get("cpassword").value !==
      this.userForm.get("password").value
    ) {
      this.userForm.get("cpassword").setValue("");
      this.userForm.get("password").setValue("");
      this.errorMessage = true;
      // this.messageService.add({
      //     severity: 'error',
      //     summary: 'Error',
      //     detail: 'Password and confirm password does not match'
      // });
    } else {
      this.errorMessage = false;
      this.staffService.updateUser(value).subscribe((data) => {
        if (data.status === 200) {
          this.messageService.add({
            severity: "success",
            summary: "Update User",
            detail: "User details updated successfully",
          });
          // this.ref.close(this);
          if (this.isEdit == true) {
            // this.staffService.getUserDetails(this.data.employeeId).subscribe((response) => {
            this.staffService
              .getUserDetails(this.data._id)
              .subscribe((response) => {
                console.log("user  details", response);
                this.staffData = response.data[0];
                this.closeEvent(true);
              });
          } else {
            // this.closeEvents.emit(true);
            this.closeEvent(true);
          }
        } else if (data.status === 500) {
          this.messageService.add({
            severity: "error",
            summary: "Update User",
            detail: data.error.message.errmsg,
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Update User",
            detail: "Unable to update User.Please try again.",
            //  detail: 'Failed to update the user details'
          });
        }
      });
      // } else {
      //     this.messageService.add({
      //         severity: 'failure',
      //         summary: 'Create Student',
      //         detail: 'Student Email and confirm email are not matching'
      //     });
      // }
    }
  }
  onChange(event) {
    this.errorMessage = false;
    this.cdr.detectChanges();
  }
  ngOnInit() {
    console.log("this.config", this.config);
    //   var element = document.getElementById("styleSelector");
    //                 element.classList.remove("slide-out");
    //                 element.classList.add("animate-block");
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");
    this.loadData();
  }
  loadData() {
    if (this.isUpdate) {
      this.userForm = this.editForm;
    } else {
      this.userForm = this.addForm;
    }
    if (this.staffData && Object.keys(this.staffData).length !== 0) {
      console.log(" true vehicleData", this.staffData);
      this.data = this.staffData;
      this.isUpdate = true;
      if (this.isUpdate) {
        this.userForm = this.editForm;
        this.userForm.controls["phone"].setValue(this.data.phone);
        this.userForm.controls["email"].setValue(this.data.email);
        this.userForm.controls["firstName"].setValue(this.data.firstName);
        this.userForm.controls["lastName"].setValue(this.data.lastName);
        this.userForm.controls["employeeId"].setValue(this.data.employeeId);
        this.userForm.controls["password"].setValue(this.data.password);
        this.userForm.controls["cpassword"].setValue(this.data.password);
        if (this.data.company) {
          this.userForm.controls["company"].setValue(
            this.data.company.companyId
          );
          this.userForm.controls["department"].setValue(this.data.department);
        }
      }
    }
  }
  closeEvent(event) {
    if (this.isEdit == false) {
      //     var element = document.getElementById("styleSelector");
      //   element.classList.remove("animate-block");
      //     element.classList.add("slide-out");
      this.slide = false;
      this.cdr.detectChanges();
      setTimeout((e) => {
        this.closeEvents.emit(true);
      }, 1000);
    } else {
      this.isEdit = false;
      this.isView = true;
      this.isUpdate = false;
      this.loadData();
    }
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
