import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { tap, takeUntil } from "rxjs/operators";

@Injectable()
export class FormTypeService {
  userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(-1);
  user: Observable<any> = this.userSubject.asObservable();

  constructor(private http: HttpClient) {
    const token = localStorage.getItem("token");
    if (token) {
      const decodeToken = this.parseJwt(token);
      const expiryTime = new Date(decodeToken.exp * 1000);
      if (expiryTime < new Date()) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }

  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  public fetchFormTypes() {
    return this.http.get(
      environment?.formBuilderApiURL + "form-type/getAllFormType"
    );
  }
  public addFormType(payload) {
    return this.http.post(
      environment?.formBuilderApiURL + "form-type/addFormType",
      payload
    );
  }
  public editFormType(paylaod, id) {
    return this.http.post(
      environment?.formBuilderApiURL + "form-type/editFormType?id=" + id,
      paylaod
    );
  }
  public deleteFormType(id) {
    return this.http.delete(
      environment?.formBuilderApiURL + "form-type/deleteFormType?id=" + id,
      {}
    );
  }
}
