import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ViewEncapsulation } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./theme/shared/shared.module";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from "./app.component";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { NavigationComponent } from "./theme/layout/admin/navigation/navigation.component";
import { NavContentComponent } from "./theme/layout/admin/navigation/nav-content/nav-content.component";
import { NavGroupComponent } from "./theme/layout/admin/navigation/nav-content/nav-group/nav-group.component";
import { NavCollapseComponent } from "./theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component";
import { NavItemComponent } from "./theme/layout/admin/navigation/nav-content/nav-item/nav-item.component";
import { NavBarComponent } from "./theme/layout/admin/nav-bar/nav-bar.component";
import { NavLeftComponent } from "./theme/layout/admin/nav-bar/nav-left/nav-left.component";
import { NavSearchComponent } from "./theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component";
import { NavRightComponent } from "./theme/layout/admin/nav-bar/nav-right/nav-right.component";
import { ChatUserListComponent } from "./theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component";
import { FriendComponent } from "./theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component";
import { ChatMsgComponent } from "./theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component";
import { ConfigurationComponent } from "./theme/layout/admin/configuration/configuration.component";
import { HttpClientModule } from "@angular/common/http";
import { ButtonModule } from "primeng/button";

import { ToggleFullScreenDirective } from "./theme/shared/full-screen/toggle-full-screen";
import { HttpLogInterceptor } from "./http-log-interceptor.service";

/* Menu Items */
import { NavigationItem } from "./theme/layout/admin/navigation/navigation";
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
  NgbDatepicker,
  NgbModule
} from "@ng-bootstrap/ng-bootstrap";
import { TableModule } from "primeng/table";
import { JobService } from "./demo/job/job-data.service";
import { VehicleService } from "./demo/vehicle/vehicle-data.service";
import { StaffService } from "./demo/staff/staff-data.service";
import { LoginComponent } from "./login/login.component";
import { LoginService } from "./login/login.service";
import { AdminUserService } from "./demo/admin-users/admin-users.service";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { StaffDataModule } from "./demo/staff/staff-data/staff-data.module";
import { JobDataModule } from "./demo/job/job-data/job-data.module";
import { DropdownModule } from "primeng/dropdown";
import { VehicleModule } from "./demo/vehicle/vehicle.module";
import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { CheckboxModule } from "primeng/checkbox";
import { ToastModule } from "primeng/toast";
import { VehicleDataModule } from "./demo/vehicle/vehicle-data/vehicle-data.module";
import { ViewJobViewModule } from "./demo/job/view-job-details/view-job-details.module";
import { TabViewModule } from "primeng/tabview";
import { AgmCoreModule } from "@agm/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CalendarModule } from "primeng/calendar";
import { InputSwitchModule } from "primeng/inputswitch";
import { AdminUserModule } from "./demo/admin-users/admin-users.component.module";
import { CustomersModule } from "./demo/customers/customers.module";
import { CustomerDivisionsModule } from "./demo/customer-divisions/customer-divisions.module";
import { VehicleTypesModule } from "./demo/vehicle-types/vehicle-types.module";
import { CustomersService } from "./demo/customers/customers.service";
import { CustomerDivisionsService } from "./demo/customer-divisions/customer-divisions.service";
import { VehicleTypesService } from "./demo/vehicle-types/vehicle-types.service";
import { WasteDescriptionComponent } from "./demo/waste-description/waste-description.component";
import { WasteCategoryModule } from "./demo/waste-category/waste-category.module";
import { WasteCategoryService } from "./demo/waste-category/waste-category.service";
import { HazardousService } from "./demo/hazardous/hazardous.service";
import { SkipService } from "./demo/skip/skip.service";
import { CustomerSiteModule } from "./demo/customer-site/customer-site.module";
import { CustomerSitesService } from "./demo/customer-site/customer-site.service";
import { EditorModule } from "primeng/editor";
import { ReportsModule } from "./demo/reports/reports.module";
import { InspectionReportModule } from "./demo/inspection-report/inspection-report.module";
import { DisposalSitesModule } from "./demo/disposal-sites/disposal-sites.module";
import { ReportsService } from "./demo/reports/reports.service";
import { InspectionReportService } from "./demo/inspection-report/inspection-report.service";
import { DisposalSitesService } from "./demo/disposal-sites/disposal-sites.service";
import { WasteDescriptionService } from "./demo/waste-description/waste-description.service";
import { Ng4GeoautocompleteModule } from "../modules/auto-complete/ng-4-geoautocomplete.module";
// import { ImportingComponent } from './demo/disposal-site/importing/importing.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { HazardousComponent } from "./demo/hazardous/hazardous.component";
import { SkipComponent } from "./demo/skip/skip.component";
import { FormQuestionsModule } from "./demo/form-questions/form-questions.component.module";
import { FormQuestionsService } from "./demo/form-questions/form-questions.service";
// import { ExportingComponent } from './staff-data/exporting/exporting.component';
// import { ImportStaffComponent } from "./demo/staff/import-staff/import-staff.component";
import { DragulaModule } from "ng2-dragula";
import { FormSectionsModule } from "./demo/form-sections/form-sections.component.module";
import { FormSectionsService } from "./demo/form-sections/form-sections.service";
import { FormBuildersModule } from "./demo/form-builders/form-builders.component.module";
import { FormBuildersService } from "./demo/form-builders/form-builders.service";
import { FormTypeService } from "./demo/formType/form-type.service";
import { UserPermissionComponent } from "./demo/admin-users/user-permission/user-permission.component";
import { FormAccessComponent } from "./demo/form-builders/form-access/form-access.component";
import { AddEditViewUserAccessFormBuilderComponent } from "./demo/form-builders/form-access/add-edit-view-user-access-form-builder/add-edit-view-user-access-form-builder.component";
import { SearchableDorpdownComponent } from "./shared-components/searchable-dorpdown/searchable-dorpdown.component";
import { ChatComponent } from "./shared-components/chat/chat.component";
import { NewInspectionReportModule } from "./demo/new-inspection-report/new-inspection-report.module";
import { SharedComponentsModule } from "./shared-components/shared-components.module";
import { NotificationsComponent } from "./shared-components/notifications/notifications.component";
import { CardModule } from "./theme/shared/components";
import { ToastyModule } from "ng2-toasty";
import { BarRatingModule } from "ngx-bar-rating";
import { MessagingDataModule } from "./demo/messaging/messaging-data/messaging-data.module";
import { MessagingDataService } from "./demo/messaging/messaging-data/messaging-data.service";
// import  * as firebase from 'firebase/app';

// import { AngularFireModule } from "@angular/fire/compat";
// import { AngularFireAuthModule } from "@angular/fire/compat/auth";
// import { AngularFireStorageModule } from "@angular/fire/compat/storage";
// import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
// import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    LoginComponent,
    WasteDescriptionComponent,
    HazardousComponent,
    SkipComponent,
    UserPermissionComponent,
    FormAccessComponent,
    AddEditViewUserAccessFormBuilderComponent,
    SearchableDorpdownComponent,
    NotificationsComponent,
    // ChatComponent,
    // ExportingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CardModule,
    JobDataModule,
    MessagingDataModule,
    NgbDropdownModule,
    NgbModule,
    BarRatingModule,
    VehicleDataModule,
    NgbTooltipModule,
    ViewJobViewModule,
    ButtonModule,
    EditorModule,
    InputSwitchModule,
    AdminUserModule,
    CustomersModule,
    CustomerDivisionsModule,
    VehicleTypesModule,
    TabViewModule,
    Ng4GeoautocompleteModule.forRoot(),
    CalendarModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBcapYO6jKgeTykdEWCtzdJIHM6zK2LN1Q", //'AIzaSyCV4EIRYyt8YOAQFgrjCfonCyJAuzRdrq4',
      libraries: ["places"],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastModule,
    TableModule,
    VehicleModule,
    MessagesModule,
    DropdownModule,
    DynamicDialogModule,
    MessageModule,
    WasteCategoryModule,
    CheckboxModule,
    StaffDataModule,
    ConfirmDialogModule,
    HttpClientModule,
    NgbButtonsModule,
    NgbTabsetModule,
    ReportsModule,
    InspectionReportModule,
    CustomerSiteModule,
    DisposalSitesModule,
    FormQuestionsModule,
    FormSectionsModule,
    FormBuildersModule,
    NewInspectionReportModule,
    SharedComponentsModule,
    DragulaModule,
    // AngularFireModule.initializeApp(firebaseConfig),
    // AngularFirestoreModule, // firestore
    // AngularFireAuthModule, // auth
    // AngularFireStorageModule, // storage
  ],

  providers: [
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    ReportsService,
    InspectionReportService,
    DisposalSitesService,
    WasteDescriptionService,
    WasteCategoryService,
    CustomersService,
    CustomerDivisionsService,
    VehicleTypesService,
    CustomerSitesService,
    HazardousService,
    SkipService,
    FormQuestionsService,
    FormSectionsService,
    FormBuildersService,
    NavigationItem,
    DynamicDialogConfig,
    DialogService,
    AdminUserService,
    JobService,
    MessagingDataService,
    DynamicDialogRef,
    ConfirmationService,
    MessageService,
    VehicleService,
    StaffService,
    LoginService,
    FormTypeService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpLogInterceptor, multi: true },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
