import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { CustomerSitesService } from './customer-site.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-customer-site',
  templateUrl: './customer-site.component.html',
  styleUrls: ['./customer-site.component.scss']
})
export class CustomerSiteComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen:any;
  companyID:any;
  showAdd = false;
  customerSiteList=[];
  siteData:any;
  selectedSite:any;
  cols = [
    {
        header: 'Big change Id',
        field: 'Id'
    },
   
    {
        header: 'Company Name',
        field: 'companyName'
    },
    {
        header: 'Customer Address',
        field: 'tipSiteName'
    },
    // {
    //     header: 'Disposal ID',
    //     field: 'disposalLocationId'
    // },
    {
        header: 'Disposal Site Name',
        field: 'disposalLocationName'
    }
];
  adminUserList = [];
  showLoader = true;
  selectedAdmin:any;
  searchArr = [];
  constructor(private service:CustomerSitesService,private translateService: TranslateService,private cdr:ChangeDetectorRef,public dialogService: DialogService,public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService,private confirmDialogService:ConfirmationService) { }

    ngOnInit() {
      this.clearIndex();
      this.companyID = localStorage.getItem('companyID');
      this.isAcumen = localStorage.getItem('isAcumen');
  
      this.fetchCustomerSiteList();
      if (this.cols.length > 0){
        this.cols.forEach(element => {
          this.searchArr.push(element.field);
        });
      }
    
    }
    callList(event){
      console.log('call list');
      this.showAdd = false;
      this.isView = false;
      this.siteData = {};
      this.cdr.detectChanges();
        this.fetchCustomerSiteList();
    }
    clearIndex(){
      localStorage.removeItem('jobID');
      localStorage.removeItem('pageJobIndex'); 
      localStorage.removeItem('pageDisposalIndex'); 
      localStorage.removeItem('pageWasteIndex'); 
      localStorage.removeItem('pageReportIndex'); 
      localStorage.removeItem('pageInspectionReportIndex'); 
    }
    createSite(event){
      console.log('create staff called');
     
      if(this.showAdd == true){
        this.showAdd = false;
        this.isView = false;
        this.siteData = {};
       setTimeout(e =>{
         this.showAdd = true;
       },1000) 
      }else{
        this.showAdd = true;
      }
      this.cdr.detectChanges();
    }
    editSite(event,data){
    
      this.service.getTipDetails(data.Id).subscribe((response) => {
          console.log('user  details', response);
          this.siteData = response.data;
       this.showAdd = true;
       console.log('user  details', this.siteData);
  
       this.cdr.detectChanges();
      // editData = response.data[0];
      // this.showLoader = false;
      // const ref = this.dialogService.open(AddEditStaffComponent, {
      //     data: {
      //         viewData: editData,
      //         isUpdate: true
      //     },
      //     header: 'Edit Staff Details',
      //     width: '600px',
      // });
  
      // ref.onClose.subscribe((car) => {
      //     if (car) {
      //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
      //      this.fetchCustomerSiteList();
      //     }
      // });
  });
    }
    
    viewSite(event,data){
     
      this.service.getTipDetails(data.Id).subscribe((response) => {
        console.log('user  details', response);
        this.siteData = response.data;
        this.isView = true;
     this.showAdd = true;
     console.log('user  details', this.siteData);
  
     this.cdr.detectChanges();
   
    
  });
    }
    deleteSite(id){
      this.confirmDialogService.confirm({
        message: 'Are you sure that you want to delete the Customer site?',
        accept: () => {
            this.service.getTipDetails(id).subscribe((data: any) => {
                if (data.status === 200) {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Delete Customer Site',
                        detail: 'Customer Site details deleted successfully'
                   });
                 
                }else {
           
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Delete Customer Site',
                        detail: data.error.message
                    });
                }
                this.fetchCustomerSiteList();
            },
            (err) => {
                console.log('error',err);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Delete Customer Site',
                    detail: 'Failed to delete the Customer Site'
                });
                this.fetchCustomerSiteList();
            }
        );
          //  });
        }
    });
    }
    fetchCustomerSiteList() {
      const staffArr = [];
      this.service.fetchTipLocation().subscribe((response) => {
          console.log('staff  details', response);
  
          // response.data.forEach((ele) => {
          //     ele.isActive = ele.active ? 'Yes' : 'No';
          // });
          if (response.data.length > 0){
            response.data.forEach(element => {
              console.log('element',element,this.companyID);
              if (this.isAcumen == 'true' || this.isAcumen == true){
                staffArr.push(element);
              } else{
                if(element.companyId == this.companyID){
                  staffArr.push(element);
                }
              }
            });
          }
          this.customerSiteList = staffArr;
         
          this.showLoader=false;
      });
  }



}
