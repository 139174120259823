import { Component, OnInit } from "@angular/core";
import { NewInspectionReportService } from "../new-inspection-report.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { ImageViewComponent } from "../../../theme/shared/components/image-view/image-view.component";
import { JobService } from "../../job/job-data.service";
import * as _ from "underscore";
@Component({
  selector: "app-view-reports",
  templateUrl: "./view-new-inspection-report.component.html",
  styleUrls: ["./view-new-inspection-report.component.scss"],
})
export class ViewNewInspectionReportComponent implements OnInit {
  reportDetails: any;
  isEditView = false;
  showLoader = true;
  data_list: any;
  improvement: any;
  username: any;
  userDetails: any;
  superAdmin: any;

  isReportTo: boolean = false;
  isSignoff: boolean = false;
  anyUserWithReportTo: boolean = false;

  reportToData = {
    improvement: "",
    notes: "",
    reportAccepted: "",
    acceptedBy: "",
    acceptedDate: null,
  };
  signOffData = {
    improvement: "",
    notes: "",
    reportAccepted: "",
    acceptedBy: "",
    acceptedDate: null,
  };
  isEditViewForReport: boolean;
  isEditViewForSignOff: boolean;
  openChatWindow: boolean = false;
  constructor(
    private service: NewInspectionReportService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobService
  ) {}
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userData"));
    let userAccessModule = JSON.parse(localStorage.getItem("accessModule"));
    const filteredArray = userAccessModule.filter(
      (item) => item && item.moduleName === "Reports"
    );
    if (filteredArray?.length) {
      this.superAdmin = filteredArray[0].objectArray.superAdmin;
    } else {
      this.superAdmin = false;
    }
    this.fetchRecords();
    // this.getJSONData();
  }
  closeEvent(event) {
    this.router.navigate(["new-inspection-report"]);
  }
  closeChatWindow(event) {
    this.openChatWindow = false;
  }
  fetchRecords() {
    this.service
      .fetchReportsById(this.route.snapshot.params.id)
      .subscribe((data) => {
        console.log(data);
        this.reportDetails = data;
        this.showLoader = false;
        this.reportDetails?.reportTo?.acceptedBy
          ? (this.reportToData.acceptedBy =
              this.reportDetails?.reportTo?.acceptedBy)
          : (this.reportToData.acceptedBy = "");

        this.reportDetails?.reportTo?.notes
          ? (this.reportToData.notes = this.reportDetails?.reportTo?.notes)
          : (this.reportToData.notes = "");

        this.reportDetails?.reportTo?.improvement
          ? (this.reportToData.improvement =
              this.reportDetails?.reportTo?.improvement)
          : (this.reportToData.improvement = "");

        this.reportDetails?.reportTo?.reportAccepted
          ? (this.reportToData.reportAccepted =
              this.reportDetails?.reportTo?.reportAccepted)
          : (this.reportToData.reportAccepted = "");
        this.reportDetails?.reportTo?.acceptedDate
          ? (this.reportToData.acceptedDate =
              this.reportDetails?.reportTo?.acceptedDate)
          : (this.reportToData.acceptedDate = null);

        // improvement: "",
        // notes: "",
        // reportAccepted: "",
        // acceptedBy: "",
        // acceptedDate: new Date(),

        this.reportDetails?.signOff?.acceptedBy
          ? (this.signOffData.acceptedBy =
              this.reportDetails?.signOff?.acceptedBy)
          : (this.signOffData.acceptedBy = "");

        this.reportDetails?.signOff?.notes
          ? (this.signOffData.notes = this.reportDetails?.signOff?.notes)
          : (this.signOffData.notes = "");

        this.reportDetails?.signOff?.improvement
          ? (this.signOffData.improvement =
              this.reportDetails?.signOff?.improvement)
          : (this.signOffData.improvement = "");

        this.reportDetails?.signOff?.reportAccepted
          ? (this.signOffData.reportAccepted =
              this.reportDetails?.signOff?.reportAccepted)
          : (this.signOffData.reportAccepted = "");

        this.reportDetails?.signOff?.acceptedDate
          ? (this.signOffData.acceptedDate =
              this.reportDetails?.signOff?.acceptedDate)
          : (this.signOffData.acceptedDate = null);

        console.log("reportToData", this.reportToData);
        console.log("signoffdata", this.signOffData);
        const filterdUser = this.reportDetails.adminUserPermission.filter(
          (item) => item && item._id === this.userDetails?._id
        );
        if (filterdUser?.length) {
          console.log("filteredUser", filterdUser);
          this.isReportTo = filterdUser[0].reportTo;
          this.isSignoff = filterdUser[0].signOff;
        }
        // console.log('report'this.isReportTo)
        let data_keys = [];
        let data_list = new Array();
        let submitted_form_json: any;
        submitted_form_json = JSON.parse(
          this.reportDetails.submitted_form_json
        );
        data_keys = Object.keys(submitted_form_json);
        for (const iterator in data_keys) {
          let key = data_keys[iterator];
          let data_val = [key, submitted_form_json[key]];
          if (key != "Check on Actions for Improvement:") {
            data_list.push(data_val);
          }
          // else {
          //   this.improvement = submitted_form_json[key];
          // }
        }
        console.log(data_list);
        this.data_list = data_list;
        this.username =
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("lastName");
        // this.checkIfUserHasPermissionsToViewForm();
      });
  }
  backToReport(e) {
    this.isEditView = false;
    this.isEditViewForReport = false;
    this.isEditViewForSignOff = false;
  }
  viewGoogleMap(location) {
    if (location) {
      window.open(
        "https://maps.google.com/?q=" + location.lat + "," + location.long,
        "_blank"
      );
    }
  }

  editReport(event) {
    this.isEditView = true;

    // if (this.isReportTo && this.isSignoff) {
    //   this.isEditViewForReport = true;
    //   this.isEditViewForSignOff = true;
    //   return;
    // }

    if (this.isReportTo) {
      if (this.reportDetails.reportTo?.reportAccepted === "true") {
        this.isEditViewForReport = false;
      } else {
        this.isEditViewForReport = true;
        this.reportToData?.acceptedBy
          ? this.reportToData.acceptedBy
          : (this.reportToData.acceptedBy =
              this.userDetails.firstName + " " + this.userDetails.lastName);
        this.reportToData?.acceptedDate
          ? this.reportToData.acceptedDate
          : (this.reportToData.acceptedDate = new Date());
      }
    }
    if (this.isSignoff) {
      if (this.reportDetails.signOff?.reportAccepted === "true") {
        this.isEditViewForSignOff = false;
      } else {
        this.isEditViewForSignOff = true;
        this.signOffData?.acceptedBy
          ? this.signOffData.acceptedBy
          : (this.signOffData.acceptedBy =
              this.userDetails.firstName + " " + this.userDetails.lastName);
        this.signOffData?.acceptedDate
          ? this.signOffData.acceptedDate
          : (this.signOffData.acceptedDate = new Date());
      }
    }
  }
  openPopUp(photo) {
    console.log(photo);
    //this.ds.createModal(PhotoViewerComponent, { header: photo.title, modal: true }, { fileURL: photo.fileURL });
  }
  viewImage(data) {
    let img;
    img = { url: data.fileURL };
    const ref = this.dialogService.open(ImageViewComponent, {
      data: {
        viewData: img,
      },
      width: "600px",
    });

    ref.onClose.subscribe((i) => {});
  }
  saveReport(event) {
    // if (this.improvement) {
    //   let submitted_form_json: any;
    //   let improvement_key = "Check on Actions for Improvement:";
    //   submitted_form_json = JSON.parse(this.reportDetails.submitted_form_json);
    //   submitted_form_json[improvement_key] = this.improvement;
    //   this.reportDetails.submitted_form_json =
    //     JSON.stringify(submitted_form_json);
    // }
    this.reportDetails.reportTo = this.reportToData;
    this.reportDetails.signOff = this.signOffData;
    if (this.signOffData?.reportAccepted === "true") {
      this.reportDetails.reportAccepted = true;
      this.reportDetails.acceptedDate = new Date();
    }
    // console.log(this.reportDetails.notes);
    this.service.saveReports(this.reportDetails).subscribe((data) => {
      this.messageService.add({
        severity: "success",
        summary: "Update Reports",
        detail: "Reports details updated successfully",
      });
      this.isEditView = false;
      this.isEditViewForSignOff = false;
      this.isEditViewForReport = false;
      this.fetchRecords();
    });
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  checkReportToAndSignOffSubmission() {
    if (this.isReportTo && this.isSignoff) {
      if (
        this.reportDetails?.reportTo?.reportAccepted === "true" &&
        this.reportDetails?.signOff?.reportAccepted === "true"
      ) {
        return false;
      }

      return true;
    }

    if (this.isSignoff) {
      if (
        this.reportDetails?.reportTo?.reportAccepted === "true" &&
        (this.reportDetails?.signOff?.reportAccepted === "false" ||
          this.reportDetails?.signOff?.reportAccepted === "")
      ) {
        return true;
      }
      if (
        this.reportDetails?.reportTo?.reportAccepted === "true" &&
        this.reportDetails?.signOff?.reportAccepted === "true"
      ) {
        return false;
      }
    }
    if (this.isReportTo) {
      if (this.reportDetails?.reportTo?.reportAccepted === "true") {
        return false;
      }
      return true;
    }
  }

  getReportToNames(type) {
    let name = "";
    switch (type) {
      case "reportTo":
        this.reportDetails?.adminUserPermission.map((item) => {
          if (item.reportTo) {
            name = name + " " + item.userFullName;
          }
        });
        console.log("name", name);
        return name;
      case "signoff":
        this.reportDetails?.adminUserPermission.map((item) => {
          if (item.signOff) {
            name = name + " " + item.userFullName;
          }
        });
        return name;
    }
  }

  openChat() {
    this.openChatWindow = true;
    this.reportDetails.isFrom = "reports";
  }
}
