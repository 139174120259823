<div   class="" >
  
        <div id="styleSelector"  class="style-block staff-container  open {{slide?'animate-block':'slide-out'}}">
    <div class="container h-100">
            <div class="row align-items-center">
              <div class="{{isView?'col-12':'col-10'}} mx-auto user-form ">
                  <div class='row add-edit-header'  *ngIf="!isView">  
                      <div class="ui-g-10">
                          <h4 *ngIf='!isUpdate'>Create Tip Site</h4>
                          <h4 *ngIf='isUpdate'>Edit Tip Site</h4>
                      </div> 
                      <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Click to close a Customer site" tooltipPosition="top">
                          <i class="fa fa-window-close fa-2x "></i>
                       </div>    
                           </div> 
                           <div class="">                    
                <form [formGroup]="TipSitesForm" *ngIf="!isView">
                        <div class='row form-group'>
                                <div class='ui-g-12 mt-3'>
                                    <label for="firstname">Big change Id</label>
                                  <input class='ui-g-12' id="firstname" class="form-control {{isUpdate?'disabled':''}}"   type="{{isUpdate?'text':'number'}}" size="30" formControlName='Id' pInputText>
                                </div>
                              </div>
                    <div class='row form-group' *ngIf="isAcumen == 'true'">
                        <div class='ui-g-12  mt-3'>
                          <label for="linked_type">Company</label>
                        <p-dropdown class='ui-g-12 form-control' [options]="companyList" (onChange)="companyChange($event)"  [style]="{'width':'100%'}" formControlName='ParentId'></p-dropdown>
              
                      </div>
                    </div>  
                    <div class='row form-group' >
                            <div class='ui-g-12  mt-3'>
                              <label for="linked_type">Disposal Site Name</label>
                            <p-dropdown class='ui-g-12 form-control' [options]="disposalList" (onChange)="disposalSiteChange($event)"  [style]="{'width':'100%'}" formControlName='disposalLocationId'></p-dropdown>
                  
                          </div>
                        </div>  
                     
                     
                  <div class='row form-group'>
                    <div class='ui-g-12  mt-3'>
                            <label for="lastname">Tip Site Name</label>
                      <input class='ui-g-12' id="lastname" type="text" class="form-control"size="30" formControlName='tipSiteName' pInputText>
                    </div>
                  </div>   
                   
                  <!-- <div class='col-12' style='color:red;font-size: 12px' *ngIf="userForm.get(''">
                      Employee ID
                    </div> -->
                  
                      
                 
                  
                  
                   
                 
                  
               
                  <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                    <div class='ui-g-12'>
                      <p-button label="Create" [disabled]='!TipSitesForm.valid' (onClick)="createSite($event)"></p-button>
                    
                    </div>
                  </div>
                  <div class='row button-cls' *ngIf='isUpdate'>
                    <div class='ui-g-12'>
                      <p-button label="Update" [disabled]='!TipSitesForm.valid' (onClick)="updateSite($event)"></p-button>
                    
                    </div>
                  </div>
                
                </form>
                <div *ngIf="isView">
                    <div class='row details-header'>  
                        <div class="ui-g-10">
                            <h4>View Tip Site Details</h4>
                        </div> 
                        <div class="ui-g-1" (click)="editEvent($event)" pTooltip="Click to edit a Customer site" tooltipPosition="top">
                            <i class="fa fa-edit fa-2x "></i>
                        </div>    
                        <div class="ui-g-1" (click)="closeEvent($event)" pTooltip="Click to close a Customer site" tooltipPosition="top">
                            <i class="fa fa-window-close fa-2x "></i>
                            </div>    
                </div>  
              <div class="details-content">
                    <div class='row'>
                            <div class="ui-g-6">
                              <span for="userName">Big change Id</span>
                            </div>
                            <div class="ui-g-6">
                              <span for="userName">{{viewData.Id}}</span>
                            </div>
                    </div>
                  <div class='row'>
                    <div class="ui-g-6">
                      <span for="userName">Company</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="userName">{{viewData.ParentId}}</span>
                    </div>
                  </div>
                  <div class='row'>
                      <div class="ui-g-6">
                        <span for="userName">Disposal Site Name</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{viewData.Name}}</span>
                      </div>
                    </div>
                    <div class='row'>
                            <div class="ui-g-6">
                              <span for="userName">Tip Site Name</span>
                            </div>
                            <div class="ui-g-6">
                              <span for="userName">{{viewData.tipSiteName}}</span>
                            </div>
                          </div>
                          
                    
                      
                        
                            
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          </div>