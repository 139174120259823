import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DisposalSitesService } from "../../disposal-sites/disposal-sites.service";
import * as _ from "underscore";
import { AdminUserService } from "../../admin-users/admin-users.service";
import { FormBuildersService } from "../form-builders.service";
@Component({
  selector: "app-form-access",
  templateUrl: "./form-access.component.html",
  styleUrls: ["./form-access.component.scss"],
})
export class FormAccessComponent implements OnInit {
  isUpdate = false;
  isView = false;
  isAcumen: any;
  companyID: any;
  showAdd = false;
  showLoader = true;
  data: any;
  selectedCatgeory: any;
  category: any;
  selectSubCategory = false;
  title: any;
  parentWasteType: any;
  wasteTypeData: any;
  ide: any;
  categoryList = [];
  colsForAdmin = [
    {
      header: "Name",
      field: "userFullName",
    },
    {
      header: "Email",
      field: "email",
    },
    {
      header: "Report To",
      field: "reportTo",
    },
    {
      header: "Sign off",
      field: "signOff",
    },
  ];
  colsForStaff = [
    {
      header: "Name",
      field: "userFullName",
    },
    {
      header: "Email",
      field: "email",
    },
    {
      header: "Access",
      field: "active",
    },
  ];
  searchArr = [];
  vehicleTypeData: any;
  adminUserList = [];
  selectedVehicle: any;
  vehicleTypeList = [];
  id: any;
  disposalData: any;
  moduleList: any;
  userAccessModule: any;
  userDetails: any;
  tempArray: any;
  paramsData: any;
  formData: any;
  tempFormdata: any;
  userList: any = [];
  userIdsToExclude: any;
  constructor(
    private service: DisposalSitesService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private router: Router,
    private adminUserService: AdminUserService,
    private formBuilderService: FormBuildersService
  ) {}

  closeEvent(event) {
    return history.back();
  }

  ngOnInit() {
    // this.id = this.route.snapshot.paramMap.get("id");
    this.showLoader = true;
    this.route.queryParamMap.subscribe((res: any) => {
      console.log(res);
      this.paramsData = res.params;
      this.fetchFormById();
      if (this.paramsData.type === "Users") {
        this.fetchStaffUserList();
      } else {
        this.fetchAdminUserList();
      }
    });
    if (this.colsForAdmin.length > 0) {
      this.colsForAdmin.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
  }

  goBack() {
    history.back();
  }

  fetchFormById() {
    this.showLoader = true;
    this.formBuilderService.fetchFormById(this.paramsData?._id).subscribe(
      (result: any) => {
        console.log(result);
        this.formData = result.data[0];
        this.userIdsToExclude = _.pluck(this.formData, "_id");

        if (this.paramsData.type === "Users") {
          this.userIdsToExclude = _.pluck(
            this.formData.staffUserPermission,
            "_id"
          );
        } else {
          this.userIdsToExclude = _.pluck(
            this.formData.adminUserPermission,
            "_id"
          );
        }
        console.log(this.userIdsToExclude);
        this.showLoader = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  reloadFormList() {
    this.fetchFormById();
  }

  fetchStaffUserList() {
    this.showLoader = true;
    this.formBuilderService.fetchStaffUsers().subscribe(
      (response) => {
        response.data.map((item) => {
          item.userFullName = item.firstName + " " + item.lastName;
        });
        response.data.map((item) => {
          if (!this.userIdsToExclude.includes(item._id)) {
            this.userList.push(item);
          }
        });

        this.showLoader = false;
      },
      (error) => {
        this.showLoader = false;

        console.log(error);
      }
    );
  }
  fetchAdminUserList() {
    this.showLoader = true;

    this.formBuilderService.fetchAdminUsers().subscribe(
      (response) => {
        this.showLoader = false;
        response.data.map((item) => {
          item.userFullName = item.firstName + " " + item.lastName;
        });
        response.data.map((item) => {
          if (!this.userIdsToExclude.includes(item._id)) {
            this.userList.push(item);
          }
        });
      },
      (error) => {
        this.showLoader = false;

        console.log(error);
      }
    );
  }

  addStaffUser() {
    this.showAdd = true;
  }

  closePopup(event) {
    this.showAdd = false;
    this.fetchFormById();
  }

  removeUsersAccess(event, data, type) {
    if (type === "staff") {
      this.formData.staffUserPermission.map((item) => {
        if (item._id === data._id) {
          item.active = event.checked;
        }
      });
      this.updateFormValues(this.formData);
    }
  }

  confirmDelete(data, type) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete user from form?",
      accept: () => {
        if (type === "staff") {
          let staffFilteredData = this.formData.staffUserPermission.filter(
            (item) => item && item._id !== data?._id
          );

          this.formData.staffUserPermission = staffFilteredData;
          this.updateFormValues(this.formData);
        } else {
          let adminFilteredData = this.formData.adminUserPermission.filter(
            (item) => item && item._id !== data?._id
          );

          this.formData.adminUserPermission = adminFilteredData;
          this.updateFormValues(this.formData);
        }
      },
    });
  }

  updateFormValues(payload) {
    this.formBuilderService
      .editFormSection(payload, this.formData._id)
      .subscribe((resp: any) => {
        this.addAdminPermissionsToSubmittedForm(payload);
        try {
          if (resp.status == 200) {
            this.messageService.add({
              severity: "success",
              summary: "Form Updated",
            });
          }

          this.fetchFormById();
        } catch (e) {
          console.warn(e);
        }
      });
  }

  removeOrAddAccessToAdmin(event, data, type) {
    this.formData.adminUserPermission.map((item) => {
      if (item._id === data._id) {
        if (type === "reportTo") {
          item.reportTo = event.checked;
        } else {
          item.signOff = event.checked;
        }
      }
    });
    this.updateFormValues(this.formData);
  }

  addAdminPermissionsToSubmittedForm(payload) {
    const body = {
      formId: this.formData._id,
      adminUserPermission: payload.adminUserPermission,
    };
    this.formBuilderService.addAdminPermissionsToSubmittedForm(body).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
