import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ReportsRoutingModule } from "./reports.routing.module";
import { ReportsComponent } from "./reports.component";
import { SharedModule } from "../../theme/shared/shared.module";
import {
  NgbCarouselModule,
  NgbProgressbarModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { ToastModule } from "primeng/toast";
import { DropdownModule } from "primeng/dropdown";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";
import { ViewReportsComponent } from "./view-reports/view-reports.component";
import { SharedComponentsModule } from "src/app/shared-components/shared-components.module";
@NgModule({
  declarations: [ReportsComponent, ViewReportsComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    NgbCarouselModule,
    DropdownModule,
    ToastModule,
    TooltipModule,
    ButtonModule,
    ConfirmDialogModule,
    MessageModule,
    DynamicDialogModule,
    MessagesModule,
    TableModule,
    SharedComponentsModule,
  ],
  exports: [ViewReportsComponent],
})
export class ReportsModule {}
