import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { NewInspectionReportService } from "./new-inspection-report.service";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Table } from "primeng/table/table";
import { JobService } from "../job/job-data.service";

import * as _ from "underscore";
@Component({
  selector: "app--new-reports",
  templateUrl: "./new-inspection-report.component.html",
  styleUrls: ["./new-inspection-report.component.scss"],
})
export class NewInspectionReportComponent implements OnInit {
  @ViewChild("dt", { static: false }) table: Table;
  reportList = [];
  pageInspectionReportIndex;
  cols = [
    {
      header: "Report No ",
      field: "live_report_number",
    },
    {
      header: "Report Name ",
      field: "formName",
    },
    {
      header: "Report Type ",
      field: "formType",
    },
    {
      header: "Date",
      field: "date_time_submit",
    },
    {
      header: "Company",
      field: "companyName",
    },
    {
      header: "Department",
      field: "departmentName",
    },
    {
      header: "Person Name",
      field: "reporter_name",
    },
    {
      header: "Accepted",
      field: "reportAccepted",
    },
  ];
  jobList = [];
  selectedReport: any;
  showAdd = false;
  companyID: any;
  isAcumen: any;
  jobData = {};
  startDate = new Date();
  endDate = new Date();
  showLoader = true;
  searchArr = [];
  userDetails: any;
  userAccessModule: any;
  superAdmin: boolean;
  constructor(
    private translateService: TranslateService,
    private service: NewInspectionReportService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    private router: Router,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService
  ) {
    this.startDate.setDate(this.startDate.getDate() - 30);
  }

  ngOnInit() {
    let userAccessModule = JSON.parse(localStorage.getItem("accessModule"));
    const filteredArray = userAccessModule.filter(
      (item) => item && item.moduleName === "Reports"
    );
    if (filteredArray?.length) {
      this.superAdmin = filteredArray[0].objectArray.superAdmin;
    } else {
      this.superAdmin = false;
    }
    this.userDetails = JSON.parse(localStorage.getItem("userData"));
    this.companyID = localStorage.getItem("companyID");
    this.pageInspectionReportIndex = parseInt(
      localStorage.getItem("pageInspectionReportIndex")
    );
    this.isAcumen = localStorage.getItem("isAcumen");
    //  this.setPage(2);
    if (this.pageInspectionReportIndex) {
      localStorage.removeItem("pageInspectionReportIndex");
    }
    this.fetchReportList({});
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
      });
    }
  }
  onPageEvent(event) {
    console.log("event", event);
    this.pageInspectionReportIndex = event.first / event.rows + 1;
  }
  setPage(n: number) {
    this.table.reset();
    let paging = {
      first: (n - 1) * this.table.rows,
      rows: this.table.rows,
    };
    console.log("table values", paging);
    this.table.first = paging.first;
    this.table.rows = paging.rows;
  }
  searchReports(event) {
    this.showLoader = true;
    this.fetchReportList({ startDate: this.startDate, endDate: this.endDate });
  }
  viewReport(event, data) {
    if (this.pageInspectionReportIndex != 1) {
      let pageNum = this.pageInspectionReportIndex.toString();
      setTimeout((e) => {
        localStorage.setItem("pageInspectionReportIndex", pageNum);
      }, 1000);
    }
    this.router.navigate(["new-inspection-report/" + data._id]);
  }
  searchJobs(event) {
    console.log("search: ", this.startDate, " : ", this.endDate);
    this.service.setStartAndEndDate(this.startDate, this.endDate);
    this.showLoader = true;
    this.fetchReportList({ startDate: this.startDate, endDate: this.endDate });
  }

  deleteReport(id) {
    console.log("id", id, this.confirmDialogService);
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Report?",
      accept: () => {
        this.service.deleteReport(id).subscribe((data: any) => {
          if (data.status === 200) {
            this.messageService.add({
              severity: "success",
              summary: "Delete Report",
              detail: "Report details deleted successfully",
            });
            this.fetchReportList({});
          }
        });
      },
    });
  }

  reload(event) {
    console.log("reload event called");
    this.showLoader = true;
    this.fetchReportList({});
  }

  fetchReportList(data) {
    const reportArr = [];
    this.service
      .fetchNewSubmittedReports(this.userDetails._id, this.superAdmin)
      .subscribe((response) => {
        console.log("reposn", response);
        if (response.length > 0) {
          response.forEach((element) => {
            if (this.isAcumen == "true" || this.isAcumen == true) {
              // if(element.job_reference && element.employeeName && element.createdAt) {
              reportArr.push(element);
              //}
            } else {
              if (element.company.companyId == this.companyID) {
                //  if(element.job_reference && element.employeeName && element.createdAt) {
                reportArr.push(element);
                // }
              }
            }
          });
        }
        console.log("Inspection Report list", response.data);
        this.reportList = reportArr;
        this.showLoader = false;
        if (this.reportList.length > 0) {
          this.reportList.forEach((ele) => {
            // ele.createdAt = new Date(ele.createdAt).toLocaleString();
            // ele.compltedAt = ele.compltedAt ? new Date(ele.compltedAt).toLocaleString() : '';
            //  ele.createdAt = ele.createdAt? moment(ele.createdAt).format("DD/MM/YYYY h:mm a"):'-';
            //  ele.compltedAt = ele.compltedAt? moment(ele.compltedAt).format("DD/MM/YYYY h:mm a"):'-';
            ele.departmentName = ele.department.departmentName;
            ele.companyName = ele.company.companyName;
            ele.date_time_submit = ele.date_time_submit
              ? moment(ele.date_time_submit).format("DD/MM/YYYY h:mm a")
              : "-";

            //   ele.date_time_submit = new Date(ele.date_time_submit).toLocaleString("en-IN");
            // e.date_time_submit= moment(e.date_time_sumit).format('DD-MM-YY hh:mm');
            ele.personName = ele.userId.firstName;
            ele.reportAccepted = ele.reportAccepted ? "Yes" : "No";
          });
        }
        if (
          this.pageInspectionReportIndex &&
          this.pageInspectionReportIndex > 1
        ) {
          console.log("page index", this.pageInspectionReportIndex);
          this.setPage(this.pageInspectionReportIndex);
        }
      });
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  checkIfUserHasPermissionsToViewForm(data) {
    const userArray = _.pluck(data.adminUserPermission, "_id");
    // console.log("userArray", userArray);
    // console.log(
    //   "user details contains check",
    //   userArray.includes(this.userDetails._id)
    // );
    return userArray.includes(this.userDetails._id);
  }
}
