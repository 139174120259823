import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-sort-drag-list",
  templateUrl: "./sort-drag-list.component.html",
  styleUrls: ["./sort-drag-list.component.scss"],
})
export class SortDragListComponent implements OnInit, OnChanges {
  public dataToShow: Array<any>;
  // public dataKeyNameForLabel;

  @Input() dataKeyNameForLabel;
  @Input() isIndexBindingRequired;

  @Input() set _dataToShow(data) {
    if (data) {
      this.dataToShow = data;
      console.log("dataoShow: ", this.dataToShow);
    }
  }

  @Input() isDeleteRequired;

  @Output() drop = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  dropItem(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      console.log("Transfering item to new container");
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    console.log("updatedData: ", this.dataToShow);
    this.drop.emit(this.dataToShow);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes: ", changes);
  }

  onDelete(index) {
    this.dataToShow.splice(index, 1);
    console.log("dataToShowDel: ", this.dataToShow);
    this.drop.emit(this.dataToShow);
  }
}
