<div class="">
  <div
    id="styleSelector"
    class="style-block staff-container  open {{
      slide ? 'animate-block' : 'slide-out'
    }}"
  >
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="col-10 mx-auto user-form">
          <div class="row add-edit-header">
            <div class="ui-g-10">
              <h4>Add User</h4>
            </div>
            <div
              class="ui-g-2"
              (click)="closeEvent($event)"
              pTooltip="Click to close a Admin"
              tooltipPosition="top"
            >
              <i class="fa fa-window-close fa-2x"></i>
            </div>
          </div>

          <div class="">
            <form [formGroup]="usersForm">
              <div class="row form-group">
                <div class="ui-g-12 mt-3">
                  <label for="type">User</label>
                  <!-- <app-searchable-dorpdown
                    [items]="viewData"
                    [size]="'small'"
                    [img]="'product'"
                    [label]="'artName'"
                    [uid]="'productId'"
                    (sendSelectedUser)="onUserSelect($event)"
                  >
                  </app-searchable-dorpdown> -->

                  <p-dropdown
                    class="ui-g-12 form-control"
                    placeholder="Search user"
                    [options]="viewData"
                    optionLabel="userFullName"
                    [style]="{ width: '100%' }"
                    [filter]="true"
                    [filterBy]="'label'"
                    (onChange)="onUserSelect($event)"
                  >
                  </p-dropdown>
                </div>
              </div>
              <div class="row form-group" *ngIf="selectedUser?.length">
                <div class="ui-g-12 mt-3">
                  <label for="type">Selected users</label>
                  <ol>
                    <li *ngFor="let user of selectedUser" class="padding-10px">
                      {{ user?.userFullName }}
                      <i
                        class="fa fa-trash trash-icon-font-size"
                        (click)="removeSelectedUser(user)"
                      ></i>
                    </li>
                  </ol>
                </div>
              </div>

              <div class="mt-3 active-checkbox" *ngIf="type === 'Users'">
                <label>Active</label>
                <p-checkbox
                  name="active"
                  ngDefaultControl
                  formControlName="active"
                  class="map-checkbox text-allign-btns"
                  binary="false"
                ></p-checkbox>
              </div>

              <div
                class="mt-3 active-checkbox"
                *ngIf="type === 'Administrator'"
              >
                <label>Report To</label>
                <p-checkbox
                  name="active"
                  ngDefaultControl
                  class="map-checkbox text-allign-btns"
                  binary="false"
                  (onChange)="adminCheckBox($event, 'reportTo')"
                ></p-checkbox>
              </div>
              <div
                class="mt-3 active-checkbox"
                *ngIf="type === 'Administrator'"
              >
                <label>Sign off</label>
                <p-checkbox
                  name="active"
                  ngDefaultControl
                  class="map-checkbox text-allign-btns"
                  binary="false"
                  (onChange)="adminCheckBox($event, 'signoff')"
                ></p-checkbox>
              </div>

              <div class="row form-group">
                <div class="ui-g-12 mt-3">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="saveUsersPermissions()"
                    [disabled]="!selectedUser?.length"
                  >
                    Save
                    <!-- RELOAD -->
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
