import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DragulaService } from "ng2-dragula";
import { FormQuestionsService } from "src/app/demo/form-questions/form-questions.service";
import { JobService } from "src/app/demo/job/job-data.service";
import * as _ from "underscore";
import { FormBuildersService } from "../../form-builders.service";
import { Dropdown } from "primeng/dropdown";

@Component({
  selector: "app-add-edit-view-user-access-form-builder",
  templateUrl: "./add-edit-view-user-access-form-builder.component.html",
  styleUrls: ["./add-edit-view-user-access-form-builder.component.scss"],
})
export class AddEditViewUserAccessFormBuilderComponent
  implements OnInit, AfterViewInit
{
  usersForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  isUpdate = false;
  slide = true;
  clickOpen = false;
  isEdit = false;
  errorMessage = false;
  isAcumen: any;
  companyID: any;
  companyList = [{ label: "Please Select", value: "" }];

  questionTypeList = [
    { label: "Checkbox", value: "checkbox" },
    { label: "File", value: "image_file" },
    { label: "Image", value: "image" },
    { label: "Location", value: "location" },
    // { label: "Image/File", value: "image_file" },
    { label: "Radio", value: "radio" },
    { label: "Text", value: "text" },
    { label: "Text Area", value: "text_area" },
  ];

  data: any = [];
  @Input("data") viewData: any = {};
  @Input("type") type: any;
  @Input("formData") formData: any;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  @ViewChild("userDropDown") userDropDown?: Dropdown;

  selectedUser: any = [];
  reportToValue = false;
  signOffValue = false;
  constructor(
    private messageService: MessageService,
    private formBuilderService: FormBuildersService,
    private cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _ds: DragulaService,
    private fb: FormBuilder,
    private jobService: JobService
  ) {
    this.usersForm = new FormGroup({
      user: new FormArray([], Validators.required),
      active: new FormControl(true, Validators.required),
    });
  }

  onChange(event) {
    this.errorMessage = false;
    this.cdr.detectChanges();
  }
  editEvent(event) {
    this.isEdit = true;
    this.isUpdate = true;
  }

  ngOnInit() {
    console.log(this.viewData);
    console.log(this.type);
    // this.viewData.active = JSON.parse(this.viewData.active);
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");
  }
  ngOnDestroy() {
    this._ds.destroy("question-items");
  }

  onUserSelect(event) {
    console.log(event);
    // let userExistsInArray = false;
    // this.selectedUser.map((item) => {
    //   if (item._id === event.value._id) {
    //     return (userExistsInArray = true);
    //   }
    //   return (userExistsInArray = false);
    // });
    this.selectedUser = this.selectedUser.filter(
      (item) => item && item._id !== event._id
    );
    // if (!userExistsInArray) {
    this.selectedUser.push({
      company: event?.value?.company,
      userFullName: event?.value?.userFullName,
      _id: event?.value?._id,
      employeeId: event?.value?.employeeId,
      email: event?.value?.email,
      active: true,
    });
    this.selectedUser = _.uniq(this.selectedUser);
    console.log(this.selectedUser);
    // }
    // console.log(this.selectedUser);
  }

  closeEvent(event) {
    this.closeEvents.emit(true);
  }

  removeSelectedUser(user) {
    this.selectedUser = this.selectedUser.filter(
      (item) => item && item._id !== user._id
    );
  }

  saveUsersPermissions() {
    if (this.type === "Users") {
      this.selectedUser.map((item) => {
        item.active = this.usersForm.value["active"];
        this.formData.staffUserPermission.push(item);
      });
      this.formData.staffUserPermission = _.uniq(
        this.formData.staffUserPermission,
        (user) => user._id
      );
    } else {
      this.selectedUser.map((item) => {
        item.reportTo = this.reportToValue;
        item.signOff = this.signOffValue;
        this.formData.adminUserPermission.push(item);
      });
      this.formData.adminUserPermission = _.uniq(
        this.formData.adminUserPermission,
        (user) => user._id
      );
    }
    let payLoad = { ...this.formData };
    payLoad.updatedBy =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
    payLoad.updatedDate = new Date();
    console.log(payLoad);
    this.formBuilderService
      .editFormSection(payLoad, this.formData._id)
      .subscribe((resp: any) => {
        // if (this.type === "Administroator") {
        //   this.addAdminPermissionsToSubmittedForm(payLoad);
        // }
        try {
          if (resp.status == 200) {
            this.messageService.add({
              severity: "success",
              summary: "Form Updated",
              detail: "New Question created successfully",
            });
            this.ref.close(this);
            this.closeEvents.emit(true);
          }
        } catch (e) {
          console.warn(e);
        }
      });
  }

  adminCheckBox(event, type) {
    switch (type) {
      case "reportTo":
        this.reportToValue = event.checked;
        break;
      case "signoff":
        this.signOffValue = event.checked;
        break;
    }
  }

  ngAfterViewInit(): void {
    if (this.userDropDown) {
      (this.userDropDown.filterBy as any) = {
        split: (_: any) => [(item: any) => item.userFullName],
      };
    }
  }

  // addAdminPermissionsToSubmittedForm(payload) {
  //   const body = {
  //     formId: this.formData._id,
  //     adminUserPermission: payload.adminUserPermission,
  //   };
  //   this.formBuilderService.addAdminPermissionsToSubmittedForm(body).subscribe(
  //     (response) => {
  //       console.log(response);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }
}
