<div id="styleSelector"  class="style-block job-container  open {{slide?'animate-block':'slide-out'}}">

<div class="container h-100">
        <div class="row align-items-center " *ngIf="!selectSubCategory">
          <div class="col-10 mx-auto">
            <form [formGroup]="parentForm">
            <div class='row add-edit-header'  >  
                    <div class="ui-g-10">
                        <h4 >Add Parent Waste Type</h4>
                    </div> 
                    <div class="ui-g-2" (click)="closeEvent($event)" pTooltip="Close" tooltipPosition="top">
                        <i class="fa fa-window-close fa-2x "></i>
                        </div>    
                            </div> 
              <div class='row form-group'>
                <div class='ui-g-12  mt-3'>
                  <label for="icon"> Parent Waste Type</label>
                  <p-dropdown class='ui-g-12 form-control' [options]="parentWasteTypeList" [style]="{'width':'100%'}" formControlName='linked_waste_category'></p-dropdown>
                </div>
              </div>
              <div class='row button-cls' *ngIf='!(isView || isUpdate)'>
                  <div class='ui-g-12'>
                    <p-button label="Save"  [disabled]='!parentForm.valid' (onClick)="createParentWasteType($event)"></p-button>
        
                  </div>
                </div>
              </form>
            
          
          </div>
        </div>
        <ng-container *ngIf="selectSubCategory||isUpdate && subCategoryList.length >0">

        <div class="row align-items-center " >
                <div class="col-10 mx-auto">
               <form>   
                <div class='row add-edit-header'  >  
                        <div class="ui-g-10">
                            <h4 >{{title}}</h4>
                        </div> 
                        <div class="ui-g-2" (click)="closeEvent($event)">
                            <i class="fa fa-window-close fa-2x "></i>
                            </div>    
                                </div> 
               <div class=" checkbox-container" *ngFor="let item of subCategoryList; let i=index" >
                <input id="{{item._id}}" type="checkbox" class="checkbox" value="{{item.ewc_code}}"  [(ngModel)]="item.checked" (change)="onChange($event,item)" [ngModelOptions]="{standalone: true}"/>
                <label for="{{item._id}}" class="checkboxLabel">{{item.code_description}}</label>
                <ng-container *ngIf="item.checked">
                  <div class="sub-sub-category" *ngFor="let j of item.waste_sub_sub_details">
                      <input id="{{j._id}}" type="checkbox"  class="checkbox" value="{{j.ewc_code}}"  [(ngModel)]="j.checked" [ngModelOptions]="{standalone: true}"/>
                      <label for="{{j._id}}" class="checkboxLabel">{{j.code_description}}</label>
                    </div>
                </ng-container>
                 </div>
                
                        <div class='row button-cls button-bottom' *ngIf='!(isView || isUpdate)'>
                            <!-- <div class='ui-g-6'>
                              <p-button label="Cancel"  (onClick)="cancel($event)"></p-button>
                  
                            </div> -->
                            <div class='ui-g-12'>
                                <p-button label="Save"  (onClick)="updateSubCategory($event)"></p-button>
                    
                              </div>
                          </div>
                          <div class='row button-cls button-bottom' *ngIf='isUpdate'>
                              <!-- <div class='ui-g-6'>
                                  <p-button label="Cancel"  (onClick)="cancel($event)"></p-button>
                              </div> -->
                            <div class='ui-g-12'>
                              <p-button label="update"  (onClick)="updateSubCategory($event)"></p-button>
                            </div>
                  
                  </div>
                  </form>
                  </div>
                  
        </div>
        
      </ng-container>     
      </div>
      </div>