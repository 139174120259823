<div class="">
  <div
    id="styleSelector"
    class="style-block staff-container  open {{
      slide ? 'animate-block' : 'slide-out'
    }}"
  >
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="{{ isView ? 'col-12' : 'col-10' }} mx-auto user-form ">
          <div class="row add-edit-header" *ngIf="!isView">
            <div class="ui-g-10">
              <h4 *ngIf="!isUpdate">Add New Question</h4>
              <h4 *ngIf="isUpdate">Edit Question</h4>
            </div>
            <div
              class="ui-g-2"
              (click)="closeEvent($event)"
              pTooltip="Click to close a Admin"
              tooltipPosition="top"
            >
              <i class="fa fa-window-close fa-2x"></i>
            </div>
          </div>

          <div class="">
            <form [formGroup]="questionsForm" *ngIf="!isView">
              <div class="row form-group">
                <div class="ui-g-12 mt-3">
                  <label for="firstname">Question Name</label>
                  <input
                    class="ui-g-12"
                    id="firstname"
                    class="form-control"
                    type="text"
                    size="30"
                    formControlName="questionName"
                    pInputText
                  />
                </div>
              </div>
              <div class="row form-group">
                <div class="ui-g-12 mt-3">
                  <label for="lastname">Question Text</label>
                  <textarea
                    class="ui-g-12"
                    id="lastname"
                    type="texta"
                    class="form-control"
                    size="30"
                    formControlName="questionText"
                    pInputText
                  >
                  </textarea>
                </div>
              </div>
              <!-- <div class='col-12' style='color:red;font-size: 12px' *ngIf="questionsForm.get(''">
                  Employee ID
                </div> -->
              <div class="row form-group">
                <div class="ui-g-12 mt-3">
                  <label for="type">Question Type</label>
                  <!-- <input class='ui-g-12' id="email" type="text" class="form-control" size="30" formControlName='questionType'
                    pInputText> -->
                  <p-dropdown
                    class="ui-g-12 form-control"
                    [options]="questionTypeList"
                    [style]="{ width: '100%' }"
                    formControlName="questionType"
                    (onChange)="onQuestionTypeSelect($event)"
                  ></p-dropdown>
                </div>
              </div>
              <!-- <div class='row form-group' *ngIf='!isUpdate'> -->
              <div class="row form-group" *ngIf="hideAnswers()">
                <div class="ui-g-12 mt-3">
                  <div class="width-100">
                    <label for="answers">Question Answers</label>
                    <button
                      [disabled]="isReorderVisible"
                      type="button"
                      class="btn btn-primary mb-2"
                      (click)="createAnswer($event)"
                      pTooltip="Click to add an answer"
                      tooltipPosition="top"
                    >
                      <i class="feather icon-plus-circle"></i>ADD
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary mb-2"
                      (click)="onReorder()"
                      pTooltip="Click to add an answer"
                      tooltipPosition="top"
                    >
                      <i class="feather icon-move"></i>
                      {{ isReorderVisible ? "Done" : "Re-Order" }}
                    </button>
                  </div>

                  <div *ngIf="!isReorderVisible">
                    <ng-container
                      formArrayName="questionAnswers"
                      *ngFor="
                        let answer of questionsForm.get('questionAnswers')[
                          'controls'
                        ];
                        let i = index
                      "
                    >
                      <div
                        class="display-flex align-items-center"
                        [id]="i"
                        [formGroupName]="i"
                      >
                        <input
                          id="answers"
                          type="text"
                          class="ui-g-11 form-control"
                          (change)="onChange($event)"
                          autocomplete="new-password"
                          size="30"
                          formControlName="answer"
                          pInputText
                        />
                        <i
                          class="fa fa-trash fa-2x ui-g-1 bin-icon cursor-pointer"
                          (click)="deleteAns(i)"
                        ></i>
                      </div>
                    </ng-container>
                  </div>
                  <app-sort-drag-list
                    *ngIf="isReorderVisible"
                    (drop)="onDropQuestions($event)"
                    [_dataToShow]="getFormArray()"
                    [dataKeyNameForLabel]="'answer'"
                  ></app-sort-drag-list>
                </div>
              </div>

              <div class="mt-3 active-checkbox">
                <label>Active</label>
                <p-checkbox
                  name="active"
                  ngDefaultControl
                  formControlName="active"
                  class="map-checkbox text-allign-btns"
                  binary="false"
                ></p-checkbox>
              </div>

              <div class="row button-cls" *ngIf="isNew && !isView">
                <div class="ui-g-12">
                  <p-button
                    label="SAVE"
                    [disabled]="!questionsForm.valid"
                    (onClick)="createQuestion($event)"
                  ></p-button>
                </div>
              </div>

              <div class="row button-cls" *ngIf="!isView && !isNew">
                <div class="ui-g-12">
                  <p-button
                    label="UPDATE"
                    [disabled]="!questionsForm.valid"
                    (onClick)="updateQuestion($event)"
                  ></p-button>
                </div>
              </div>
            </form>

            <div *ngIf="isView">
              <div class="row details-header">
                <div class="ui-g-10">
                  <h4>Question Details</h4>
                </div>
                <!-- <div class="ui-g-1" (click)="editEvent($event)">
                  <i class="fa fa-edit fa-2x " pTooltip="Click to edit the Question" tooltipPosition="top"></i>
                </div> -->
                <div class="ui-g-1" (click)="closeEvent($event)">
                  <i
                    class="fa fa-window-close fa-2x"
                    pTooltip="Click to close the Question"
                    tooltipPosition="top"
                  ></i>
                </div>
              </div>
              <div class="details-content">
                <div class="row">
                  <div class="ui-g-4 pl-0">
                    <span>Question Name:</span>
                  </div>
                  <div class="ui-g-8">
                    <span>{{ viewData.itemName }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="ui-g-4 pl-0">
                    <span>Question Text:</span>
                  </div>
                  <div class="ui-g-8">
                    <span>{{ viewData.itemText }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="ui-g-4 pl-0">
                    <span>Question Type:</span>
                  </div>
                  <div class="ui-g-8">
                    <span>{{
                      viewData.itemData &&
                        viewData.itemData.length &&
                        viewData.itemData[0].itemInputType
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="ui-g-4 pl-0">
                    <span>Question Answers:</span>
                  </div>
                  <div
                    class="ui-g-8"
                    *ngIf="
                      viewData.itemData &&
                      viewData.itemData.length &&
                      viewData.itemData[0].itemData
                    "
                  >
                    <div *ngFor="let answer of viewData.itemData[0].itemData">
                      {{ answer.answer }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="ui-g-4 pl-0">
                    <span>Active:</span>
                  </div>
                  <div class="ui-g-8">
                    <span>
                      {{ viewData?.active }}
                      <!-- <p-checkbox name="active" [disabled]="true" ngDefaultControl [(ngModel)]="viewData.active" class="map-checkbox text-allign-btns" binary="false"></p-checkbox> -->
                    </span>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 edit-btn">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="editEvent($event)"
                  *ngIf="checkPermissions('edit', 'Form Question')"
                >
                  <i class="fa fa-edit fa-2x"></i>EDIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
