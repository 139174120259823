<div class="">
  <div
    id="styleSelector"
    class="style-block staff-container  open width-35-percent {{
      slide ? 'animate-block' : 'slide-out'
    }}"
  >
    <div class="container h-100">
      <div class="row align-items-center">
        <div class="col-12 mx-auto user-form ">
          <div class="row add-edit-header margin-top-10">
            <div class="ui-g-10">
              <h4 class="margin-left-35">Messages</h4>
            </div>
            <div
              class="ui-g-2"
              (click)="closeNotifications()"
              pTooltip="Click to close a Admin"
              tooltipPosition="top"
            >
              <i class="fa fa-window-close fa-2x"></i>
            </div>
          </div>

          <div class="row box" *ngFor="let item of data"  [ngClass]="!item?.read ? 'unread' : ''">
            <div class="col-10" (click)="openMessages(item)">
              {{'Message received from '+item?.senderName +' '+ (item?.date | date: "dd-MM-yyyy, h:mm a")}} <br>
              {{'Message: '+ item?.message}}
            </div>
            <div class="col-2" *ngIf="item?.read">
              <i class="fa fa-trash fa-2x font-size-20" (click)="deleteMessage(item)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
