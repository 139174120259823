import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormSectionsRoutingModule } from "./form-sections.component.routing.module";
import { SharedModule } from "../../theme/shared/shared.module";
import {
  NgbCarouselModule,
  NgbProgressbarModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { ToastModule } from "primeng/toast";
import { CalendarModule } from "primeng/calendar";
import { InputSwitchModule } from "primeng/inputswitch";
import { DropdownModule } from "primeng/dropdown";
import { CheckboxModule } from "primeng/checkbox";
import { TooltipModule } from "primeng/tooltip";
import { FormSectionsComponent } from "./form-sections.component";
import { AddEditViewFormSectionsComponent } from "./add-edit-view-form-sections/add-edit-view-form-sections.component";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { AutoCompleteModule } from "primeng/autocomplete";
import { FormQuestionsModule } from "../form-questions/form-questions.component.module";
import { SortDragListModule } from "../common/sort-drag-list/sort-drag-list.module";

@NgModule({
  declarations: [FormSectionsComponent, AddEditViewFormSectionsComponent],
  imports: [
    CommonModule,
    FormSectionsRoutingModule,
    SharedModule,
    TooltipModule,
    CheckboxModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    ToastModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MessageModule,
    MessagesModule,
    NgbCarouselModule,
    DropdownModule,
    CalendarModule,
    DragulaModule,
    AutoCompleteModule,
    FormQuestionsModule,
    SortDragListModule,
  ],
  providers: [DragulaService],
  exports: [AddEditViewFormSectionsComponent],
})
export class FormSectionsModule {}
