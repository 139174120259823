<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <!-- <img src="assets/images/auth/auth-logo.png" alt="" class="img-fluid"> -->
        <!-- <h1 class="text-white my-4">ACUMEN</h1> -->
        <h4 class="text-white font-weight-normal"></h4>
      </div>
    </div>
    <div class="auth-side-form">
      <div class="auth-content">
        <img
          src="../../assets/images/logo.jpg"
          alt=""
          class="img-fluid mb-4 d-block d-xl-none d-lg-none"
        />
        <h3 class="mb-4 f-w-400">Signin</h3>
        <form [formGroup]="loginForm">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fa-solid fa-envelope"></i></span>
            </div>
            <input
              type="email"
              class="form-control"
              formControlName="email"
              placeholder="Email address"
            />
          </div>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fa-solid fa-lock"></i></span>
            </div>
            <input
              [type]="isPasswordVisible ? 'text' : 'password'"
              class="form-control"
              formControlName="password"
              placeholder="Password"
            />
            <div
              (click)="togglePasswordVisibility()"
              class="input-group-prepend"
            >
              <span class="input-group-text-v2 eye-icon-section"
                ><i *ngIf="!isPasswordVisible" class="fa fa-eye-slash"></i>
                <i *ngIf="isPasswordVisible" class="fa fa-eye"></i>
              </span>
            </div>
          </div>
        </form>
        <div class="col-12" style="color: red; font-size: 12px">
          {{ errorMessage }}
        </div>
        <!-- <div class="form-group text-left mt-2">
          <div class="checkbox checkbox-primary d-inline">
            <input type="checkbox" name="checkbox-p-1" id="checkbox-p-1" checked="">
            <label for="checkbox-p-1" class="cr">Save credentials</label>
          </div>
        </div> -->
        <button
          class="btn btn-block btn-primary mb-0"
          [disabled]="!loginForm.valid"
          (click)="login()"
        >
          Signin
        </button>
        <div class="text-center">
          <div class="saprator my-4"><span></span></div>
          <!-- <button class="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-facebook-f"></i></button>
          <button class="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-google-plus-g"></i></button>
          <button class="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-twitter"></i></button> -->
          <p class="mb-2 text-muted">
            Forgot password?
            <a [routerLink]="['/auth/reset-password-v2']" class="f-w-400"
              >Reset</a
            >
          </p>
          <!-- <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/signup-v2']" class="f-w-400">Signup</a></p> -->
        </div>
      </div>
    </div>
  </div>
</div>
