import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { SearchFilterAddService } from './search-filter-add.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

enum ViewMode {
  Default = 'default',
  Selection = 'selection',
}

@Component({
  selector: 'app-search-filter-add',
  templateUrl: './search-filter-add.component.html',
  styleUrls: ['./search-filter-add.component.scss'],
})
export class SearchFilterAddComponent implements OnInit {
  @Input() boxData: any;
  @Input() dataMap: any;
  @Output() checkBoxDependenciesEmpty = new EventEmitter<{ [key: string]: any }>();
  @Output() disableBoxDependencies = new EventEmitter<string[]>();
  @Output() enableBoxDependencies = new EventEmitter<string[]>();
  @Output() fetchDependentLists = new EventEmitter<{ [key: string]: any }>();
  @Output() clearDependentLists = new EventEmitter<string[]>();
  @Output() resetListToClear = new EventEmitter<void>();
  @Input() disabled: boolean;
  @Input() toClear: string[];
  @Input() showResults: boolean;
  toClearBackup: string[] = [];
  id: string;
  inputRef: HTMLInputElement;
  dependsOnId: string;
  viewMode=ViewMode.Default;
  listItemsToRemoveBackup: [] = [];
  selectionMap: { [key: string]: any };
  resultsMap: { [key: string]: any } = {};

  

  constructor(
    private messagingService: SearchFilterAddService,
    ) {
  }

  ngOnInit(): void {
    this.initializeComponent();
    
  }

  initializeComponent() {
    this.id = this.boxData["id"];
    this.dependsOnId = this.boxData["depends_on"];
    
    this.selectionMap = {...this.dataMap["selection"][this.id]};
    this.viewMode = this.boxData["states"]["view_mode"];
    this.resetInput();
    if (this.boxData["fetch_immediately"] === true || this.checkIfShouldFetch()) {
      if (this.viewMode === ViewMode.Selection || this.checkIfShouldFetch()) {
        this.showAll();
      } else {
        this.initialFetch();
      }
    }
  }

  ngDoCheck() {
  }

  ngOnDestroy(): void {
    
  }

  ngOnChanges() {
    
  }

  

  checkIfShouldFetch() {
    const dependsOn = this.boxData["depends_on"];
    if (dependsOn.length === 0) {
      return false;
    }
    if (Object.keys(this.selectionMap).length === 0
    && Object.keys(this.dataMap["selection"][dependsOn[0]]).length > 0) {
      return true;
    }
    return false;
  }

  shallowCopy(obj: any): any {
  if (typeof obj === 'object' || obj === null) {
    return obj;
  }
  const copy: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = this.shallowCopy(obj[key]);
    }
  }
  return copy;
}


deepCopy(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Return primitive types and null directly
  }
  
  if (Array.isArray(obj)) {
    // If obj is an array, create a new array and copy its elements recursively
    const copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = this.deepCopy(obj[i]);
    }
    return copy;
  } else {
    // If obj is an object, create a new object and copy its properties recursively
    const copy = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        copy[key] = this.deepCopy(obj[key]);
      }
    }
    return copy;
  }
}

  inSelection(id: string) {
    if (!(id in this.selectionMap)) {
    }
    
    return id in this.selectionMap;
  }

  mapToList(map) {
    return Object.values(map);
  }

  toggleViewMode() {
    this.removeListItemsFromSelection();
    this.selectionMap = {...this.dataMap["selection"][this.id]};
    if (this.viewMode == ViewMode.Default) {
      if (this.inputRef !== undefined) {
        this.selectionInput();
      }
      this.viewMode = ViewMode.Selection
    } else {
      if (this.inputRef !== undefined) {

        const filter = this.getCurrentFilter();
        this.defaultInput(filter);
      } else {
        //this.resultsMap = {};
      }
      if (this.boxData["fetch_immediately"] === true) {
        this.showAll();
      }
      this.viewMode = ViewMode.Default
    }
    
  }

  removeListItemsFromSelection() {
    let listItemsToRemove = this.dataMap["listItemsToRemove"];
    Object.keys(listItemsToRemove).forEach((boxID) => {
      listItemsToRemove[boxID].forEach((id) => {
        delete this.dataMap["selection"][boxID][id];
      });
      listItemsToRemove[boxID] = [];
    });
    
  }

  


  toggleListItem(listItemId: string) {
    let listItemsToRemove = this.dataMap["listItemsToRemove"][this.id];
    if (listItemId in this.dataMap["selection"][this.id]) {
      if (listItemsToRemove.includes(listItemId)) {
        let index = listItemsToRemove.indexOf(listItemId);
        if (index !== -1) {
          listItemsToRemove.splice(index, 1);
          this.dataMap["selection"][this.id][listItemId] = this.resultsMap[listItemId];
          this.selectionMap[listItemId] = {...this.resultsMap[listItemId]};
        }
      } else {
        listItemsToRemove.push(listItemId);
      }
      if (Object.keys(this.dataMap["selection"][this.id]).length === listItemsToRemove.length) {
        console.log("YYYYYYYYYYYYYYYYYYYYYYYYYYYY");
        this.clickClearSelection();
      } else {
        const listitem = this.resultsMap[listItemId] != undefined ? this.resultsMap[listItemId] : this.selectionMap[listItemId]
        this.fetchDependentLists.emit(
          {
            "dependencies": this.boxData["dependencies"],
            "filter": {
              "filterBoxes": this.boxData["depends_on"],
              "items": [listitem]
            }
          }
        );
      }
      
      
    } else {
      this.dataMap["selection"][this.id][listItemId] = this.resultsMap[listItemId];
      this.selectionMap[listItemId] = {...this.resultsMap[listItemId]};
      this.fetchDependentLists.emit(
        {
          "dependencies": this.boxData["dependencies"],
          "filter": {
            "filterBoxes": this.boxData["depends_on"],
            "items": [this.resultsMap[listItemId]]
          }
        }
      );
    }
  }
  


  saveData() {
    this.dataMap["selection"][this.id] = {...this.selectionMap};
    localStorage.setItem("send-message", JSON.stringify(this.dataMap));
  }

  getImmediateFilter(dataMap: { [key: string]: any }, boxData: { [key: string]: any }) {
    if (boxData["depends_on"].length > 0) {
      const filter = boxData["depends_on"][0]
      if (Object.keys(dataMap["selection"][filter]).length > 0) {
        return {
          "matches": dataMap["selection"][filter],
          "filter": filter
        }
      }
    }
    return null;
  }

  findClosestPreviousFilter(dataMap: { [key: string]: any }, boxData: { [key: string]: any }) {
    for (const filter of boxData["depends_on"]) {
      if (Object.keys(dataMap["selection"][filter]).length > 0) {
        
        return {
          "matches": dataMap["selection"][filter],
          "filter": filter
        };
      }
    }
    return null;
  }

  getCurrentFilter() {
    let filter: any;
    if (!this.dataMap["selection"][this.id].keys || this.dataMap["selection"][this.id].keys.length === 0) {
      filter = this.findClosestPreviousFilter(this.dataMap, this.boxData);
    } else {
      filter = this.dataMap["selection"][this.id]
    }
    
    if (filter != null) {
      let returnVal: any = {};
      const filterArray = [];
      for (const key in filter["matches"]) {
        const filterValue = filter["matches"][key]["filter"];
        filterArray.push(filterValue);
      }
      returnVal[filter["filter"]] = filterArray;
      return returnVal;
    }
    
    return null;
  }

  getPreviousFilter() {
    //const filter = this.findClosestPreviousFilter(this.dataMap, this.boxData);
    const filter = this.getImmediateFilter(this.dataMap, this.boxData);
    if (filter != null) {
      let returnVal: any = {};
      const filterArray = [];
      for (const key in filter["matches"]) {
        const filterValue = filter["matches"][key]["filter"];
        filterArray.push(filterValue);
      }
      returnVal[filter["filter"]] = filterArray;
      return returnVal;
    }
    
    return null;
  }


  isSelectionEmpty() {
    if (this.viewMode === ViewMode.Selection) {
      return true;
    }
    for (let key in this.selectionMap) {
      if (this.resultsMap.hasOwnProperty(key)) {
        
        return Object.keys(this.dataMap["selection"][this.id]).length === this.dataMap["listItemsToRemove"][this.id].length || Object.keys(this.selectionMap).length === 0 || Object.keys(this.selectionMap).length === this.dataMap["listItemsToRemove"][this.id].length;
      }
    }
    return true;
  }

  disableSelect() {
    if (this.viewMode === ViewMode.Selection) {
      return true;
    }
    for (let key in this.resultsMap) {
      if (!(key in this.dataMap["selection"][this.id])) {
        return false;
      }
    }
    return true;
  }


  showAndSelectAll(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.removeListItemsFromSelection();
      const filter = this.getPreviousFilter();
      if (filter == null) {
        this.selectionMap = {};
        this.resultsMap = {};
        resolve(true);
      } else {
        this.messagingService.getAllItemsForFilter(null, this.boxData["path"], filter)
      .subscribe((data) => {
        const previousResults = this.resultsMap;
        
        const previousSelection = {...this.dataMap["selection"][this.id]};
        let previousFilters = this.flattenArrayRecursive(Object.values(filter)) as string[];
        previousFilters = previousFilters.filter(filter => Object.values(previousSelection).some(item => Object.values(item).includes(filter)));
        console.log(previousSelection, "sel");
        console.log(previousResults, "res");
        console.log(filter, "filter");
        console.log(Object.values(previousFilters), "prevFil");
        this.resultsMap = data;
        
        this.selectionMap = { ...this.resultsMap, ...this.selectionMap };
        for (const key in this.selectionMap) {
          if ((!previousSelection.hasOwnProperty(key) && previousResults.hasOwnProperty(key)) || !this.resultsMap.hasOwnProperty(key)) {
            delete this.selectionMap[key];
          }
        }
        for (const key in this.selectionMap) {
          if (!previousSelection.hasOwnProperty(key)) {
            if (previousFilters.some(filter => Object.values(this.selectionMap[key]).some(val => filter === val))) {
              delete this.selectionMap[key];
            }
          }
        }
        this.saveData();
        resolve(true);
      }, error => {
        this.selectionMap = {}
        this.resultsMap = {}
        this.saveData();
        reject(error);
      });
      }
      
      if (this.inputRef != null) {
        this.resetInput();
      }
    });
  }


  flattenArrayRecursive(arr) {
    return arr.reduce((acc, val) => {
        return Array.isArray(val) ? acc.concat(this.flattenArrayRecursive(val)) : acc.concat(val);
    }, []);
}

  clearResultAndSelection() {
    this.resultsMap = {};
    this.selectionMap = {};
    this.saveData();
  }

  showAll() {
    this.removeListItemsFromSelection();
    const filter = this.getCurrentFilter();
    this.messagingService.getAllItemsForFilter(null, this.boxData["path"], filter)
    .subscribe((data) => {
      this.resultsMap = data;
    });
    if (this.inputRef != null) {
      this.resetInput();
    }
  }

  

  initialFetch() {
    this.messagingService.getAllItemsForFilter(null, this.boxData["path"], null)
    .subscribe((data) => {
      this.resultsMap = data;
    });
    if (this.inputRef != null) {
      this.resetInput();
    }
  }

  async initialFetchAsync(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        this.messagingService.getAllItemsForFilter(null, this.boxData["path"], null)
        .subscribe((data) => {
          this.resultsMap = data;
          resolve(true);
        },
        (error) => {
          reject(error);
        });
        if (this.inputRef != null) {
          this.resetInput();
        }
      }
    )
  }
  

  clickSelectAll() {
    this.removeListItemsFromSelection();
    this.selectionMap = {...this.selectionMap, ...this.resultsMap};
    const filter = this.getCurrentFilter();
    if (this.inputRef != null) {
      this.defaultInput(filter);
    }
    this.saveData();
    
    this.fetchDependentLists.emit(
      {
        "dependencies": this.boxData["dependencies"],
        "filter": {
          "filterBoxes": this.boxData["depends_on"],
          "items": Object.values(this.resultsMap)
        }
      }
    );
    this.dataMap["lowestLevelSelection"] = this.id;
  }

  clickClearSelection() {
   this.removeListItemsFromSelection();
   for (let key in this.selectionMap) {
    if (key in this.resultsMap) {
      delete this.selectionMap[key]
    }
   }
   this.saveData();
   if (Object.keys(this.selectionMap).length === 0) {
    this.clearDependentLists.emit(this.boxData["dependencies"]);
   } else {
    this.fetchDependentLists.emit(
      {
        "dependencies": this.boxData["dependencies"],
        "filter": {
          "filterBoxes": this.boxData["depends_on"],
          "items": Object.values(this.selectionMap)
        }
      }
    );
   }
   this.viewMode = ViewMode.Default
  }


  onInput(event: Event) {
    this.removeListItemsFromSelection();
    this.selectionMap = { ...this.dataMap["selection"][this.id]};
    const filter = this.getCurrentFilter();
    if (this.inputRef === undefined) {
      this.inputRef = event.target as HTMLInputElement;
    }
    if (this.viewMode === ViewMode.Default) {
      this.defaultInput(filter);
    } else if (this.viewMode === ViewMode.Selection) {
      this.selectionInput();
    }
  }


  refetch(filter) {
    this.messagingService.getAllItemsForFilter(null, this.boxData["path"], filter)
    .subscribe((data) => {
      console.log(data);
      this.resultsMap = data;
    });
  }

  defaultInput(filter) {
    if (this.inputRef.value.length < 3) {
      if (filter != null || this.id === "companies") {
        console.log(filter);
        this.refetch(filter);
      } else if (filter == null) {
        this.resultsMap = {};
      }
      return;
    }
    this.messagingService.getAllItemsForFilter(this.inputRef.value, this.boxData["path"], filter)
    .pipe(
      catchError(error => {
        if (error.status === 404) {
          this.resultsMap = {};
          return new Observable();
        } else {
          return throwError(error);
        }
      })
    )
    .subscribe((data) => {
      
      this.resultsMap = data;
    });
  }

  selectionInput() {
    if (this.inputRef.value.length === 0) {
      let tempMap: { [key: string]: any } = {};
      for (let key in this.dataMap["selection"][this.id]) {
        delete this.dataMap["selection"][this.id][key]["substringPositions"];
      }
      this.selectionMap = {...this.dataMap["selection"][this.id]};
      return;
    }
    let tempMap: { [key: string]: any } = {};
    for (let key in this.dataMap["selection"][this.id]) {
      if (this.dataMap["selection"][this.id][key]["display_value"].toLowerCase().includes(this.inputRef.value.toLowerCase())) {
        tempMap[key] = this.shallowCopy(this.dataMap["selection"][this.id][key]);
        tempMap[key]["substringPositions"] = {"highlight": this.findSubstringIndices(tempMap[key]["display_value"].toLowerCase(), this.inputRef.value.toLowerCase())};
      }
    }
    this.selectionMap = {...tempMap};
  }


  findSubstringIndices(mainString, substring) {
    const firstIndex = mainString.indexOf(substring);
    const lastIndex = firstIndex + substring.length - 1;
    
    return {
        start: firstIndex,
        end: lastIndex
    };
  }


  resetInput() {
    if (this.inputRef != null) {
      this.inputRef.value = "";
    }
    for (let key in this.resultsMap) {
      delete this.resultsMap[key]["substringPositions"];
    }
    for (let key in this.selectionMap) {
      delete this.selectionMap[key]["substringPositions"];
    }
  }
}


 
