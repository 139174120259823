import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Injectable()
export class CustomerDivisionsService {
    constructor(private http: HttpClient) { }

    departmentList() {
        return this.http.get(environment.apiURL + 'department/list').pipe(
            tap((response: any) => {
                return response;
            })
        );

    }
    companyList() {
        return this.http.get(environment.apiURL + 'company/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    getDepartmentDetails(id) {
        return this.http.get(environment.apiURL + 'department/' + id).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    createDepartment(data) {
        return this.http.post(environment.apiURL + 'department/create', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    updateDepartment(data) {
        return this.http.post(environment.apiURL + 'department/update', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    fetchType() {
        return this.http.get(environment.apiURL + 'types/list').pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    createType(data) {
        return this.http.post(environment.apiURL + 'types/create', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    updateType(data) {
        return this.http.post(environment.apiURL + 'types/update', data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
    deleteType(id) {
        return this.http.delete(environment.apiURL + 'department/delete/' + id).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
}
