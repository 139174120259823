import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Injectable()
export class SkipService {
    constructor(private http: HttpClient) { }

    fetchSkip() {
        return this.http.post(`${environment.stitchApiURL}skip/incoming_webhook/fetchSkip?isAcumen=true`, {})
    }

    updateSkip(data) {
        return this.http.post(`${environment.stitchApiURL}skip/incoming_webhook/updateSkip?isAcumen=true&id=${data._id}`, data).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }
}
