<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card moduleName="Waste Category" module="Waste category" (addVehicle)="createWasteCategory($event)"
      cardClass="table-card" blockClass="p-0">
      <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->

      <p-table #dt [value]="wasteCategoryList" (onPage)="onPageEvent($event)" [(selection)]="selectedCategory"
        dataKey="_id" styleClass="ui-table-customers waste-category" [rowHover]="true" [rows]="10"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
        [globalFilterFields]="searchArr">
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>

            <th></th>
            <th *ngIf="checkPermissions('edit', 'Waste Category')"></th>

            <!-- <th>View</th>
                        <th>Delete</th> -->
            <th></th>
          </tr>
          <tr>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
              <input pInputText type="text" class="ui-inputtext"
                (input)="dt.filter($event.target.value, col.field, 'contains')" />
            </th>
            <th></th>
            <th></th>
            <th *ngIf="checkPermissions('edit', 'Waste Category')"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of cols" (click)="viewWasteCategory($event, car)">
              {{ car[col.field] }}
            </td>
            <td class="Delete-btn editDeleteBtns">
              <span (click)="listCategory($event, car)" class="mob_icon style-toggler">
                <a href="javascript:void(0);"> EWC Category </a>
              </span>
              <span (click)="listCustomer($event, car)" class="mob_icon style-toggler delete_icon">
                <a href="javascript:void(0);">Customer</a>
              </span>
              <span (click)="alertText($event, car)" class="mob_icon style-toggler delete_icon">
                <a href="javascript:void(0);">Alert Text</a>
              </span>
            </td>
            <td class="Delete-btn editDeleteBtns" *ngIf="checkPermissions('edit', 'Waste Category')">
              <span>
                <p-checkbox name="metal_slider" ngDefaultControl [(ngModel)]="car.metal_slider" class="map-checkbox"
                  (onChange)="change($event, car)" label="Metal slider" binary="false"></p-checkbox>
                <p-checkbox name="active" ngDefaultControl [(ngModel)]="car.active"
                  class="map-checkbox text-allign-btns" (onChange)="change($event, car)" label="Active"
                  binary="false"></p-checkbox>
              </span>
            </td>
            <td>
              <span (click)="viewWasteCategory($event, car)" class="mob_icon style-toggler"
                pTooltip="Click to view a Waste category" tooltipPosition="top">
                <i class="fa fa-eye fa-2x"></i>
              </span>
              <span (click)="editWasteCategory($event, car)" class="mob_icon style-toggler delete_icon"
                pTooltip="Click to edit a Waste category" tooltipPosition="top"
                *ngIf="checkPermissions('edit', 'Waste Category')">
                <i class="fa fa-edit fa-2x"></i>
              </span>
              <span (click)="deleteWasteCategory(car._id)" class="style-toggler mob_icon delete_icon"
                pTooltip="Click to delete a Waste category" tooltipPosition="top"
                *ngIf="checkPermissions('delete', 'Waste Category')">
                <i class="fa fa-trash fa-2x"></i>
              </span>
              <!-- <span *ngIf="!car.delete">
                              -
                            </span> -->
            </td>
          </tr>
        </ng-template>
        <ng-template class="noRecords" *ngIf="wasteCategoryList.length == 0" pTemplate="emptymessage">
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length + 3">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>
    </app-card>
  </div>
  <!-- tabs card end -->
</div>

<div *ngIf="showAdd">
  <app-add-edit-waste-category [isView]="isView" [data]="vehicleTypeData"
    (closeEvents)="callList($event)"></app-add-edit-waste-category>
</div>
<div *ngIf="showAlertText">
  <app-alert-text [isView]="isView" [data]="viewData" (closeEvents)="closeView($event)"></app-alert-text>
</div>
<div *ngIf="showLink">
  <app-link-wastecategory-customer [data]="linkData" (closeEvents)="closeAll($event)"></app-link-wastecategory-customer>
</div>