import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormQuestionsRoutingModule } from "./form-questions.component.routing.module";
import { SharedModule } from "../../theme/shared/shared.module";
import {
  NgbCarouselModule,
  NgbProgressbarModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { ToastModule } from "primeng/toast";
import { CalendarModule } from "primeng/calendar";
import { InputSwitchModule } from "primeng/inputswitch";
import { DropdownModule } from "primeng/dropdown";
import { CheckboxModule } from "primeng/checkbox";
import { TooltipModule } from "primeng/tooltip";
import { FormQuestionsComponent } from "./form-questions.component";
import { AddEditViewFormQuestionsComponent } from "./add-edit-view-form-questions/add-edit-view-form-questions.component";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SortDragListModule } from "../common/sort-drag-list/sort-drag-list.module";

@NgModule({
  declarations: [FormQuestionsComponent, AddEditViewFormQuestionsComponent],
  imports: [
    CommonModule,
    FormQuestionsRoutingModule,
    SharedModule,
    TooltipModule,
    CheckboxModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    ToastModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MessageModule,
    MessagesModule,
    NgbCarouselModule,
    DropdownModule,
    CalendarModule,
    DragulaModule,
    DragDropModule,
    SortDragListModule,
  ],
  providers: [DragulaService],
  exports: [AddEditViewFormQuestionsComponent],
})
export class FormQuestionsModule {}
