import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormBuildersRoutingModule } from "./form-builders.component.routing.module";
import { SharedModule } from "../../theme/shared/shared.module";
import {
  NgbCarouselModule,
  NgbProgressbarModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { ToastModule } from "primeng/toast";
import { CalendarModule } from "primeng/calendar";
import { InputSwitchModule } from "primeng/inputswitch";
import { DropdownModule } from "primeng/dropdown";
import { CheckboxModule } from "primeng/checkbox";
import { TooltipModule } from "primeng/tooltip";
import { FormBuildersComponent } from "./form-builders.component";
import { AddEditViewFormBuildersComponent } from "./add-edit-view-form-builders/add-edit-view-form-builders.component";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { DialogModule } from "primeng/dialog";
import { FormQuestionsModule } from "../form-questions/form-questions.component.module";
import { FormSectionsModule } from "../form-sections/form-sections.component.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SortDragListModule } from "../common/sort-drag-list/sort-drag-list.module";

@NgModule({
  declarations: [FormBuildersComponent, AddEditViewFormBuildersComponent],
  imports: [
    DialogModule,
    CommonModule,
    FormBuildersRoutingModule,
    SharedModule,
    TooltipModule,
    CheckboxModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    ToastModule,
    TableModule,
    InputSwitchModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MessageModule,
    MessagesModule,
    NgbCarouselModule,
    DropdownModule,
    CalendarModule,
    DragulaModule,
    FormQuestionsModule,
    FormSectionsModule,
    DragDropModule,
    SortDragListModule,
  ],
  providers: [DragulaService],
  exports: [AddEditViewFormBuildersComponent],
})
export class FormBuildersModule {}
