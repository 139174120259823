import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { SendEditMessageService } from './send-edit-message.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SearchFilterAddComponent } from 'src/app/shared-components/search-filter-add/search-filter-add.component';
import { MessagingService } from '../messaging.service';


enum MessageStatus {
  New = 'new',
  Draft = 'draft',
  Scheduled = 'scheduled'
}

enum ViewMode {
  Default = 'default',
  Selection = 'selection',
}

@Component({
  selector: 'app-send-edit-message',
  templateUrl: './send-edit-message.component.html',
  styleUrls: ['./send-edit-message.component.scss']
})
export class SendEditMessageComponent implements OnInit {
  @ViewChildren('boxes', {}) searchFilterAddComponents: QueryList<SearchFilterAddComponent>
  @Input() messageStatus: MessageStatus;
  @Input() editorData: { [key: string]: any };
  @Input() messageId: string;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  @Output("openEvents") openEvents = new EventEmitter<any>();
  dataMap: { [key: string]: any } = {};
  showLoader = false;
  messageForm: FormGroup;
  showSchedule: boolean = false;
  scheduleSet: boolean = false;
  scheduleMode: boolean = false;
  scheduleDate: Date = new Date();
  boxesToClear: [] = [];
  messageData: { [key: string]: any } = {
    "status": "sent"
  };

  
  boxObjects = [
    {
      "id": "companies",
      "title": "Companies",
      "depends_on": [],
      "dependencies": ["departments", "users"],
      "path": "company/",
      "states": {
        "disabled": false,
        "view_mode": ViewMode.Default
      },
      "fetch_immediately": true
    },
    {
      "id": "departments",
      "title": "Departments",
      "depends_on": ["companies"],
      "dependencies": ["users"],
      "path": "department/",
      "states": {
        "disabled": false,
        "view_mode": ViewMode.Default
      },
      "fetch_immediately": false
    },
    {
      "id": "users",
      "title": "Users",
      "depends_on": ["departments", "companies"],
      "dependencies": [],
      "path": "user/",
      "states": {
        "disabled": false,
        "view_mode": ViewMode.Default
      },
      "fetch_immediately": false
    },
  ]
  
  constructor(
    private messagingService: MessagingService,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService
    ) { 
    this.messageForm = new FormGroup({
      message: new FormControl("", [Validators.required]),
    });
    
  }

  ngOnInit(): void {
    this.messageForm = new FormGroup({
      message: new FormControl("", [Validators.required]),
    });
    this.initializeComponent();
    
    
  }

  initializeComponent() {
    if (localStorage.getItem("send-message") != null) {
      this.dataMap =JSON.parse(localStorage.getItem("send-message"));
      console.log(this.dataMap);
      this.loadDataMap();
    } else {
      this.dataMap = {
        "messageData": {
          "textData": ""
        },
        "selection": {
        },
        "listItemsToRemove": {
        }
      };
      this.boxObjects.forEach((box) => {
        this.dataMap["selection"][box.id] = {};
        this.dataMap["listItemsToRemove"][box.id] = [];
      });
      this.dataMap["lowestLevelSelection"] = this.boxObjects[0]["id"];
      this.dataMap["toSend"] = this.boxObjects[this.boxObjects.length - 1]["id"];
      this.dataMap["boxObjects"] = this.boxObjects;
      this.saveDataMap();
      if (this.messageId !== undefined) {
        this.getMessageDetailsOnUpdate();
      }
    }
  }

  loadDataMap() {
    this.boxObjects = this.dataMap["boxObjects"];
    this.scheduleDate = new Date(this.dataMap["scheduleDate"]);
    this.scheduleMode = this.dataMap["scheduleMode"];
    this.scheduleSet = this.dataMap["scheduleSet"];
    this.messageForm.get("message").setValue(this.dataMap["messageData"]["textData"]);
    this.messageData["date_of_dispatch"] = this.dataMap["messageData"]["date_of_dispatch"];
    this.messageData["status"] = this.dataMap["messageData"]["status"];
    this.messageData["editor_data"] = this.dataMap["messageData"]["editor_data"];
    this.messageStatus = this.dataMap["messageStatus"];
    this.removeListItemsFromSelection();
    this.boxObjects.forEach((box) => {
      if (Object.keys(this.dataMap["selection"][box.id]).length > 0) {
        Object.keys(this.dataMap["selection"][box.id]).length
        box["states"]["view_mode"] = ViewMode.Selection;
        box["fetch_immediately"] = true;
      }
      
    });
    
  }

  saveDataMap() {
    this.dataMap["scheduleDate"] = this.scheduleDate;
    this.dataMap["scheduleMode"] = this.scheduleMode;
    this.dataMap["scheduleSet"] = this.scheduleSet;
    this.dataMap["messageData"] = this.messageData;
    this.dataMap["messageData"]["textData"] = this.messageForm.get("message").value;
    this.dataMap["messageStatus"] = this.messageStatus;
    localStorage.setItem("send-message", JSON.stringify(this.dataMap));
  }

  clearDataMap() {
    this.boxObjects.forEach((box) => {
      this.dataMap["selection"][box.id] = {};
      this.dataMap["listItemsToRemove"][box.id] = [];
      box["states"]["disabled"] = false;
      box["states"]["view_mode"] = ViewMode.Default;
      this.messageForm.get("message").setValue('');
    });
    localStorage.removeItem("send-message");
  }

  getDataMap() {
    return JSON.parse(localStorage.getItem("send-message"))
  }


  getMessageDetailsOnUpdate() {
    this.showLoader=true;
    this.messagingService.getMessageDetails(this.messageId)
      .subscribe(
        (data) => {
          if (data.editor_data != null) {
            this.dataMap["selection"] = data.editor_data["selection"];
            this.boxObjects.forEach((box) => {
              if (Object.keys(this.dataMap["selection"][box.id]).length > 0) {
                box["states"]["view_mode"] = ViewMode.Selection;
                box["fetch_immediately"] = true;
              }
            });
          }
          this.messageForm.get("message").setValue(data["message_data"]["text_data"]);
          this.messageStatus = data.status;
          if (this.messageStatus === MessageStatus.Scheduled) {
            this.scheduleMode = true;
            this.scheduleSet = true;
            this.scheduleDate = new Date(data.date_of_dispatch);
            this.messageData["date_of_dispatch"] = this.scheduleDate;
            this.messageData["status"] = "scheduled";
            this.messageData["editor_data"] = {"selection": this.dataMap["selection"]};
          }
          this.showLoader = false;
          this.saveDataMap();
        }
      )
  }


  dataLoaded() {
    return (!this.showLoader || this.messageId === undefined);
  }

  getUserId() {
    return JSON.parse(localStorage.getItem("userData"))._id;
  }
  
  isDraft() {
    return (this.messageStatus === MessageStatus.Draft);
  }

  isScheduled() {
    return (this.messageStatus === MessageStatus.Scheduled);
  }

  isUpdate() {
    return this.isScheduled() || this.isDraft();
  }

  isNew() {
    return (this.messageStatus === MessageStatus.New.valueOf())
  }


  removeListItemsFromSelection() {
    let listItemsToRemove = this.dataMap["listItemsToRemove"];
    Object.keys(listItemsToRemove).forEach((boxID) => {
      listItemsToRemove[boxID].forEach((id) => {
        delete this.dataMap["selection"][boxID][id];
      });
      listItemsToRemove[boxID] = [];
    });
  }


  async fetchDependencies(config) {
    console.log(config)
    if (config.hasOwnProperty("filter") && config["filter"] != null) {
      const items = config["filter"]["items"] as any[];
      const filterBoxes = config["filter"]["filterBoxes"];
      if (filterBoxes != null && items != null) {
        for (let box of this.searchFilterAddComponents) {
          if (filterBoxes.includes(box.id)) {
            let idField;
            if (box.id === "companies") {
              idField = "companyId";
            } else if (box.id === "departments") {
              idField = "deptId";
            }
            if (Object.keys(box.resultsMap).length === 0) {
              await box.initialFetchAsync();
            }
            console.log(items);
            console.log(box.resultsMap);
            const filtersToAdd = Object.values(box.resultsMap).filter(box => items.some(item => item[idField] === box["filter"]));
            for (let filterToAdd of filtersToAdd) {
              if (!box.selectionMap.hasOwnProperty(filterToAdd["id"])) {
                box.selectionMap[filterToAdd["id"]] = filterToAdd;
              }
              box.dataMap["selection"][box.id][filterToAdd["id"]] = filterToAdd;
              
            }
            const dataMapCopy = box.deepCopy(box.dataMap);
            dataMapCopy["selection"][box.id] = box.deepCopy(box.selectionMap);
            localStorage.setItem("send-message", JSON.stringify(dataMapCopy));

          }
        }
      }
    }
    const dependentBoxes = config["dependencies"]
    for (let box of this.searchFilterAddComponents) {
      if (dependentBoxes.includes(box.id)) {
        let result = await box.showAndSelectAll();
       } else {
        const dataMapCopy = box.deepCopy(box.dataMap);
        dataMapCopy["selection"][box.id] = box.deepCopy(box.selectionMap);
        localStorage.setItem("send-message", JSON.stringify(dataMapCopy));

       }
    }
  }

  removeListItems(dataMap) {
    let listItemsToRemove = dataMap["listItemsToRemove"];
    Object.keys(listItemsToRemove).forEach((boxID) => {
      listItemsToRemove[boxID].forEach((id) => {
        delete dataMap["selection"][boxID][id];
      });
      listItemsToRemove[boxID] = [];
    });
  }

  clearDependencies(boxes) {
    this.searchFilterAddComponents.forEach((box) => {
      if (boxes.includes(box.id)) {
        box.clearResultAndSelection();
      }
    })
  }

  checkIfSelectionIsEmpty(boxes) {
    for (let i = boxes.length - 1; i >= 0; i--) {
      const currentFilter = this.searchFilterAddComponents.find(box => box.id === boxes[i]);
      if (Object.keys(currentFilter.selectionMap).length === 0) {
        return false;
      }
    }
    return true;
  }

  resetBoxes() {
    this.searchFilterAddComponents.forEach((box) => {
      box.resultsMap = {};
      box.selectionMap = {};
      box.dataMap["selection"] = {};
      box.dataMap["listItems"];
      if (box.boxData["id"] !== "companies") {
        box.boxData["fetch_immediately"] = false
      }
      if (box.boxData["fetch_immediately"] === true) {
        box.initialFetch();
        
      }
      box.viewMode = ViewMode.Default;
      box.resetInput();
      
    })
    localStorage.removeItem("send-message");
    this.initializeComponent();
  }


  createNewMessage(event) {
    let toastMessage: string;
    if (this.scheduleSet === true) {
      toastMessage = `Message scheduled to be sent at ${this.formatDate(this.scheduleDate)}`;
    } else {
      toastMessage = "Message sent"
    }
    this.saveMessage(toastMessage);
  }

  saveDraft(event) {
    this.messageData["status"] = "draft";
    this.messageData["editor_data"] = {"selection": this.dataMap["selection"]};
    const toastMessage = "Draft saved"
    this.saveMessage(toastMessage);
  }

  updateToDraft(event) {
    this.messageData["status"] = "draft";
    this.messageData["editor_data"] = {"selection": this.dataMap["selection"]};
    this.updateMessage(event);
  }

  updateMessage(event) {
    let message: string = "Message Sent";
    if (this.messageData["status"] === "draft") {
      message = "Draft Saved"
    } else if (this.scheduleSet === true) {
      message = `Message scheduled to be sent at ${this.formatDate(this.scheduleDate)}`;
    }
    this.messageData["message_data"] = {
      "text_data": this.messageForm.get("message").value
    }
    this.removeListItemsFromSelection();
    this.messageData["recipients"] = Object.keys(this.dataMap["selection"]["users"]);
    if (this.messageData["recipients"].length === 0) {
      this.messageService.add({
        severity: "error",
        summary: "You have not selected any recipients",
        detail: "Please select a company"
      });
      return;
    }
    this.messageData["id"] = this.messageId;
    this.messagingService.updateMessage(this.messageData)
    .subscribe(
      (response) => {
        if (response) {
          delete this.messageData["editor_data"];
          this.messageData["status"] = "sent";
          // this.clearDataMap();
          this.closePopup();
          this.messageService.add({
            severity: "success",
            summary: `${message}`,
          });
          this.getMessageDetailsOnUpdate();
        }
      },
      (error) => {
        console.log(error);
        if (error.error["detail"] === "You can't update a message that has already been sent.") {
          this.closePopup();
          this.messageService.add({
            severity: "error",
            summary: "Message has already been sent",
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Message failed",
          });
        }
      }
    )
  }


  saveMessage(message: string) {
    this.messageData["sender_id"] = this.getUserId();
    //this.messageData["sender_id"] = "5c125c70b99a9e2668f48034";
    this.messageData["message_data"] = {
      "text_data": this.messageForm.get("message").value
    }
    this.removeListItemsFromSelection();
    this.messageData["recipients"] = Object.keys(this.dataMap["selection"]["users"]);
    if (this.messageData["recipients"].length === 0) {
      this.messageService.add({
        severity: "error",
        summary: "You have not selected any recipients",
        detail: "Please select a company"
      });
      return;
    }
    this.messagingService.createNewMessage(this.messageData)
    .subscribe(
      (response) => {
        if (response) {
          delete this.messageData["editor_data"];
          this.messageData["status"] = "sent";
          // this.clearDataMap();
          this.closePopup();
          this.messageService.add({
            severity: "success",
            summary: `${message}`,
          });
          console.log(response);
          
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Message failed",
            detail: response.detail,
          });
          console.log(response);
        }
        
      },
      (error) => {
        console.log(error);
      }
    )
  }

  unSetScheduleDate() {
    this.scheduleSet = false;
    this.scheduleDate = new Date();
    delete this.messageData["date_of_dispatch"];
    this.messageData["status"] = "sent";
    delete this.messageData["editor_data"];
    this.saveDataMap();
    
  }
  
  setScheduleDate(date) {
    this.scheduleSet = true;
    this.scheduleDate = new Date(date);
    this.messageData["date_of_dispatch"] = date;
    this.messageData["status"] = "scheduled";
    this.messageData["editor_data"] = {"selection": this.dataMap["selection"]};
    this.saveDataMap();
    
  }
  
  deleteMessage(event) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete this Message?",
      accept: () => {
        this.messagingService.deleteMessage(this.messageId)
        .subscribe(
          (response) => {
            if (response.message == "success") {
              this.messageService.add({
                severity: "success",
                summary: "Message Deleted",
                detail: "Message deleted successfully",
              });
              this.closePopup(); 
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Message deletion failed",
                detail: response.detail,
              });
            }
                      
            
          },
          (error) => {
            console.log(error);
            this.messageService.add({
              severity: "error",
              summary: "Message Deleted",
              detail: "Failed to delete the Message",
            });
            this.closePopup();          }
        )
      },
      reject: () => {
        
      }
    })
    
  }
  schedule(event) {
    this.showSchedule = true;
  }

  toggleScheduleMode() {
    if (this.scheduleMode == true) {
      this.scheduleMode = false;
      if (this.messageStatus === MessageStatus.Scheduled) {
        this.messageStatus = MessageStatus.Draft;
      }
    } else {
      this.scheduleMode = true;
      if (this.messageStatus === MessageStatus.Draft) {
        this.messageStatus = MessageStatus.Scheduled;
      }
    }
    
  }

  closeSchedule(event) {
    this.showSchedule = false;
  }


  formatDate(date) {
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'full',
      timeStyle: 'long',
    }).format(date);
  }


  clickClose() {
    
    this.confirmDialogService.confirm({
      message: "If you quit now you'll lose unsaved data. Are you sure?",
      accept: () => {
        this.clearDataMap();
        this.resetBoxes();
        this.scheduleMode = false;
        this.unSetScheduleDate();
        this.closeEvents.emit(true);
      },
      reject: () => {
        //this.openPopup();
      }
    })
  }

  closePopup() {
    this.clearDataMap();
    this.resetBoxes();
    this.scheduleMode = false;
    this.unSetScheduleDate();
    this.closeEvents.emit(true);
  }

  openPopup() {
    this.openEvents.emit(true);
  }
  

}
