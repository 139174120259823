import {Component, DoCheck, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {animate, style, transition, trigger} from '@angular/animations';
import {GradientConfig} from '../../../../../app-config';
import { LoginService } from '../../../../../login/login.service';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/shared-components/chat/chat.service';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;
  username:any;
  @Output("openNotificationWindow") openNotificationWindow = new EventEmitter<any>();
  usersDetails: any;
  notificationCount: any;

  constructor(private loginService: LoginService, private router: Router, private chatService: ChatService) {
    this.visibleUserList = false;
    this.chatMessage = false;
    this.gradientConfig = GradientConfig.config;
  }

  ngOnInit() {
    this.usersDetails = JSON.parse(localStorage.getItem('userData'))
    this.username = localStorage.getItem('firstName')+ ' '+localStorage.getItem('lastName');
    this.fetchNewNotifications()

   }

  onChatToggle(friendID) {
    this.friendId = friendID;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('elite-rtl')) {
      this.gradientConfig['rtl-layout'] = true;
    } else {
      this.gradientConfig['rtl-layout'] = false;
    }
  }
  logout(event){
    console.log('log out');

      this.loginService.updateUser(-1);
      localStorage.clear();
      this.router.navigate(['login']);
      window.location.reload();

  }

  openNotificationPanel() {
    this.openNotificationWindow.emit(true);
  }
  fetchNewNotifications() {
    this.chatService.getNotifications(this.usersDetails?._id).subscribe((response: any) => {
   this.notificationCount = response.data.filter((item: any) => item && !item.read);
   localStorage.setItem('notifications', JSON.stringify(this.notificationCount))
    },(error) => {
      console.log(error)
    })
  }

  getNotificationCount() {
    const data = JSON.parse(localStorage.getItem('notifications'));
    if(!data) {
      return {
        count: 0,
        showBadge: false
      }
    }
   this.notificationCount = data.filter((item: any) => item && !item.read);
   return {
    count: this.notificationCount?.length,
    showBadge: this.notificationCount?.length > 0 ? true : false
   }
  }
}
