import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ViewJobDetailsComponent } from "./view-job-details.component";
import { SharedModule } from "../../../theme/shared/shared.module";
import {
  NgbCarouselModule,
  NgbProgressbarModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { ToastModule } from "primeng/toast";
import { TabViewModule } from "primeng/tabview";
import { CheckboxModule } from "primeng/checkbox";
import { AgmCoreModule } from "@agm/core";
import { TooltipModule } from "primeng/tooltip";

import { EditorModule } from "primeng/editor";
import { AddEditNetweightComponent } from "./add-edit-netweight/add-edit-netweight.component";
import { SharedComponentsModule } from "src/app/shared-components/shared-components.module";
import { AddEditJobNumberComponent } from './add-edit-job-number/add-edit-job-number.component';

@NgModule({
  declarations: [ViewJobDetailsComponent, AddEditNetweightComponent, AddEditJobNumberComponent],
  imports: [
    CommonModule,
    SharedModule,
    TabViewModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    ToastModule,
    TableModule,
    EditorModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    MessageModule,
    TooltipModule,
    CheckboxModule,
    MessagesModule,
    NgbCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBcapYO6jKgeTykdEWCtzdJIHM6zK2LN1Q", // 'AIzaSyCV4EIRYyt8YOAQFgrjCfonCyJAuzRdrq4',
      libraries: ["places"],
    }),
    SharedComponentsModule,
  ],
  exports: [AddEditNetweightComponent],
})
export class ViewJobViewModule {}
