<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card cardClass="table-card list-category" blockClass="p-0">
      <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->
      <div class="row padding-10">
        <div class="ui-g-11">
          <h1>User access</h1>
        </div>
        <div class="ui-g-1">
          <div
            (click)="closeEvent($event)"
            pTooltip="Click to close user permission"
            tooltipPosition="top"
          >
            <i class="fa fa-window-close fa-2x"></i>
          </div>
        </div>
      </div>

      <p-table
        #dt
        [value]="moduleList"
        [(selection)]="selectedCatgeory"
        dataKey="_id"
        styleClass="ui-table-customers"
        [rowHover]="true"
        [rows]="20"
        [showCurrentPageReport]="true"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [globalFilterFields]="searchArr"
      >
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search"
            />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>

            <!-- <th>Edit</th> -->
            <!-- <th>View</th>
                    <th>Delete</th> -->
            <th>
              <button
                type="button"
                class="btn btn-primary"
                (click)="saveUsersAccess()"
                pTooltip="Click to save user access"
                tooltipPosition="top"
                *ngIf="!checkIfDataChanged()"
              >
                Save
              </button>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
              <input
                pInputText
                type="text"
                class="ui-inputtext"
                (input)="dt.filter($event.target.value, col.field, 'contains')"
              />
            </th>
            <!-- <th></th> -->
            <!-- <th></th> -->
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of cols">
              {{ car[col.field] }}
            </td>

            <!-- <td>
                      <span (click)='editSubject($event,car)'>
                        <i class='fa fa-pencil fa-2x'></i>
                      </span>
                    </td> -->
            <td class="flex-between">
              <!-- <span (click)='viewVehicleType($event,car)' class="mob_icon style-toggler">
                        <i class='fa fa-eye fa-2x'></i>
                      </span> -->
              <span *ngIf="car?.objectArray?.read">
                <!-- <p-checkbox  name="active" ngDefaultControl [(ngModel)]="" *ngIf="car?.objectArray?.read" class="map-checkbox text-allign-btns"  label='Read'  binary="false"></p-checkbox> -->
                <input
                  type="checkbox"
                  id="Read"
                  name="Read"
                  value="read"
                  [checked]="checkForAccessRole(car, 'read')"
                  class="margin-right-5 checkbox"
                  (change)="changePermission($event, car, 'read')"
                />
                <label for="Read" class="checkboxLabel">Read</label><br />
              </span>
              <span *ngIf="car?.objectArray?.create">
                <!-- <p-checkbox  name="active" ngDefaultControl  *ngIf="car?.objectArray?.create" class="map-checkbox text-allign-btns"  label='Create'  binary="false"></p-checkbox> -->

                <input
                  type="checkbox"
                  id="Create"
                  name="Create"
                  value="Create"
                  [checked]="checkForAccessRole(car, 'create')"
                  class="margin-right-5 checkbox"
                  (change)="changePermission($event, car, 'create')"
                />
                <label for="Create" class="checkboxLabel">Create</label><br />
              </span>
              <span *ngIf="car?.objectArray?.edit">
                <!-- <p-checkbox  name="active" ngDefaultControl  *ngIf="car?.objectArray?.read" class="map-checkbox text-allign-btns"  label='Edit'  binary="false"></p-checkbox> -->
                <input
                  type="checkbox"
                  id="Edit"
                  name="Edit"
                  value="Edit"
                  [checked]="checkForAccessRole(car, 'edit')"
                  class="margin-right-5 checkbox"
                  (change)="changePermission($event, car, 'edit')"
                />
                <label for="Edit" class="checkboxLabel">Edit</label><br />
              </span>
              <span *ngIf="car?.objectArray?.delete">
                <!-- <p-checkbox  name="active" ngDefaultControl  *ngIf="car?.objectArray?.delete" class="map-checkbox text-allign-btns"  label='Delete'  binary="false"></p-checkbox> -->
                <input
                  type="checkbox"
                  id="Delete"
                  name="Delete"
                  value="Delete"
                  [checked]="checkForAccessRole(car, 'delete')"
                  class="margin-right-5 checkbox"
                  (change)="changePermission($event, car, 'delete')"
                />
                <label for="Delete" class="checkboxLabel">Delete</label><br />
              </span>
              <span *ngIf="car?.objectArray?.superAdmin">
                <!-- <p-checkbox  name="active" ngDefaultControl  *ngIf="car?.objectArray?.delete" class="map-checkbox text-allign-btns"  label='Delete'  binary="false"></p-checkbox> -->
                <input
                  type="checkbox"
                  id="superAdmin"
                  name="superAdmin"
                  value="superAdmin"
                  [checked]="checkForAccessRole(car, 'superAdmin')"
                  class="margin-right-5 checkbox"
                  (change)="changePermission($event, car, 'superAdmin')"
                />
                <label for="superAdmin" class="checkboxLabel">SuperAdmin</label
                ><br />
              </span>

              <!-- <span (click)='deleteVehicleType(car._id)'  class="style-toggler mob_icon delete_icon">
                        <i class='fa fa-trash fa-2x'></i>
                      </span> -->
              <!-- <span *ngIf="!car.delete">
                          -
                        </span> -->
            </td>
          </tr>
        </ng-template>
        <ng-template
          class="noRecords"
          *ngIf="moduleList?.length == 0"
          pTemplate="emptymessage"
        >
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length + 1">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>
    </app-card>
  </div>
  <!-- tabs card end -->
</div>
