import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { takeUntil, isEmpty } from "rxjs/operators";
import { VehicleService } from "../vehicle-data.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { JobService } from "../../job/job-data.service";

@Component({
  selector: "app-add-edit-vehicle",
  templateUrl: "./add-edit-vehicle.component.html",
  styleUrls: ["./add-edit-vehicle.component.scss"],
})
export class AddEditVehicleComponent implements OnInit {
  QuotesForm: FormGroup;
  vehicleForm: FormGroup;
  subjectList = [];
  slide = true;
  isSubjectVisible = false;
  isUpdate = false;
  isEdit = false;
  matchError = false;
  isAcumen: any;
  CompanyForm: FormGroup;
  companyID: any;
  vehicleTypeList = [{ label: "Please Select", value: "" }];
  companyList = [{ label: "Please Select", value: "" }];
  @Input("data") vehicleData: any = {};
  @Input("isView") isView = false;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  _data: any = [];
  get viewData() {
    // this._data = this.config.data.viewData;
    return this._data;
  }
  set viewData(data) {
    if (data) {
      console.log("data", data);
      this._data = data;
      this.vehicleForm.controls["vehicle_reg_number"].setValue(
        this._data.vehicle_reg_number
      );
      // this.vehicleForm.controls['isActive'].setValue(this._data.isActive);
      if (data.quotes_type === "general") {
        this.isSubjectVisible = false;
      } else {
        this.isSubjectVisible = true;
      }
      const subject = data.subject
        ? { label: data.subject_name, value: data._id }
        : undefined;
      // this.QuotesForm.controls['quotes_type'].setValue(data.quotes_type);
      // this.QuotesForm.controls['title'].setValue(data.title);
      // this.QuotesForm.controls['show_main'].setValue(data.show_main);
      // this.QuotesForm.controls['active'].setValue(data.active);
    }
  }
  typesList = [
    { label: "General", value: "general" },
    { label: "Subject", value: "subject" },
  ];
  constructor(
    private translateService: TranslateService,
    private vehicleService: VehicleService,
    private dialogService: DialogService,
    private eRef: ElementRef,
    private cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private jobService: JobService
  ) {
    this.QuotesForm = new FormGroup({
      quotes_type: new FormControl("general", [Validators.required]),
      title: new FormControl("", [Validators.required]),
      subject: new FormControl(),
      show_main: new FormControl(true),
      active: new FormControl(true),
    });
    this.vehicleForm = new FormGroup({
      company: new FormControl("", [Validators.required]),
      vehicle_type: new FormControl("", [Validators.required]),
      // isActive:new FormControl(true),
      vehicle_reg_number: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(^[A-Z]{2}[0-9]{2} [A-Z]{3}$)|(^[A-Z][0-9]{1,3} [A-Z]{3}$)|(^[A-Z]{3} [0-9]{1,3}[A-Z]$)|(^[0-9]{1,4} [A-Z]{1,2}$)|(^[0-9]{1,3} [A-Z]{1,3}$)|(^[A-Z]{1,2} [0-9]{1,4}$)|(^[A-Z]{1,3} [0-9]{1,3}$)$/
        ),
      ]),
    });
  }
  companyLists() {
    this.vehicleService.companyList().subscribe((data) => {
      if (data.status === 200) {
        console.log("company list", data);
        data.data.forEach((element) => {
          if (this.isAcumen == "true" || this.isAcumen == true) {
            this.companyList.push({
              label: element.companyName,
              value: element.companyId,
            });
          } else {
            if (element.companyId == this.companyID) {
              this.companyList.push({
                label: element.companyName,
                value: element.companyId,
              });
              if (
                this.isAcumen == "false" ||
                (this.isAcumen == false && !this.isUpdate)
              ) {
                console.log("abcdefghijk", this.companyList[0].value);
                this.vehicleForm.controls["company"].setValue(
                  element.companyId
                );
              }
            }
          }
        });

        if (this.isUpdate) {
          this.vehicleForm.controls["company"].setValue(
            this._data.company[0].companyId
          );
        }
      } else {
      }
    });
    this.vehicleService.vehicleTypeList().subscribe((data) => {
      if (data) {
        console.log("vehicle list", data);
        data.forEach((element) => {
          this.vehicleTypeList.push({
            label: element.name,
            value: element._id,
          });
        });
        if (this.isUpdate) {
          this.vehicleForm.controls["vehicle_type"].setValue(
            this._data.vehicle_type[0]._id
          );
        }
      } else {
      }
    });
  }
  editEvent(event) {
    this.isView = false;
    this.isUpdate = true;
    this.isEdit = true;
    // this.vehicleData.isActive = this.vehicleData.isActive == 'Yes' ? 1: 0;
    this.loadData();
  }
  onChangeType(event) {
    console.log(event.value);
    if (event.value === "general") {
      this.QuotesForm.controls["subject"].setValue("");
      this.isSubjectVisible = false;
    } else {
      this.isSubjectVisible = true;
      const defaultValue = this.subjectList ? this.subjectList[0].value : "";
      this.QuotesForm.controls["subject"].setValue(defaultValue);
    }
  }
  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if(this.eRef.nativeElement.contains(event.target)) {
  //     console.log("clicked inside");
  //     // this.closeEvent(true);
  //   } else {
  //     console.log("clicked outside");
  //     // this.closeEvent(true);

  //   }
  // }
  closeEvent(event) {
    console.log("close event");
    if (this.isEdit == false) {
      // var element = document.getElementById("styleSelector");
      //               element.classList.remove("animate-block");
      //               element.classList.add("slide-out");
      this.slide = false;
      setTimeout((e) => {
        this.closeEvents.emit(true);
      }, 1000);
    } else {
      this.isView = true;
      this.isEdit = false;
      this.isUpdate = false;
      this.loadData();
    }
  }
  onChange(event) {
    let val;
    val = this.vehicleForm.get("vehicle_reg_number").value;
    console.log("event", event, val);
    if (val != "") {
      if (
        !val.match(
          /^(^[A-Z]{2}[0-9]{2} [A-Z]{3}$)|(^[A-Z][0-9]{1,3} [A-Z]{3}$)|(^[A-Z]{3} [0-9]{1,3}[A-Z]$)|(^[0-9]{1,4} [A-Z]{1,2}$)|(^[0-9]{1,3} [A-Z]{1,3}$)|(^[A-Z]{1,2} [0-9]{1,4}$)|(^[A-Z]{1,3} [0-9]{1,3}$)$/
        )
      ) {
        console.log("loop1", val);
        this.matchError = true;
      } else {
        console.log("loop2", val);
        this.matchError = false;
      }
    }
  }
  updateVehicle(event: any) {
    let value: any = "";
    value = this.vehicleForm.value;
    value._id = this._data._id;
    console.log("vehicle data", value);

    this.vehicleService.updateVehicle(value).subscribe((data: any) => {
      if (data) {
        this.messageService.add({
          severity: "success",
          summary: "Update Vehicle",
          detail: "Vehicle details updated successfully",
        });
        // this.ref.close(this);
        // this.closeEvents.emit(true);
        if (this.isEdit == true) {
          this.vehicleService
            .getVehicleDetails(this._data._id)
            .subscribe((response) => {
              this.vehicleData = response[0];
              this.closeEvent(true);
            });
        } else {
          //this.closeEvents.emit(true);
          this.closeEvent(true);
        }
      } else if (data.status === 500) {
        this.messageService.add({
          severity: "error",
          summary: "Update Vehicle",
          detail: data.error.message.errmsg,
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Update Vehicle",
          // detail: 'Failed to quotes the Department details'
          detail: "Unable to update Vehicle.Please try again.",
        });
      }
    });
  }
  createVehicle(e: any) {
    let vehicleData: any = "";
    vehicleData = this.vehicleForm.value;
    console.log("vehicle data", vehicleData);
    this.vehicleService.createVehicle(vehicleData).subscribe(
      (data: any) => {
        if (data) {
          this.messageService.add({
            severity: "success",
            summary: "Create Vehicle",
            detail: "New Vehicle created successfully",
          });
          // this.ref.close(this);
          //this.closeEvents.emit(true);
          this.closeEvent(true);
        } else if (data.status === 500) {
          this.messageService.add({
            severity: "error",
            summary: "Create Vehicle",
            detail: data.error.message.errmsg,
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Create Vehicle",
            // detail: 'Failed to create the Department'
            detail: "Unable to add Vehicle.Please try again.",
          });
        }
      },
      (err) => {
        //  this.closeEvents.emit(true);
        this.closeEvent(true);
        console.log("error", err);
        this.messageService.add({
          severity: "error",
          summary: "Create Vehicle",
          detail: err.error.message,
          //  detail: 'Failed to create the Department'
        });
        //this.dialogService.closeAll();
      }
    );
  }
  loadData() {
    if (this.vehicleData && Object.keys(this.vehicleData).length !== 0) {
      console.log(" true vehicleData", this.vehicleData);
      this._data = this.vehicleData;
      this.isUpdate = true;
      if (this.isUpdate) {
        this.vehicleForm.controls["vehicle_reg_number"].setValue(
          this._data.vehicle_reg_number
        );
        // this.vehicleForm.controls['isActive'].setValue(this._data.isActive);
      }
    }

    if (this.isView) {
      // this.vehicleData.isActive = this.vehicleData.isActive == 1 ?'Yes':'No';
    }
  }
  ngOnInit() {
    var element = document.getElementById("styleSelector");
    element.classList.remove("slide-out");
    element.classList.add("animate-block");
    console.log("view data", this.vehicleData);
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");

    this.loadData();
    this.companyLists();
  }
  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
